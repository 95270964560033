import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { businessClassification, stateName } from '../../APIs/Businessclassfyservice';
import Header from '../Common/Header';
import { LoadingSpinerComponent } from '../Common/loadingspinner';



const Step1 = (props) => {
    const [Name, setName] = useState(props.step1Data.businessName ? props.step1Data.businessName : '')
    const [type, setType] = useState(props.step1Data.businessType ? props.step1Data.businessType : "soleProprietorship")
    const [Category, setCategory] = useState(props.step1Data.businessIndex ? props.step1Data.businessIndex : '')
    const [businessCategoryId, setBusinessCategoryId] = useState('')
    const [IndusCategory, setIndusCategory] = useState(props.step1Data.industryClassification ? props.step1Data.industryClassification : '')
    const [ein, setEin] = useState(props.step1Data.ein ? props.step1Data.ein : '')
    const [store, setStore] = useState(props.step1Data.store ? props.step1Data.store : '')
    const [state, setState] = useState(props.step1Data.state ? props.step1Data.state : '')
    const [city, setCity] = useState(props.step1Data.city ? props.step1Data.city : '')
    const [address, setaddress] = useState(props.step1Data.address ? props.step1Data.address : '')
    const [postalcode, setPostalCode] = useState(props.step1Data.postalcode ? props.step1Data.postalcode : '')
    const [document, setDocument] = useState(props.step1Data.document ? props.step1Data.document : <i className="fa fa-camera"></i>)
    const [license, setLicense] = useState(props.step1Data.lic ? props.step1Data.lic : require('../../images/add.png').default)
    const [LicenseImage, setLicenseImage] = useState(props.step1Data?.licence)
    const [DocumentImage, setDocumentImage] = useState(props.step1Data?.picture)
    const [businessList, setBusinessList] = useState([]);
    const [subBusinessList, setSubBusinessList] = useState([]);
    const [StateName, setStateName] = useState('');

    useEffect(() => {
        check()
        stateNames()
    }, []);

    function stateNames() {
        stateName().then(res => {
            setStateName(res.data.data)
        })
    }

    function check() {
        businessClassification()
            .then(res => {
                setBusinessList(res.data.data);
                if (props.step1Data.businessIndex)
                    subBusinessListByIndex(props.step1Data.businessIndex, res.data.data);
            },
                (error) => {
                    console.log(JSON.stringify(error));
                })
    }

    function subBusinessListByIndex(index, bList) {
        setBusinessCategoryId(bList[index].id)
        const subBusinessCategories = bList[index].industry;
        setSubBusinessList(subBusinessCategories);
    }

    function onBusinessCategoryChange(e) {
        setIndusCategory('')
        setCategory(e.target.value)
        const index = e.target.value;
        if (e.target.value === '') {
            toast.dismiss()
            toast.error('please select valid business category')
        } else {
            setBusinessCategoryId(businessList[index].id)

        }
        if (e.target.value !== '') {
            const subBusinessCategories = businessList[index].industry;
            // console.log(subBusinessCategories);
            setSubBusinessList(subBusinessCategories);
        } else {
            setSubBusinessList('')
        }
    }

    function handleLicense(event) {
        if (event.target.files[0]) {
            let file = event.target.files[0]
            setLicenseImage(file)
            let ext = file.name.split('.').pop()
            if (ext == "pdf") {
                setLicense("pdf.png")
            } else {
                setLicense(URL.createObjectURL(file))
            }
        }
        event.target.value = ""
    }

    function handleDocument(event) {
        if (event.target.files[0]) {
            setDocument(URL.createObjectURL(event.target.files[0]))
            setDocumentImage(event.target.files[0])
        }
    }



    function handleStep(event) {
        localStorage.setItem('Type', type)
        const step1 = {
            businessName: Name,
            businessType: type,
            businessClassification: businessCategoryId,
            industryClassification: IndusCategory,
            ein: ein,
            email: localStorage.getItem('email'),
            phone: localStorage.getItem('phone'),
            store: store,
            state: state,
            address: address,
            city: city,
            postalcode: postalcode,
            picture: DocumentImage,
            document: document,
            businessIndex: Category
        }
        if (type != "soleProprietorship") {
            step1.lic = license
            step1.licence = LicenseImage
        }
        props.handleStep1(step1)
    }


    function onlyNumber(e) {
        // console.log(e)
        if (/^[0-9\b]+$/.test(e.target.value)) {
            setEin(e.target.value);
        } else if (e.target.value === '')
            setEin('');

    }
    function onlyPostal(e) {
        // console.log(e)
        if (/^[0-9\b]+$/.test(e.target.value)) {
            setPostalCode(e.target.value);
        } else if (e.target.value === '')
            setPostalCode('');

    }

    function onlyAddress(e) {
        if (/^[a-zA-Z0-9 ]{0,51}$/.test(e.target.value)) {
            setaddress(e.target.value)
        }
        else if (e.target.value = '') {
            setaddress('')
        }
    }

    return (
        <>

            <section className="signup_section">
                <LoadingSpinerComponent promiseInProgress={props.loader} />
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h6 className="text-white businesstext">Business Details</h6>
                        <hr className="bg-secondary"></hr>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-3 text-center">
                        {/* <a className="" href="/#"> */}
                        <div className="icon_circle">
                            <i className="fa fa-camera"></i>
                            <img src={document} alt="" />
                            <input type="file" accept="image/jpeg,image/jpg,image/png" className='' onChange={handleDocument} />
                        </div>
                        <p className="mt-2 addphototext text-white">Add a business photo</p>
                        {/* </a> */}
                    </div>
                    <div className="col-md-9">
                        <Form>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="">
                                        <Form.Label>Business Name</Form.Label>
                                        <Form.Control type="" placeholder="E.g. PayRink" value={Name} onChange={(e) => { setName(e.target.value) }} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group controlId="exampleForm.ControlSelect3">
                                        <Form.Label>Business Type</Form.Label>
                                        <Form.Control as="select" value={type} onChange={(e) => { setType(e.target.value) }} >
                                            <option disabled hidden selected>--Select--</option>
                                            <option value="soleProprietorship">SoleProprietorship</option>
                                            <option value="corporation"> Corporation</option>
                                            <option value="llc">LLC</option>
                                            <option value="partnership">Partnership</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                {
                                    type != "soleProprietorship" ?
                                        <div className='col-md-12 text-white mb-2'>
                                            Disclaimer: Business Licence, Business Owner/Controller documents are required.
                                        </div>
                                        : ""
                                }
                                <div className="col-md-6 mb-2">
                                    <Form.Group controlId="exampleForm.ControlSelect3">
                                        <Form.Label>Business Category</Form.Label>
                                        <Form.Control as="select" value={Category} onChange={(e) => onBusinessCategoryChange(e)}>
                                            <option value='' disabled hidden selected>Select One</option>
                                            {
                                                businessList.length > 0 && businessList.map((business, index) => <option key={index} value={index}>{business.name}</option>)
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group controlId="exampleForm.ControlSelect3">
                                        <Form.Label>Industry Category</Form.Label>
                                        <Form.Control as="select" value={IndusCategory} onChange={(e) => {
                                            setIndusCategory(e.target.value)
                                        }}>
                                            <option value='' disabled hidden selected>--Select--</option>
                                            {
                                                subBusinessList.length > 0 && subBusinessList.map((subBusiness, index) => <option key={index} value={subBusiness.id}>{subBusiness.name}</option>)
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                {/* <div className="col-md-12 mb-2">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Website</Form.Label>
                                            <Form.Control type="" placeholder="E.g. www.payrink.com" />
                                        </Form.Group>
                                    </div> */}
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>EIN(Employer Identification number)</Form.Label>
                                        <Form.Control type="" inputmode="numeric" maxlength="9" placeholder="E.g. 1234" value={ein} onChange={(e) => onlyNumber(e)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control type="" placeholder="E.g. adam@gmail.com" value={localStorage.getItem('email')} disabled />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Business Phone Number</Form.Label>
                                        <Form.Control type="" placeholder="E.g. (987-653-2110)" value={localStorage.getItem('phone')} disabled />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Store</Form.Label>
                                        <Form.Control type="" placeholder="E.g. Walmart 201" value={store} onChange={(e) => { setStore(e.target.value) }} />
                                    </Form.Group>
                                </div>

                                <div className="col-md-12 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label> Business Address (Street)</Form.Label>
                                        <Form.Control type="" maxLength="50" placeholder="E.g. California USA" value={address} onChange={(e) => { onlyAddress(e) }} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control as="select" value={state} onChange={(e) => { setState(e.target.value) }}>
                                            <option>Select State</option>
                                            {
                                                StateName.length > 0 && StateName.map((States, index) => <option key={index} value={States.code}>{States.name} ,  {States.code}</option>)
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    {/* <Form.Group controlId="exampleForm.ControlSelect2">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control as="select" onChange={(e) => { setCity(e.target.value) }}>
                                            <option>Select City</option>
                                            <option>City</option>
                                        </Form.Control>
                                    </Form.Group> */}
                                    <Form.Group className="mb-3">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="" placeholder="E.g. Los Angeles" value={city} onChange={(e) => { setCity(e.target.value) }} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control type="" inputMode="numeric" maxlength="5" placeholder="E.g.12345" value={postalcode} onChange={(e) => { onlyPostal(e) }} />
                                    </Form.Group>
                                </div>
                                {
                                    type != "soleProprietorship" ?
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Upload Business License</Form.Label>
                                                <div className="dcmnt_img">
                                                    <span>
                                                        <img src={license} alt="" />
                                                    </span>
                                                    <input type="file" accept="image/jpeg,image/jpg,image/png,application/pdf" className='imginput_upload' onChange={handleLicense} />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        : ""
                                }
                            </div>
                        </Form>


                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between">

                                {/* <button className="btn backbtn">
                                                Back
                                            </button> */}
                                <button className="btn nxtbtn" onClick={(event) => handleStep(event)} >
                                    Next
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Step1;
