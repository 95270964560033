import axios from "axios";
import { config } from "../config";

function Details(txn_id){
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone
    return axios.get(`${config.api_URL}/accounts/transaction_details?txn_id=`+txn_id +"&timezone="+timezone,{
        headers:{"Authorization":localStorage.getItem('access_token')}
    })
}

export default Details;