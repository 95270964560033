import axios from "axios";
import { config } from "../config";

function SearchUserApi(data){
    return axios.post(`${config.api_URL}/accounts/search_user`,data,
    {
        headers:{
            'Authorization':localStorage.getItem('access_token')}
        }
    )
}

export default SearchUserApi;