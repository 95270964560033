import axios from "axios";
import { config } from "../config";



export const ForgetPasswordApi=(data)=>{
    const {
        email   //object destructuring
    }=data
    const param={
        "email":email
    }

    return axios.post(
        `${config.api_URL}/accounts/password_reset`,param);
}