import { useEffect, useState } from "react";
import Footer from "./Common/Footer2";
import Header2 from "./Common/Header2";
import Header from "./Common/Header";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import contactUs from "../APIs/contactusApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { LoadingSpinerComponent } from "./Common/loadingspinner";
import Form from "react-bootstrap/Form";
import { IoIosArrowForward } from "react-icons/io";
import Button from "react-bootstrap/Button";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FooterNew from "./Common/FooterNew";

const schema = yup.object({
    first_name: yup.string().required("Required field"),
    last_name: yup.string().required("Required field"),
    work_phone: yup.number().typeError("Required numeric value").required("Required field"),
    work_email: yup.string().email('Not a proper email').required("Required field"),
    company_webiste: yup.string().required("Required field"),
    company_size: yup.string().required("Required field"),
    country: yup.string().required("Required field"),
    payment_volume: yup.string().required("Required field"),
    anything_else: yup.string(),
}).required();

const Contact = () => {
    const [loader, setLoader] = useState(false);
    function onSubmit(data) {
        //event.preventDefault();
        //toast.dismiss();
        //if (formData.first_name === "") {
        //    toast.error("First name can't be empty");
        //} else if (formData.last_name === "") {
        //    toast.error("Last name can't be empty");
        //} else if (formData.work_email === "") {
        //    toast.error("Email can't be empty");
        //} else if (formData.company_webiste === "") {
        //    toast.error("Company website can't be empty");
        //} else if (
        //    !/^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/.test(
        //        formData.work_email
        //    )
        //) {
        //    toast.error("Please enter valid email");
        //    return false;
        //} else {
        setLoader(true);
        contactUs(data)
            .then((res) => {
                toast.success(res.data.message);
                setLoader(false);
            })
            .catch(function (err) {
                setLoader(false);
                toast.error(err.response.data.message);
            });
        window.setTimeout(() => {
            loader && setLoader(false);
        }, 3000);
        //}
    }
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    return (
        <>
            <header className="contactheader">
                <div className="container-fluid">
                    <Navbar expand="lg contact-nav" className="px-0">
                        <Navbar.Brand href="/">
                            <img
                                src={require("../images/logoWhite.png").default}
                                alt="img"
                                className="web_logo"
                            />
                            <img
                                src={require("../images/blackLogo.png").default}
                                alt="img"
                                className="mob_logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/solutions">Solutions</Nav.Link>
                            </Nav>
                            <Form inline>
                                <Button className="dark-btn" href="/login">
                                    Get Started
                                </Button>
                            </Form>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </header>
            <section
                className="team-cnt-header text-center text-white"
                style={{ background: "#f26369", padding: "90px 0" }}
            >
                <div className="container-fluid">
                    <h2
                        className="cnt-title"
                        style={{ fontSize: "50px", fontWeight: "bold" }}
                    >
                        Contact Our Sales Team
                    </h2>
                </div>
            </section>
            <section
                className="team-contact"
                style={{ background: "#f2f3f4", padding: "70px 0" }}
            >
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container-fluid">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-5">
                                <div className="contact-sales-left">
                                    <h2 className="f-45 pb-4 dark-tx">Rethinking Payments</h2>
                                    <p className="f-32">Offering the lowest Processing Fees</p>
                                    <ul className="contact-listing f-22">
                                        <li>PayRink gets rid of all unnecessary fees.</li>
                                        <li> You only pay a <strong style={{ color: "#007230" }}>0.3% </strong> per transaction platform fee.</li>
                                        <li>No other fees.</li>
                                        <li>No monthly minimums.</li>
                                        <li>No cost is transferred to the customer.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-7">
                                <div className="contact-form-wrap">
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>
                                                First Name <span style={{ color: "#f3686d" }}>*</span>
                                            </Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Form.Control
                                                placeholder="John"
                                                {...register("first_name", { maxLength: 20, pattern: /^[A-Za-z]+$/i })}
                                            />{errors.first_name && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.first_name.message}</span>}
                                        </div>
                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>
                                                Last Name <span style={{ color: "#f3686d" }}>*</span>
                                            </Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Form.Control
                                                placeholder="Williams"
                                                {...register("last_name", { maxLength: 20, pattern: /^[A-Za-z]+$/i })}
                                            />{errors.last_name && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.last_name.message}</span>}
                                        </div>
                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>
                                                Work Email <span style={{ color: "#f3686d" }}>*</span>
                                            </Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Form.Control
                                                placeholder="john@example.com"
                                                {...register("work_email", { maxLength: 20, pattern: /^[A-Za-z]+$/i })}
                                            />{errors.work_email && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.work_email.message}</span>}
                                        </div>
                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>Work Phone <span style={{ color: "#f3686d" }}>*</span></Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Form.Control
                                                placeholder="+1-777-777-7777"
                                                {...register("work_phone", { minLength: 7, maxLength: 15 })}
                                            />{errors.work_phone && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.work_phone.message}</span>}
                                        </div>
                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>
                                                Company Website{" "}
                                                <span style={{ color: "#f3686d" }}>*</span>
                                            </Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Form.Control
                                                placeholder="example.com"
                                                {...register("company_webiste", { maxLength: 20, pattern: /^[A-Za-z]+$/i })}
                                            />{errors.company_webiste && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.company_webiste.message}</span>}
                                        </div>
                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>
                                                Country
                                                <span style={{ color: "#f3686d" }}>*</span>
                                            </Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <select name="country"  {...register("country")} defaultValue="US">
                                                <option value="AF" data-country-name="Afghanistan">
                                                    Afghanistan
                                                </option><option value="AX" data-country-name="Åland Islands">
                                                    Åland Islands
                                                </option><option value="AL" data-country-name="Albania">
                                                    Albania
                                                </option><option value="DZ" data-country-name="Algeria">
                                                    Algeria
                                                </option><option value="AS" data-country-name="American Samoa">
                                                    American Samoa
                                                </option><option value="AD" data-country-name="Andorra">
                                                    Andorra
                                                </option><option value="AO" data-country-name="Angola">
                                                    Angola
                                                </option><option value="AI" data-country-name="Anguilla">
                                                    Anguilla
                                                </option><option value="AQ" data-country-name="Antarctica">
                                                    Antarctica
                                                </option><option value="AG" data-country-name="Antigua and Barbuda">
                                                    Antigua &amp; Barbuda
                                                </option><option value="AR" data-country-name="Argentina">
                                                    Argentina
                                                </option><option value="AM" data-country-name="Armenia">
                                                    Armenia
                                                </option><option value="AW" data-country-name="Aruba">
                                                    Aruba
                                                </option><option value="AU" data-country-name="Australia">
                                                    Australia
                                                </option><option value="AT" data-country-name="Austria">
                                                    Austria
                                                </option><option value="AZ" data-country-name="Azerbaijan">
                                                    Azerbaijan
                                                </option><option value="BS" data-country-name="Bahamas">
                                                    Bahamas
                                                </option><option value="BH" data-country-name="Bahrain">
                                                    Bahrain
                                                </option><option value="BD" data-country-name="Bangladesh">
                                                    Bangladesh
                                                </option><option value="BB" data-country-name="Barbados">
                                                    Barbados
                                                </option><option value="BY" data-country-name="Belarus">
                                                    Belarus
                                                </option><option value="BE" data-country-name="Belgium">
                                                    Belgium
                                                </option><option value="BZ" data-country-name="Belize">
                                                    Belize
                                                </option><option value="BJ" data-country-name="Benin">
                                                    Benin
                                                </option><option value="BM" data-country-name="Bermuda">
                                                    Bermuda
                                                </option><option value="BT" data-country-name="Bhutan">
                                                    Bhutan
                                                </option><option value="BO" data-country-name="Bolivia">
                                                    Bolivia
                                                </option><option value="BA" data-country-name="Bosnia and Herzegovina">
                                                    Bosnia &amp; Herzegovina
                                                </option><option value="BW" data-country-name="Botswana">
                                                    Botswana
                                                </option><option value="BV" data-country-name="Bouvet Island">
                                                    Bouvet Island
                                                </option><option value="BR" data-country-name="Brazil">
                                                    Brazil
                                                </option><option value="IO" data-country-name="British Indian Ocean Territory">
                                                    British Indian Ocean Territory
                                                </option><option value="VG" data-country-name="Virgin Islands, British">
                                                    British Virgin Islands
                                                </option><option value="BN" data-country-name="Brunei Darussalam">
                                                    Brunei
                                                </option><option value="BG" data-country-name="Bulgaria">
                                                    Bulgaria
                                                </option><option value="BF" data-country-name="Burkina Faso">
                                                    Burkina Faso
                                                </option><option value="BI" data-country-name="Burundi">
                                                    Burundi
                                                </option><option value="KH" data-country-name="Cambodia">
                                                    Cambodia
                                                </option><option value="CM" data-country-name="Cameroon">
                                                    Cameroon
                                                </option><option value="CA" data-country-name="Canada">
                                                    Canada
                                                </option><option value="CV" data-country-name="Cape Verde">
                                                    Cape Verde
                                                </option><option value="BQ" data-country-name="Bonaire, Sint Eustatius and Saba">
                                                    Caribbean Netherlands
                                                </option><option value="KY" data-country-name="Cayman Islands">
                                                    Cayman Islands
                                                </option><option value="CF" data-country-name="Central African Republic">
                                                    Central African Republic
                                                </option><option value="TD" data-country-name="Chad">
                                                    Chad
                                                </option><option value="CL" data-country-name="Chile">
                                                    Chile
                                                </option><option value="CN" data-country-name="China">
                                                    China
                                                </option><option value="CX" data-country-name="Christmas Island">
                                                    Christmas Island
                                                </option><option value="CC" data-country-name="Cocos (Keeling) Islands">
                                                    Cocos (Keeling) Islands
                                                </option><option value="CO" data-country-name="Colombia">
                                                    Colombia
                                                </option><option value="KM" data-country-name="Comoros">
                                                    Comoros
                                                </option><option value="CG" data-country-name="Congo">
                                                    Congo - Brazzaville
                                                </option><option value="CD" data-country-name="Congo, The Democratic Republic Of The">
                                                    Congo - Kinshasa
                                                </option><option value="CK" data-country-name="Cook Islands">
                                                    Cook Islands
                                                </option><option value="CR" data-country-name="Costa Rica">
                                                    Costa Rica
                                                </option><option value="CI" data-country-name="Côte D'Ivoire">
                                                    Côte d’Ivoire
                                                </option><option value="HR" data-country-name="Croatia">
                                                    Croatia
                                                </option><option value="CW" data-country-name="Curaçao">
                                                    Curaçao
                                                </option><option value="CY" data-country-name="Cyprus">
                                                    Cyprus
                                                </option><option value="CZ" data-country-name="Czech Republic">
                                                    Czech Republic
                                                </option><option value="DK" data-country-name="Denmark">
                                                    Denmark
                                                </option><option value="DJ" data-country-name="Djibouti">
                                                    Djibouti
                                                </option><option value="DM" data-country-name="Dominica">
                                                    Dominica
                                                </option><option value="DO" data-country-name="Dominican Republic">
                                                    Dominican Republic
                                                </option><option value="EC" data-country-name="Ecuador">
                                                    Ecuador
                                                </option><option value="EG" data-country-name="Egypt">
                                                    Egypt
                                                </option><option value="SV" data-country-name="El Salvador">
                                                    El Salvador
                                                </option><option value="GQ" data-country-name="Equatorial Guinea">
                                                    Equatorial Guinea
                                                </option><option value="ER" data-country-name="Eritrea">
                                                    Eritrea
                                                </option><option value="EE" data-country-name="Estonia">
                                                    Estonia
                                                </option><option value="SZ" data-country-name="Swaziland">
                                                    Eswatini
                                                </option><option value="ET" data-country-name="Ethiopia">
                                                    Ethiopia
                                                </option><option value="FK" data-country-name="Falkland Islands (Malvinas)">
                                                    Falkland Islands
                                                </option><option value="FO" data-country-name="Faroe Islands">
                                                    Faroe Islands
                                                </option><option value="FJ" data-country-name="Fiji">
                                                    Fiji
                                                </option><option value="FI" data-country-name="Finland">
                                                    Finland
                                                </option><option value="FR" data-country-name="France">
                                                    France
                                                </option><option value="GF" data-country-name="French Guiana">
                                                    French Guiana
                                                </option><option value="PF" data-country-name="French Polynesia">
                                                    French Polynesia
                                                </option><option value="TF" data-country-name="French Southern Territories">
                                                    French Southern Territories
                                                </option><option value="GA" data-country-name="Gabon">
                                                    Gabon
                                                </option><option value="GM" data-country-name="Gambia">
                                                    Gambia
                                                </option><option value="GE" data-country-name="Georgia">
                                                    Georgia
                                                </option><option value="DE" data-country-name="Germany">
                                                    Germany
                                                </option><option value="GH" data-country-name="Ghana">
                                                    Ghana
                                                </option><option value="GI" data-country-name="Gibraltar">
                                                    Gibraltar
                                                </option><option value="GR" data-country-name="Greece">
                                                    Greece
                                                </option><option value="GL" data-country-name="Greenland">
                                                    Greenland
                                                </option><option value="GD" data-country-name="Grenada">
                                                    Grenada
                                                </option><option value="GP" data-country-name="Guadeloupe">
                                                    Guadeloupe
                                                </option><option value="GU" data-country-name="Guam">
                                                    Guam
                                                </option><option value="GT" data-country-name="Guatemala">
                                                    Guatemala
                                                </option><option value="GG" data-country-name="Guernsey">
                                                    Guernsey
                                                </option><option value="GN" data-country-name="Guinea">
                                                    Guinea
                                                </option><option value="GW" data-country-name="Guinea-Bissau">
                                                    Guinea-Bissau
                                                </option><option value="GY" data-country-name="Guyana">
                                                    Guyana
                                                </option><option value="HT" data-country-name="Haiti">
                                                    Haiti
                                                </option><option value="HM" data-country-name="Heard and McDonald Islands">
                                                    Heard &amp; McDonald Islands
                                                </option><option value="HN" data-country-name="Honduras">
                                                    Honduras
                                                </option><option value="HK" data-country-name="Hong Kong">
                                                    Hong Kong
                                                </option><option value="HU" data-country-name="Hungary">
                                                    Hungary
                                                </option><option value="IS" data-country-name="Iceland">
                                                    Iceland
                                                </option><option value="IN" data-country-name="India">
                                                    India
                                                </option><option value="ID" data-country-name="Indonesia">
                                                    Indonesia
                                                </option><option value="IQ" data-country-name="Iraq">
                                                    Iraq
                                                </option><option value="IE" data-country-name="Ireland">
                                                    Ireland
                                                </option><option value="IM" data-country-name="Isle of Man">
                                                    Isle of Man
                                                </option><option value="IL" data-country-name="Israel">
                                                    Israel
                                                </option><option value="IT" data-country-name="Italy">
                                                    Italy
                                                </option><option value="JM" data-country-name="Jamaica">
                                                    Jamaica
                                                </option><option value="JP" data-country-name="Japan">
                                                    Japan
                                                </option><option value="JE" data-country-name="Jersey">
                                                    Jersey
                                                </option><option value="JO" data-country-name="Jordan">
                                                    Jordan
                                                </option><option value="KZ" data-country-name="Kazakhstan">
                                                    Kazakhstan
                                                </option><option value="KE" data-country-name="Kenya">
                                                    Kenya
                                                </option><option value="KI" data-country-name="Kiribati">
                                                    Kiribati
                                                </option><option value="KW" data-country-name="Kuwait">
                                                    Kuwait
                                                </option><option value="KG" data-country-name="Kyrgyzstan">
                                                    Kyrgyzstan
                                                </option><option value="LA" data-country-name="Lao People's Democratic Republic">
                                                    Laos
                                                </option><option value="LV" data-country-name="Latvia">
                                                    Latvia
                                                </option><option value="LB" data-country-name="Lebanon">
                                                    Lebanon
                                                </option><option value="LS" data-country-name="Lesotho">
                                                    Lesotho
                                                </option><option value="LR" data-country-name="Liberia">
                                                    Liberia
                                                </option><option value="LY" data-country-name="Libya">
                                                    Libya
                                                </option><option value="LI" data-country-name="Liechtenstein">
                                                    Liechtenstein
                                                </option><option value="LT" data-country-name="Lithuania">
                                                    Lithuania
                                                </option><option value="LU" data-country-name="Luxembourg">
                                                    Luxembourg
                                                </option><option value="MO" data-country-name="Macau">
                                                    Macao SAR China
                                                </option><option value="MG" data-country-name="Madagascar">
                                                    Madagascar
                                                </option><option value="MW" data-country-name="Malawi">
                                                    Malawi
                                                </option><option value="MY" data-country-name="Malaysia">
                                                    Malaysia
                                                </option><option value="MV" data-country-name="Maldives">
                                                    Maldives
                                                </option><option value="ML" data-country-name="Mali">
                                                    Mali
                                                </option><option value="MT" data-country-name="Malta">
                                                    Malta
                                                </option><option value="MH" data-country-name="Marshall Islands">
                                                    Marshall Islands
                                                </option><option value="MQ" data-country-name="Martinique">
                                                    Martinique
                                                </option><option value="MR" data-country-name="Mauritania">
                                                    Mauritania
                                                </option><option value="MU" data-country-name="Mauritius">
                                                    Mauritius
                                                </option><option value="YT" data-country-name="Mayotte">
                                                    Mayotte
                                                </option><option value="MX" data-country-name="Mexico">
                                                    Mexico
                                                </option><option value="FM" data-country-name="Micronesia, Federated States Of">
                                                    Micronesia
                                                </option><option value="MD" data-country-name="Moldova, Republic of">
                                                    Moldova
                                                </option><option value="MC" data-country-name="Monaco">
                                                    Monaco
                                                </option><option value="MN" data-country-name="Mongolia">
                                                    Mongolia
                                                </option><option value="ME" data-country-name="Montenegro">
                                                    Montenegro
                                                </option><option value="MS" data-country-name="Montserrat">
                                                    Montserrat
                                                </option><option value="MA" data-country-name="Morocco">
                                                    Morocco
                                                </option><option value="MZ" data-country-name="Mozambique">
                                                    Mozambique
                                                </option><option value="MM" data-country-name="Myanmar">
                                                    Myanmar (Burma)
                                                </option><option value="NA" data-country-name="Namibia">
                                                    Namibia
                                                </option><option value="NR" data-country-name="Nauru">
                                                    Nauru
                                                </option><option value="NP" data-country-name="Nepal">
                                                    Nepal
                                                </option><option value="NL" data-country-name="Netherlands">
                                                    Netherlands
                                                </option><option value="NC" data-country-name="New Caledonia">
                                                    New Caledonia
                                                </option><option value="NZ" data-country-name="New Zealand">
                                                    New Zealand
                                                </option><option value="NI" data-country-name="Nicaragua">
                                                    Nicaragua
                                                </option><option value="NE" data-country-name="Niger">
                                                    Niger
                                                </option><option value="NG" data-country-name="Nigeria">
                                                    Nigeria
                                                </option><option value="NU" data-country-name="Niue">
                                                    Niue
                                                </option><option value="NF" data-country-name="Norfolk Island">
                                                    Norfolk Island
                                                </option><option value="MK" data-country-name="North Macedonia">
                                                    North Macedonia
                                                </option><option value="MP" data-country-name="Northern Mariana Islands">
                                                    Northern Mariana Islands
                                                </option><option value="NO" data-country-name="Norway">
                                                    Norway
                                                </option><option value="OM" data-country-name="Oman">
                                                    Oman
                                                </option><option value="PK" data-country-name="Pakistan">
                                                    Pakistan
                                                </option><option value="PW" data-country-name="Palau">
                                                    Palau
                                                </option><option value="PS" data-country-name="Palestine, State of">
                                                    Palestinian Territories
                                                </option><option value="PA" data-country-name="Panama">
                                                    Panama
                                                </option><option value="PG" data-country-name="Papua New Guinea">
                                                    Papua New Guinea
                                                </option><option value="PY" data-country-name="Paraguay">
                                                    Paraguay
                                                </option><option value="PE" data-country-name="Peru">
                                                    Peru
                                                </option><option value="PH" data-country-name="Philippines">
                                                    Philippines
                                                </option><option value="PN" data-country-name="Pitcairn">
                                                    Pitcairn Islands
                                                </option><option value="PL" data-country-name="Poland">
                                                    Poland
                                                </option><option value="PT" data-country-name="Portugal">
                                                    Portugal
                                                </option><option value="PR" data-country-name="Puerto Rico">
                                                    Puerto Rico
                                                </option><option value="QA" data-country-name="Qatar">
                                                    Qatar
                                                </option><option value="RE" data-country-name="Réunion">
                                                    Réunion
                                                </option><option value="RO" data-country-name="Romania">
                                                    Romania
                                                </option><option value="RU" data-country-name="Russian Federation">
                                                    Russia
                                                </option><option value="RW" data-country-name="Rwanda">
                                                    Rwanda
                                                </option><option value="WS" data-country-name="Samoa">
                                                    Samoa
                                                </option><option value="SM" data-country-name="San Marino">
                                                    San Marino
                                                </option><option value="ST" data-country-name="Sao Tome and Principe">
                                                    São Tomé &amp; Príncipe
                                                </option><option value="SA" data-country-name="Saudi Arabia">
                                                    Saudi Arabia
                                                </option><option value="SN" data-country-name="Senegal">
                                                    Senegal
                                                </option><option value="RS" data-country-name="Serbia">
                                                    Serbia
                                                </option><option value="SC" data-country-name="Seychelles">
                                                    Seychelles
                                                </option><option value="SL" data-country-name="Sierra Leone">
                                                    Sierra Leone
                                                </option><option value="SG" data-country-name="Singapore">
                                                    Singapore
                                                </option><option value="SX" data-country-name="Sint Maarten">
                                                    Sint Maarten
                                                </option><option value="SK" data-country-name="Slovakia">
                                                    Slovakia
                                                </option><option value="SI" data-country-name="Slovenia">
                                                    Slovenia
                                                </option><option value="SB" data-country-name="Solomon Islands">
                                                    Solomon Islands
                                                </option><option value="SO" data-country-name="Somalia">
                                                    Somalia
                                                </option><option value="ZA" data-country-name="South Africa">
                                                    South Africa
                                                </option><option value="GS" data-country-name="South Georgia and the South Sandwich Islands">
                                                    South Georgia &amp; South Sandwich Islands
                                                </option><option value="KR" data-country-name="Korea, Republic of">
                                                    South Korea
                                                </option><option value="SS" data-country-name="South Sudan">
                                                    South Sudan
                                                </option><option value="ES" data-country-name="Spain">
                                                    Spain
                                                </option><option value="LK" data-country-name="Sri Lanka">
                                                    Sri Lanka
                                                </option><option value="BL" data-country-name="Saint Barthélemy">
                                                    St. Barthélemy
                                                </option><option value="SH" data-country-name="Saint Helena">
                                                    St. Helena
                                                </option><option value="KN" data-country-name="Saint Kitts And Nevis">
                                                    St. Kitts &amp; Nevis
                                                </option><option value="LC" data-country-name="Saint Lucia">
                                                    St. Lucia
                                                </option><option value="MF" data-country-name="Saint Martin">
                                                    St. Martin
                                                </option><option value="PM" data-country-name="Saint Pierre And Miquelon">
                                                    St. Pierre &amp; Miquelon
                                                </option><option value="VC" data-country-name="Saint Vincent And The Grenedines">
                                                    St. Vincent &amp; Grenadines
                                                </option><option value="SD" data-country-name="Sudan">
                                                    Sudan
                                                </option><option value="SR" data-country-name="Suriname">
                                                    Suriname
                                                </option><option value="SJ" data-country-name="Svalbard And Jan Mayen">
                                                    Svalbard &amp; Jan Mayen
                                                </option><option value="SE" data-country-name="Sweden">
                                                    Sweden
                                                </option><option value="CH" data-country-name="Switzerland">
                                                    Switzerland
                                                </option><option value="TW" data-country-name="Taiwan">
                                                    Taiwan
                                                </option><option value="TJ" data-country-name="Tajikistan">
                                                    Tajikistan
                                                </option><option value="TZ" data-country-name="Tanzania, United Republic of">
                                                    Tanzania
                                                </option><option value="TH" data-country-name="Thailand">
                                                    Thailand
                                                </option><option value="TL" data-country-name="Timor-Leste">
                                                    Timor-Leste
                                                </option><option value="TG" data-country-name="Togo">
                                                    Togo
                                                </option><option value="TK" data-country-name="Tokelau">
                                                    Tokelau
                                                </option><option value="TO" data-country-name="Tonga">
                                                    Tonga
                                                </option><option value="TT" data-country-name="Trinidad and Tobago">
                                                    Trinidad &amp; Tobago
                                                </option><option value="TN" data-country-name="Tunisia">
                                                    Tunisia
                                                </option><option value="TR" data-country-name="Turkey">
                                                    Turkey
                                                </option><option value="TM" data-country-name="Turkmenistan">
                                                    Turkmenistan
                                                </option><option value="TC" data-country-name="Turks and Caicos Islands">
                                                    Turks &amp; Caicos Islands
                                                </option><option value="TV" data-country-name="Tuvalu">
                                                    Tuvalu
                                                </option><option value="UM" data-country-name="United States Minor Outlying Islands">
                                                    U.S. Outlying Islands
                                                </option><option value="VI" data-country-name="Virgin Islands, U.S.">
                                                    U.S. Virgin Islands
                                                </option><option value="UG" data-country-name="Uganda">
                                                    Uganda
                                                </option><option value="UA" data-country-name="Ukraine">
                                                    Ukraine
                                                </option><option value="AE" data-country-name="United Arab Emirates">
                                                    United Arab Emirates
                                                </option><option value="GB" data-country-name="United Kingdom">
                                                    United Kingdom
                                                </option><option value="US" data-country-name="United States">
                                                    United States
                                                </option><option value="UY" data-country-name="Uruguay">
                                                    Uruguay
                                                </option><option value="UZ" data-country-name="Uzbekistan">
                                                    Uzbekistan
                                                </option><option value="VU" data-country-name="Vanuatu">
                                                    Vanuatu
                                                </option><option value="VA" data-country-name="Holy See (Vatican City State)">
                                                    Vatican City
                                                </option><option value="VE" data-country-name="Venezuela, Bolivarian Republic of">
                                                    Venezuela
                                                </option><option value="VN" data-country-name="Vietnam">
                                                    Vietnam
                                                </option><option value="WF" data-country-name="Wallis and Futuna">
                                                    Wallis &amp; Futuna
                                                </option><option value="EH" data-country-name="Western Sahara">
                                                    Western Sahara
                                                </option><option value="YE" data-country-name="Yemen">
                                                    Yemen
                                                </option><option value="ZM" data-country-name="Zambia">
                                                    Zambia
                                                </option><option value="ZW" data-country-name="Zimbabwe">
                                                    Zimbabwe
                                                </option>
                                            </select>{errors.country && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.country.message}</span>}
                                        </div>

                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>
                                                Payment Volume
                                                <span style={{ color: "#f3686d" }}>*</span>
                                            </Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <select name="payment_volume" {...register("payment_volume")} defaultValue="">
                                                <option value="">Select a monthly amount</option>
                                                <option value="None, just getting started" data-route="sales/volume/none">None, just getting started</option>
                                                <option value="Less than $50,000" data-route="sales/volume/1-out-of-5">
                                                    Less than $50,000
                                                </option>
                                                <option value="$50,000 to $1,500,000" data-route="sales/volume/2-out-of-5">
                                                    $50,000 to $1,500,000
                                                </option>
                                                <option value="$1,500,000 to $15,000,000" data-route="sales/volume/3-out-of-5">
                                                    $1,500,000 to $15,000,000
                                                </option>
                                                <option value="$15,000,000 to $65,000,000" data-route="sales/volume/4-out-of-5">
                                                    $15,000,000 to $65,000,000
                                                </option>
                                                <option value="More than $65,000,000" data-route="sales/volume/5-out-of-5">
                                                   More than $65,000,000  
                                                </option>
                                            </select>{errors.payment_volume && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.payment_volume.message}</span>}
                                        </div>
                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>
                                                Company Size
                                                <span style={{ color: "#f3686d" }}>*</span>
                                            </Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <select name="Employee_Count_Form__c" {...register("company_size")} defaultValue="">
                                                <option value="">Select a range of employees</option>
                                                <option value="1-99">1-99</option>
                                                <option value="100-999">100-999</option>
                                                <option value="1000-4999">1000-4999</option>
                                                <option value="5000+">5000+</option>
                                            </select>{errors.company_size && <span style={{ color: "#f3686d", fontSize: "14px" }}>{errors.company_size.message}</span>}
                                        </div>
                                    </div>
                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4">
                                            <Form.Label>Anything Else?</Form.Label>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Tell us more about your project.."
                                                name="anything_else"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="row align-items-center"
                                        style={{ padding: "5px 0" }}
                                    >
                                        <div className="col-12 col-md-4"></div>
                                        <div className="col-12 col-md-8 text-right">
                                            <Button className="contact-sbmt" type="submit">
                                                Submit{" "}
                                                <span>
                                                    <IoIosArrowForward />
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </section >
            <FooterNew />
        </>
    );
};

export default Contact;
