import React from 'react'
import Header from '../Components/Common/Header';
import Footer2 from './Common/Footer2';
import Footer from './Common/Footer';
import { Accordion, Card } from 'react-bootstrap'
import Header2 from './Common/Header2';


const Faq = () => {
    return (
        <>
            {localStorage.getItem('access_token') !== null ?
                <Header2 />
                :
                <Header />
            }

            <section className="solution_secyion vision">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className="w-100" style={{ fontSize: "40px" }}>Frequently Asked Question</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq_section py-5">
                <div className="container">
                    <div className="row">
                        <aside className="col-lg-12 mt-4">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header className="pl-0">
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                            <span>1.</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className="pl-0">
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                        <span>2.</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className="pl-0">
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                                        <span>3.</span>  Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>ALorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className="pl-0">
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                                        <span>4.</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className="pl-0">
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
                                        <span>5.</span>  Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className="pl-0">
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="5">
                                        <span>6.</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                        </aside>
                    </div>
                </div>
            </section>

            {localStorage.getItem('access_token') !== null ?
                <Footer2 />
                :
                <Footer />
            }
            {/* <Footer/> */}
        </>
    )
}
export default Faq;