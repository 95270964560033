import React, { useEffect, useState } from 'react'
import { Breadcrumb, Modal, Tabs, Tab, Form, InputGroup } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import SearchUserApi from '../APIs/SearchUser';
import OtpInput from 'react-otp-input';
import Header2 from './Common/Header2'
import Pay from '../APIs/sendMoneyApi';
import Recentusers from '../APIs/RecentUserTranscApi';
import ForgetPin from '../APIs/ForgetPinApi';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import QrReader from 'react-qr-reader'
import { useHistory, useLocation } from 'react-router';
import Footer2 from './Common/Footer2';
import { config } from '../config';
import { v4 as uuidv4 } from 'uuid';

const Sendmoney = () => {
    const Media = config.api_URL
    const history = useHistory()
    const location = useLocation()
    const [to_user, setToUser] = useState({});
    const [smShow, setSmShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [resMsg, setResMsg] = useState();
    const [pinSet, setPinSet] = useState(false)
    const [userImage, setUserImage] = useState('')
    const [userName, setUserName] = useState('')
    const [showdiv, setShowDiv] = useState(0)
    const [memo, setMemo] = useState("");
    const [show, setShow] = useState(false);
    const [successpaymodal, setsuccesspaymodal] = useState(false);
    const [phone, setPhone] = useState('')
    const [Amount, setAmount] = useState('')
    const [pin, setPin] = useState('')
    const [showvalue, setShowValue] = useState(false)
    const [tab, setTab] = useState("home")
    const uuid = uuidv4()

    useEffect(() => {
        if (!localStorage.getItem("access_token")) {
            history.push('/login')
        }
        setLoader(true)
        window.setTimeout(() => {
            RecentList()
        }, 2000)
    }, [])

    const [userList, setUserList] = useState([])

    function RecentList() {
        Recentusers().then(Response => {
            if (Response.status == 200) {
                setLoader(false)
                setPinSet(Response.data.pin_set)
                setUserList(Response.data.recent)
            }
        }).catch(err => {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
        })
    }
    
    const modalClose = () => {
        setShow(false)
        setPin('')

    }

    const handleShow = (e, value, id) => {
        toast.dismiss()
        if (phone.trim() === "") {
            toast.error("Phone Number field can't be empty")
            return false;
        }
        else if (phone.trim().length < 10) {
            toast.error('Phone number should be 10 digits.')
            return false;
        } else if (Amount.trim() == '') {
            toast.error("Amount field can't be empty")
        } else {
            if (pinSet == true) {
                setShow(true);
            } else {
                setSmShow(true)
            }

        }
    }

    const hidesuccesspaymodal = () => {
        setsuccesspaymodal(false)
        setShow(false)
        history.go(0)
    }

    function Forget_pin() {
        setLoader(true)
        ForgetPin().then(Response => {
            if (Response.status == 200) {
                toast.success(Response.data.message)
                window.setTimeout(() => {
                    setLoader(false)
                    history.push('/forgotpin')
                }, 1000)
            }
        }).catch(function (err) {
            toast.error(err.response.data.message)
        })
    }

    function handleScan(event) {
        toast.dismiss()
        if (event !== null) {
            let data = JSON.parse(event)
            if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(data.phone)) {
                toast.error("Invalid QR.")
            } else {
                setToUser(data)
                setPhone(data.phone)
                setTab("home")
                // alert(tab)
            }


        }
    }

    function handleError(err) {
        console.log(err)
    }

    function onlyNumber(e) {
        toast.dismiss()
        if (/^[0-9\b]+$/.test(e)) {
            setPhone(e);
            if (e.length === 10) {
                setLoader(true)
                SearchUserApi({ 'phone': e }).then(Response => {
                    setToUser(Response.data)
                    setLoader(false)
                }).catch(err => {
                    setPhone('');
                    setToUser({})
                    toast.error(err.response.data.message)
                    setLoader(false)
                })
            } else {
                setToUser({})
            }
            return true;
        } else
            setPhone('');
    }
    function Handlepin(event) {
        setPin(event)
    }

    function handleClick(event) {
        setPin(event)
        const data = {
            'recv_user': phone,
            'amount': Amount,
            'pin': pin,
            'txnKey': uuid,
            'memo':memo
        }
        setLoader(true)
        Pay(data).then(Response => {
            if (Response.status == 200) {
                setsuccesspaymodal(true)
                setResMsg(Response.data.message)
                setShow(false)
                setLoader(false)
                setPin('')
                setPhone('')
                setAmount("")
                setMemo("")
                setToUser({})
                window.setTimeout(() => {
                    history.push('/sendmoney')
                }, 2000)
            }
        }).catch(function (err) {
            setPin('')
            //    setShow(false)
            setLoader(false)
            toast.error(err.response.data.message)
        })
    }
    function handleAmount(e) {
        if (/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/.test(e.target.value)) {
            setAmount(e.target.value)
            return true;
        }
        else if (e.target.value === "") {
            setAmount('')
            return false;
        }
    }

    const REQUIRED_FIELD = <small style={{ fontSize: "20px" }} className='text-danger'>*</small>

    return (
        <>
            <Header2 />
            <section className="bg_section py-5">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container-fluid">
                    <div className="row">
                        <aside className="col-md-12">
                            <div className="border_box pb-5">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/home" className="active"><i className="fa fa-angle-left mr-2" style={{ fontSize: "17px" }}></i>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Pay Money
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <div className="mt-4">
                                    <Tabs defaultActivekey="home" activeKey={tab} onSelect={(k) => setTab(k)} id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="home" title="Pay Money Via Phone Number">
                                            <div className="row mt-4">
                                                <div className="col-md-4">

                                                </div>
                                            </div>
                                            <div className="row mt-4 form-pay">
                                                <div className="col-lg-4 col-md-6 m-auto phone_input2">
                                                    {showdiv == 1 &&
                                                        <div className="user-img">
                                                            <span><img src={Media + userImage} alt="" /></span>
                                                            <p className="verfiied_text my-3">{userName}</p>
                                                        </div>
                                                    }

                                                    <Form.Group className="my-3" controlId="formBasicPhone">
                                                        <Form.Label>Phone Number{REQUIRED_FIELD}</Form.Label>
                                                        <PhoneInput
                                                            placeholder="E.g. (987-654-3210)"
                                                            disableCountryCode={true}
                                                            disableDropdown={true}
                                                            onlyCountries={['us']}
                                                            enableAreaCodes={true}
                                                            country={'us'}
                                                            value={phone}
                                                            onChange={(e) => onlyNumber(e)} />
                                                        {to_user.name &&
                                                            <div className="row my-3">
                                                                <div className="col-md-12 d-flex  text-white">
                                                                    <span className="user_img" style={{ margin: 0 }}>
                                                                        <img src={Media + to_user.image} alt="User_image" />
                                                                    </span>
                                                                    {
                                                                        to_user.is_business
                                                                            ?
                                                                            <span className="verify_img2"><img src={require('../images/verify.png').default} alt="" /></span>
                                                                            : ""
                                                                    }
                                                                    <p className="pl-4 pt-2">{to_user.name}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Form.Group>

                                                    {/* <InputGroup className="mb-3 dolar_icon">
                                                        <div className='w-100 d-flex'>
                                                            <InputGroup.Text>$</InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="E.g. $1234"
                                                                maxlength="10"
                                                                value={Amount}
                                                                onChange={handleAmount}
                                                            />
                                                        </div>
                                                    </InputGroup> */}

                                                    <Form.Group className="mb-1 mt-4" controlId="formBasicPhone">
                                                        <Form.Label>Enter Amount (in $){REQUIRED_FIELD}</Form.Label>
                                                        <Form.Control type="number" placeholder="E.g. $1234" inputMode="numeric" maxlength="10" value={Amount} onChange={handleAmount}  />
                                                    </Form.Group>

                                                    <Form.Group className="mb-1 mt-4" controlId="formBasicPhone">
                                                        <Form.Label>
                                                            Memo
                                                        </Form.Label>

                                                        <Form.Control as="textarea" rows={4} placeholder="What's this for? E.g. Grocery." maxlength={1499} value={memo} onChange={(e) => {
                                                            let val = e.target.value
                                                            if ((val.trim() != "" || val == "") && val.length <= 1500) {
                                                                setMemo(val)
                                                            }
                                                        }} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <div className="pay_btn mt-4">
                                                            <button className="btn" onClick={handleShow}>Next</button>
                                                        </div>
                                                    </Form.Group>

                                                    <p className="verfiied_text my-3">Pay money by verified phone number</p>
                                                </div>
                                            </div>

                                            <div className="row bank_list1">
                                                <div className="col-md-5">
                                                    {/* <h6 className="py-2" style={{color:"#868686", fontSize:"15px"}}>Your Saved Bank</h6> */}
                                                    <div className="row">
                                                        <h6 className="py-2 pl-3" style={{ color: "#868686", fontSize: "15px" }}>Recent Users</h6>
                                                        <div className="col-md-12">
                                                            <div className="scroll_list" >
                                                                <div className="bank_box border-0 p-0">
                                                                    <p className="empty-text mt-0">{userList.length == 0 ? " No users found" : ''}</p>
                                                                    {userList.length > 0 && userList.map((user) =>
                                                                        <div className="content users_list pb-2 mb-2" onClick={(event) => { setToUser(user); setPhone(user.phone) }}>
                                                                            <span className="user_img">
                                                                                <img src={Media + user.image} alt="" />
                                                                            </span>
                                                                            {
                                                                                user.is_business
                                                                                    ?
                                                                                    <span className="verify_img1"><img src={require('../images/verify.png').default} alt="" /></span>
                                                                                    : ""
                                                                            }
                                                                            <p className="main-para text-light mb-0">{user.name}</p>
                                                                            <p className="main-para">{user.phone}</p>
                                                                            <h6 className="price minus mb-0" style={{ float: "unset", lineHeight: "2", color: "#fb6466" }}>${user.amount}</h6>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-7">
                                                    <h6 className="py-2 text-center" style={{ color: "#868686", fontSize: "15px" }}>Wallet</h6>
                                                    <div className="row">

                                                        <div className="col-md-12 mb-3 text-center">
                                                            <div className="border-0 py-4 wallet_section">
                                                                <img src={require('../images/wallet1.svg').default} alt="" />
                                                                <h2>${localStorage.getItem('balance')}</h2>
                                                                <a className="btn addamount mt-2" href="/addmoney"><i className="fa fa-plus mr-1"></i>Add Amount</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </Tab>

                                        <Tab eventKey="profile" title="Pay Money Via Scan QR Code">
                                            <div className="row">
                                                <div className="col-lg-5 col-md-6 m-auto">
                                                    <div className="scanner_box text-center my-4">
                                                        <div className="scanner_bg">
                                                            {/* <img src={require('../images/barcode.png').default} alt="" /> */}
                                                            <QrReader
                                                                delay={500}
                                                                onError={handleError}
                                                                onScan={handleScan}
                                                                style={{ width: '100%' }}
                                                            />
                                                        </div>
                                                        <p className="pt-3">Scan a QR code to pay money.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>

            {/* <Modal className="sucessmodal" show={show} onHide={handleClose}
         size="sm"
         aria-labelledby="contained-modal-title-vcenter"
         centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content users_list">
                                <span className="user_img">
                                    <img src={require('../images/transaction-4.jpg').default} alt="" />
                                </span>
                                <p className="main-para text-light mb-0 mt-2 text-center">Alex Smith</p>
                                <p className="main-para text-center">+1 123-123-4123</p>
                            </div>
                            <hr className="bg-light" style={{opacity:".1"}} />
                            <div className="border_box payborder-box mt-3">
                                <Form.Group className="amount_row">
                                    <Form.Label>Enter Amount</Form.Label>
                                    <span>$</span>
                                    <Form.Control type="" placeholder="20"/>
                                </Form.Group>
                            </div>
                            <div className="pay_btn mt-2">
                                <button className="btn" onClick={showsuccessmodal}>Pay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal> */}

            <Modal className="sucessmodal" show={successpaymodal} onHide={hidesuccesspaymodal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="text-right justify-content-end">
                    <i className="fa fa-close text-white" onClick={hidesuccesspaymodal} style={{ cursor: "pointer" }}></i>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={require('../images/check.png').default} alt="" />
                                {/* <h5 className="text-white mt-3">$200</h5> */}
                                <p>{resMsg}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show} onHide={modalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="text-center w-100 pt-2">
                        <Modal.Title className="text-white">Enter your 4 digits PIN Number</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="otp_row pin_row">
                                    <OtpInput
                                        isInputNum={true}
                                        isInputSecure={showvalue == true ? false : true}
                                        inputMode="numeric"
                                        value={pin}
                                        onChange={Handlepin}
                                        inputStyle={"input101"}

                                        numInputs={4}
                                        separator={<span></span>}


                                    />

                                </div>
                                <p className="mt-2 mb-0 text-right forgot_pin">
                                    <a className="btn" onClick={(e) => { showvalue == false ? setShowValue(true) : setShowValue(false) }}>
                                        {showvalue == false ? <i className="fa fa-eye" aria-hidden="true"> Show PIN</i> : ''}{showvalue == true ? <span><i className="fa fa-eye-slash" aria-hidden="true"> Hide PIN</i></span> : ''}
                                    </a>
                                    <a className="btn" onClick={Forget_pin}>
                                        Forgot PIN?
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn signupbtn my-3" onClick={handleClick}>
                        Confirm
                    </button>
                </Modal.Footer>

            </Modal>


            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={smShow}
                onHide={() => setSmShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-white"></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="text-white modal-title h4">Create your 4 - digits PIN</div>
                    <a className="btn createpin_btn text-white my-3" onClick={() => history.push("/createpin")}>
                        Create PIN
                    </a>
                </Modal.Body>
            </Modal>
            <Footer2 />
        </>
    )
}
export default Sendmoney;