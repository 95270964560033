import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Link from '../link';
import { toast, Flip } from 'react-toastify';
import Logindata from '../../APIs/Loginservice';
import Header from './Header';
import Footer from './Footer';
import { LoadingSpinerComponent } from './loadingspinner';
const Login = (props) => {

    useEffect(() => {
        localStorage.removeItem('access_token')
    }, [])

    const [loader, setLoader] = useState(false)
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        toast.dismiss()
        toast.success("Successfully logged in.")
        props.history.push('/home')
    }

    function login() {
        toast.dismiss()
        if (user.trim() === "") {
            toast.error("Email or phone number field can't be empty")
            return false;
        }
        else if (password === "") {
            toast.error('password should not be empty')
            return false;
        }
        else {
            const fcmToken = 'jhgusguybvhjbvwhgiu654654564'
            const device = 'w'
            setLoader(true)
            Logindata.verifictaion(user, password, timezone, fcmToken, device).then(Response => {
                console.log(Response)

                if (Response.status === 200) {
                    localStorage.setItem('email', Response.data.email)
                    localStorage.setItem('phone', Response.data.phone)
                    if (Response.data.is_profile_created === false) {
                        localStorage.setItem('profile_created', Response.is_profile_created)
                        localStorage.setItem('access_token', Response.data.token)
                        props.history.push('/selectprofile')
                    }
                    // console.log(Response.data)
                    else {
                        if (Response.data.account_linked === 0) {
                            localStorage.setItem('access_token', Response.data.token)
                            setLoader(false)
                            setShow(true);
                            localStorage.setItem('profile_created', Response.is_profile_created)
                            localStorage.setItem('profile', Response.data.picture)
                            localStorage.setItem('name', Response.data.first_name)
                            localStorage.setItem('is_business', Response.data.is_business)


                        }

                        else {
                            setLoader(false)
                            localStorage.setItem('profile_created', Response.data.is_profile_created)
                            localStorage.setItem('is_business', Response.data.is_business)
                            localStorage.setItem('access_token', Response.data.token)
                            localStorage.setItem('profile', Response.data.picture)
                            localStorage.setItem('name', Response.data.first_name)
                            localStorage.setItem('notify', Response.data.notifications)
                            toast.success('Successfully logged in.')
                            props.history.push('/home')
                        }
                    }


                }
                else {
                    setLoader(false)
                    console.log('----message---', Response.message)
                    toast.error(Response.data.message)
                }

            }

            ).catch(function (err) {
                setLoader(false)
                toast.error(err.response.data.message)
            })
        }

    }

    function handleKey(e) {
        // alert(e.key)
        if (e.which == 13) {
            login();

        }
    }



    return (
        <>
            <Header />
            <section className="signup_section py-5">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-7 m-auto">
                            <div className="text-center">
                                <h4 className="text-white mb-2">Login with your email id or phone number</h4>
                            </div>
                            <div className="signup_box my-4">
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Label>Email ID or Phone Number</Form.Label>
                                        <Form.Control type="" placeholder="Enter your email id or phone number" onChange={(e) => setUser(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter your password" onKeyPress={(e) => handleKey(e)} onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>
                                    <div className="mt-3">
                                        <Button className="btn signupbtn mt-4" onClick={login}>
                                            Login
                                        </Button>
                                    </div>
                                    <div className="text-center mt-3 Existing">
                                        <p className="pb-0 mb-0 pt-2">
                                            <a href="/forgotpassword" className="text-white"><u>Forgot Password?</u></a>
                                        </p>
                                    </div>
                                    <div className="text-center mt-2 Existing">
                                        <p className="pb-0 mb-0 pt-3">New User? <a href="/signup" className="text-white"><u>Create Account</u></a></p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Modal show={show} onHide={handleClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="m-auto addpopup">
                    <Modal.Title className="linktext linktext1">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <div className="text-center my-2">
                        <img src={require('../../images/addbank.png').default} className="ml-2" alt="" />
                        <p className="linktext linktext1 mt-4 pt-2 mb-3">Please link your bank account.</p>
                        <Link />
                        <button className="btn skipbtn mb-3" onClick={handleClose}>Skip</button>
                    </div>

                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )
}
export default Login;