import axios from "axios";
import { config } from "../config";

function Change_Pin(data){
    return axios.post(`${config.api_URL}/accounts/change_pin`,data,{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}
export default Change_Pin;
