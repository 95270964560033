import React, { useEffect, useState } from 'react';
import { Nav, Tab, Form } from 'react-bootstrap'
import TransactionsL from '../APIs/TransactionList';
import Header2 from './Common/Header2';
// import ReactPaginate from 'react-paginate';
// import xtype from 'xtypejs'
import Footer from './Common/Footer2';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import Pagination from 'react-js-pagination';
import { useHistory } from 'react-router';
import Footer2 from './Common/Footer2';
import { config } from '../config';
// import Transactions_details from './Transactions_details';
// import Details from '../APIs/TransactionDetailApi';
// import { toast } from 'react-toastify';

const Transactions = (props) => {
    const [loader, setLoader] = useState(false)
    const [List, setList] = useState([])
    const yr = new Date().getFullYear()
    const [year, setYear] = useState(yr)
    const [sort, setSort] = useState("all");
    const [search, setSearch] = useState('')
    const [check, setCheck] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [pageCount, setPageCount] = useState('')
    const Media = config.api_URL
    const [pages, setPages] = useState(1)
    const [items, setItems] = useState(0)
    let history = useHistory()
    
    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            history.push('/login')
            return false;
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        var currrentPage = params.get('page')
        var sortKey = params.get('sort')
        var sortYear = params.get('year')
        if (!sortKey) {
            params.set("sort", "all")
            showByPage("all", yr, currrentPage)
        } else {
            showByPage(sortKey, yr, currrentPage)
        }
        setPages(Number(currrentPage))
    }, [search])

    

    function showByPage(eventKey, year, event) {
        let params = new URLSearchParams(window.location.search);
        let tempPage;
        if (!search) {
            setLoader(true)
        }
        setList([])
        setSort(eventKey)
        setYear(year)
        if (eventKey) {
            params.set("sort", eventKey)
        }
        if (year != yr) {
            params.set("year", year)
            params.delete("sort")
        } else {
            params.delete("year")
        }
        if (event.selected > -1) {
            setPages(Number(event.selected))
            tempPage = pages + 1
            params.set("page", tempPage)
        }
        else {
            tempPage = event
            params.set("page", tempPage)
            history.push('/transactions?' + params.toString())
            setPages(event)
        }
        TransactionsL(eventKey, year, tempPage, search).then(Response => {
            setLoader(false)
            // console.log(Response.data.has_old_txns)
            if (Response.status === 200) {
                setCheck(Response.data.has_old_txns)
                setList(Response.data.data)
                setPageCount(Response.data.pages)
                setItems(Response.data.total_count)
                setPageNumber(1)
            }
        }).catch(err => {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
        })
    }






    // function showByYear(params) {
    //     setYear(params)
    //     setList([]);
    //     TransactionsL(undefined,params,undefined).then(Response=>{
    //         //alert('year')
    //         console.log(Response.data)
    //         if (Response.status===200){
    //             setList(Response.data.data)
    //             setPageCount(Response.data.pages)
    //         }
    //     })
    // }








    return (
        <>
            <Header2 />
            <LoadingSpinerComponent promiseInProgress={loader} />
            <section className="transaction transaction_tabs py-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 main-tab-box">
                            <div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <div className='flex_tab_search'>
                                        <Nav variant="pills" className="">
                                            <div className="sort">
                                                Sort by:
                                            </div>
                                            {
                                                year == yr ?
                                                    <React.Fragment>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="all" active={'all' == sort} onSelect={(eventkey) => {
                                                                showByPage(eventkey, year, pageNumber)
                                                            }}>All</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="today" value={"today"} active={'today' == sort} onSelect={(eventkey) => showByPage(eventkey, year, pageNumber)}>Today</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="week" value={"week"} active={'week' == sort} onSelect={(eventkey) => showByPage(eventkey, year, pageNumber)}>This Week</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="month" value={"month"} active={'month' == sort} onSelect={(eventkey) => showByPage(eventkey, year, pageNumber)}>This Month</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="3months" value={"3months"} active={'3months' == sort} onSelect={(eventkey) => showByPage(eventkey, year, pageNumber)}>Last 3 Months</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="6months" value={"6months"} active={'6months' == sort} onSelect={(eventkey) => showByPage(eventkey, year, pageNumber)}>Last 6 Months</Nav.Link>
                                                        </Nav.Item>
                                                    </React.Fragment>
                                                    : ""
                                            }
                                            {loader == false &&
                                                <div className="select_row">

                                                    <span><i className="fa fa-angle-down text-white"></i></span>

                                                    <Form.Control value={year} onChange={(e) => {
                                                        if (e.target.value == yr) {
                                                            showByPage('all', yr, pageNumber);
                                                        }
                                                        else
                                                            showByPage('', e.target.value, 1)
                                                    }}
                                                        as="select"
                                                        custom
                                                    >
                                                        <option value={yr}>{yr}</option>
                                                        <option value={yr - 1}>{yr - 1}</option>
                                                        <option value={yr - 2}>{yr - 2}</option>
                                                        <option value={yr - 3}>{yr - 3}</option>
                                                        <option value={yr - 4}>{yr - 4}</option>

                                                    </Form.Control>

                                                </div>
                                            }
                                        </Nav>
                                        <div className='p-0'>
                                            <div className='flex_search'>
                                                <Form.Group controlId="formBasicPassword" className="mb-0">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search by user or phone number"
                                                        value={search}
                                                        onChange={(e) => {
                                                            let val = e.target.value
                                                            if (val.trim() != "" || val == "") {
                                                                setSearch(val)
                                                            }
                                                        }} />
                                                    {
                                                        search ?
                                                            <i className='fa fa-times cross_icon_search' onClick={() => setSearch("")}></i>
                                                            : ""
                                                    }
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>


                                    <Tab.Content>
                                        {/* <p className="empty-text">{  List.length == 0 ? " Hey, It's great to have you here! Start transfer money via PayRink to make transaction easy." : ''}</p> */}
                                        <Tab.Pane active={sort == 'all' || sort == 'today' || sort == 'week' || sort == 'month' || sort == '3months' || sort == '6months' || year == yr - 1 || year == yr - 2 || year == yr - 3 || year == yr - 4 || year == yr - 5}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="transaction-card">
                                                        <p className="main-para">Recent Transactions</p>
                                                        {check == true && List.length == 0 ?
                                                            <p className="empty-text">No transactions found</p>
                                                            :
                                                            <p className="empty-text"> {List.length == 0 ? "Welcome to PayRink! You can track all your transactions on this page. " : ''} </p>}
                                                        {
                                                            List.length > 0 && List.map((transc) =>
                                                            transc.fields.txn_type === "debit" ?
                                                                <a style={{cursor:"pointer"}} className="history d-block border-bottom border-dark" href={'/history?txn_key=' + transc.pk} >
                                                                    <div className="row carding trans1" >
                                                                        <aside className="col-md-9 col-sm-9" >
                                                                            <div className="content">
                                                                                <span className="user_img">
                                                                                    <img src={Media + transc.fields.from_account_img} alt="" />
                                                                                </span>
                                                                                {
                                                                                    transc.fields.is_business
                                                                                        ?
                                                                                        <span className="verify_img"><img src={require('../images/verify.png').default} alt="" /></span>
                                                                                        : ""
                                                                                }
                                                                                <p className="main-para text-light mb-0">{transc.fields.from_account}</p>
                                                                                <p className="main-para">{transc.fields.created_at}</p>
                                                                            </div>
                                                                        </aside>
                                                                        <aside className="col-md-3 col-sm-3 price-box">
                                                                            <span className="price minus">- ${transc.fields.amount}</span>
                                                                            <span className="price failed">{transc.fields.status}</span>
                                                                        </aside>
                                                                    </div>
                                                                </a>
                                                                :
                                                                <a style={{cursor:"pointer"}} className="history d-block border-bottom border-dark" href={'/history?txn_key=' + transc.pk} >
                                                                    <div className="row carding trans1">
                                                                        <aside className="col-md-9 col-sm-9">
                                                                            <div className="content">
                                                                                <span className="user_img">
                                                                                    <img src={Media + transc.fields.to_img} alt="" />
                                                                                </span>
                                                                                <p className="main-para text-light mb-0">{transc.fields.to}</p>
                                                                                <p className="main-para">{transc.fields.created_at}</p>
                                                                            </div>
                                                                        </aside>
                                                                        <aside className="col-md-3 col-sm-3 price-box">
                                                                            <span className="price">+ ${transc.fields.amount}</span>
                                                                            <span className="price failed">{transc.fields.status}</span>
                                                                        </aside>
                                                                    </div>

                                                                </a>


                                                        )
                                                        }





                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className="empty-text">{  List.length == 0 ? " Hey, It's great to have you here! Start transfer money via PayRink to make transaction easy." : ''}</p> */}
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                    {items > 0 ?
                        <div className="pagination-payrink">


                            {/* console.log("hello");                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                            <ReactPaginate 
                            
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}       
                            initialPage={0}
                            // forcePage={0}
                            disableInitialCallback={false}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={(event)=>{showByPage(sort,year,event)}}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            
                        /> */}

                            <Pagination
                                activePage={pages}
                                itemsCountPerPage={10}
                                totalItemsCount={items}
                                pageRangeDisplayed={5}
                                prevPageText="Previous"
                                nextPageText="Next"
                                onChange={(event) => { showByPage(sort, year, event) }}
                            />



                        </div>
                        : ""
                    }
                </div>
            </section>
            <Footer2 />
        </>
    )
}
export default Transactions;