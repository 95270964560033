import React from 'react';
import Loader from 'react-loader-spinner';


export const LoadingSpinerComponent = (props) => {
    return (
        <div className="loader">
        {
            (props.promiseInProgress === true) ?
                <div className="loader-div ml-5">
                    <Loader type="ThreeDots" color="#eb3434" height="100" width="100" />
                </div>
            :
                null
        } 
        </div>
    )
};