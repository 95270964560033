import React from 'react'
import Header from '../Components/Common/Header';
import Footer2 from './Common/Footer2';
import Footer  from './Common/Footer';

import Header2 from './Common/Header2';
import { useHistory } from 'react-router-dom';


const About = () => {
    const history = useHistory()
    function redirectLogin() {
        history.push("/login")
    }
    
    return(
        <>
        {localStorage.getItem('access_token')!==null?
            <Header2/>
        : 
            <Header/>
        }
        
        <section className="solution_secyion vision">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h1>About US</h1>
                        <h4 className="text-white mb-4">We aim to save the small businesses of Billions of money spent on transaction fees. With the money saved, we hope businesses can invest more on the customer experience and better compensate their employees.</h4>
                    </div>
                </div>
            </div>
        </section>

        <section className="vision1_section Accelerate_section fluid_section mt-4">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <div className="Accelerate_section_box">
                    <div className="Accelerate_section_left text-center">
                    <div>
                        <h5>About Us</h5>
                    </div>
                    </div>
                    <div className="Accelerate_section_right" style={{overflow:"hidden"}}>
                    <div>
                        <p>Every year, Banks and Credit card companies, Credit Card Processing companies make over $50 Billion on Fees associated with Credit card transactions. Many small businesses are struggling with the costs of operations. We talked to many small business who told us that our solution will enable them to better invest in their employees, expand their businesses and improve the overall customer experience. </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section className="transparency_section fluid_section">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <div className="transparency_section_box">
                    <div className="transparency_section_left text-center">
                    <div>
                        <h5>Unprecedented Transparency.</h5>
                        <h5>Impeccable Reliability.</h5>
                    </div>
                    </div>
                    <div className="transparency_section_right" style={{overflow:"hidden"}}>
                    <div>

                        <p>No More Hidden transaction fees. Know what to expect from the get go. PayRink Enables fast transactions at way lower cost than the current systems. With the additional savings, businesses can focus on providing a better customer experience. </p>
                                        <button className="btn learnmorebtn" onClick={() => redirectLogin()}>Learn More</button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="vision2_section Accelerate_section fluid_section">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <div className="Accelerate_section_box"> 
                <div className="Accelerate_section_left text-center">
                        <div>
                            <h5>Experienced <br />Leadership</h5>
                        </div>
                    </div>
                    <div className="Accelerate_section_right" style={{overflow:"hidden"}}>
                    <div>
                        <p>We collaborate  with Industry Experts, experienced financial professionals in developing and perfecting our products. </p>
                    </div>
                    </div>
                    
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="Numbers_section fluid_section py-5 my-3">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 text-center">
                <h2>We Take Pride in Our Numbers</h2>
                </div>
                <div className="col-md-12">
                    <div className="number_list mt-4">
                    <div className="numberlist1 mb-3">
                        <h4>$40+</h4>
                        <p className="mb-4">Projected Yearly Savings to Small Businesses</p>
                        <div className="rightslideline1"></div>
                    </div>

                    <div className="numberlist1 mb-3">
                        <h4>$0</h4>
                        <p className="mb-4">Interchange Fee</p>
                        <div className="rightslideline1"></div>
                    </div>

                    <div className="numberlist1 mb-3">
                        <h4>$0</h4>
                        <p className="mb-4">Assessment Fee</p>
                        <div className="rightslideline1"></div>
                    </div>

                    <div className="numberlist1 mb-3">
                        <h4>$0</h4>
                        <p className="mb-4">Authorization Fee</p>
                        <div className="rightslideline1"></div>
                    </div>

                    <div className="numberlist1 mb-3">
                        <h4>0.3%</h4>
                        <p className="mb-4">Platform Fee</p>
                        <div className="rightslideline1"></div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
        {localStorage.getItem('access_token')!==null?
            <Footer2/>
        :
            <Footer/>
        }
        {/* <Footer/> */}
        </>
    )
}
export default About;