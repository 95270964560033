import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap'
import { Flip, toast } from 'react-toastify';
import { stateName } from '../../APIs/Businessclassfyservice';
import { benficial } from '../../APIs/FormsAPI';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom'
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import moment from 'moment';
import Header3 from '../Common/header3';
import { LoadingSpinerComponent } from '../Common/loadingspinner';
import DateField from '../Common/DateField';
const Benficialowner = (props) => {

    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    // const [benificierImage, setBenificier] = useState(require('../../images/add.png').default)
    // const [DocImg, setDocImg] = useState('')
    // const [DocType, setDocType] = useState('')
    const [SSN, setSSN] = useState('')
    const [DOB, setDOB] = useState('')
    const [Country, setCountry] = useState('')
    const [Address, setAddress] = useState('')
    const [State, setState] = useState('')
    const [City, setCity] = useState('')
    const [PostalCode, setPostalCode] = useState('')
    const [StateName, setStateName] = useState('');
    const [loader, setLoader] = useState(false)
    const [agree, setAgree] = useState(false)
    const history = useHistory()


    const Maxdate = moment(new Date()).subtract(18, 'years')
    const newDate = new Date(Maxdate)

    useEffect(() => {
        history.listen(location => {
            if (history.action === 'POP') {
                props.history.push('/')
            }
        })
        stateNames()
    }, []);

    function stateNames() {
        stateName().then(res => {
            setStateName(res.data.data)
        })
    }

    function handleSubmit(event) {
        event.preventDefault()
        let date = moment(DOB).format("YYYY-MM-DD")
        const data = {
            FirstName,
            LastName,
            // DocImg,
            // DocType,
            SSN,
            data,
            Country,
            Address,
            State,
            City,
            PostalCode

        }
        toast.dismiss()
        if (FirstName.trim() === "") {
            toast.error("First Name field can't be empty")
            return false;
        }
        else if (LastName.trim() === "") {
            toast.error("Last Name field can't be empty")
            return false;
        }
        else if (SSN.trim() === "") {
            toast.error("SSN field can't be empty")
            return false;
        }
        else if (SSN.trim().length !== 9) {
            toast.error('Please enter valid SSN number')
            return false;
        }
        // else if (DocType && DocImg === "") {
        //     toast.error('Please upload document image')
        //     return false;
        // }
        // else if (DocImg && DocType === "") {
        //     toast.error('Plese select document type')
        //     return false;
        // }
        else if (DOB === "") {
            toast.error("DOB field can't be empty")
            return false;
        }
        else if (Address.trim() === '') {
            toast.error("Address field can't be empty")
            return false;
        }
        else if (State === '') {
            toast.error("Please select state")
            return false;
        }
        else if (City.trim() === '') {
            toast.error("City field can't be empty")
            return false;
        }
        else if (PostalCode.trim() === '') {
            toast.error("Zip Code field can't be empty")
            return false;
        }
        else if (PostalCode.trim().length !== 5) {
            toast.error('Please enter valid Zip Code')
            return false;
        }
        else if (Country === '') {
            toast.error('Please select Country')
            return false;
        } else if (agree == false) {
            toast.error('Please agree to beneficial ownership information consent.')
            return false;
        }
        else {
            setLoader(true)
            // console.log(data)
            benficial.beneficialOwner(data).then(Response => {
                // console.log(Response)
                if (Response.status === 200) {
                    setLoader(false)
                    toast.success(Response.data.message)
                    props.history.push('/login')
                }
                else {
                    toast.error(Response.data.message)
                }
            }).catch(function (err) {
                setLoader(false)
                console.log({ err })
                toast.error(err.response.data.message)
            })
        }
    }

    function onlySSN(e) {
        if (/^[0-9\b]+$/.test(e.target.value)) {

            setSSN(e.target.value);
        } else if (e.target.value === '') {
            // alert(e.target.value)
            setSSN('')
            return false
        }
    }

    function onlyPostal(e) {
        // alert(/^[0-9\b]+$/.test(e.target.value))
        if (/^[0-9\b]+$/.test(e.target.value)) {

            setPostalCode(e.target.value);
        } else if (e.target.value === '') {
            // alert(e.target.value)
            setPostalCode('')
            return false
        }


    }
    function onlyAddress(e) {
        if (/^[a-zA-Z0-9 ]{0,51}$/.test(e.target.value)) {
            setAddress(e.target.value)
        }
        else if (e.target.value = '') {
            setAddress('')
        }
    }

    return (
        <>
            <Header3 />
            <section className="signup_section py-5">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 col-md-12 m-auto">
                            <div className="text-center">
                                <h4 className="text-white mb-2">Beneficial Owner Details</h4>
                            </div>
                            <div className="signup_box my-4">
                                <div className="row">

                                    {/* <div className="col-md-3 mb-2">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Document Image</Form.Label>
                                            <div className="dcmnt_img">
                                                <span>
                                                    <img src={benificierImage} alt="" />

                                                </span>
                                                <input type="file" className='imginput_upload' accept="image/*" onChange={handlebenificierImage} />
                                            </div>
                                        </Form.Group>
                                    </div> */}

                                    <div className="col-md-12">
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control type="" placeholder="E.g.Adam" onChange={(e) => { setFirstName(e.target.value) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control type="" placeholder="E.g.Smith" onChange={(e) => { setLastName(e.target.value) }} />
                                                    </Form.Group>
                                                </div>

                                                {/* <div className="col-md-6 mb-2">
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>Document Type</Form.Label>
                                                        <Form.Control as="select" onChange={(e) => { setDocType(e.target.value) }}>
                                                            <option value=''>Select document type</option>
                                                            <option value="idCard">ID Card</option>
                                                            <option value="passport">Passport</option>
                                                            <option value="license">Driver License</option>
                                                            <option value="other">Other</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div> */}

                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>SSN</Form.Label>
                                                        <Form.Control type="" maxlength="9" value={SSN} placeholder="E.g. 123456789" onChange={(e) => { onlySSN(e) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2 datepicker1">

                                                    <Form.Group className="mb-3">
                                                        <Form.Label style={{ display: "block" }} >D.O.B</Form.Label>
                                                        {/* <DatePicker
                                                            className="mb-3"
                                                            onChangeRaw={(e) => { handleDateChangeRaw(e) }}
                                                            showMonthDropdown
                                                            showYearDropdown

                                                            maxDate={newDate}
                                                            selected={DOB}
                                                            onChange={(e) => { setDOB(e) }}
                                                            clearIcon={null}
                                                            dateFormat='MM-dd-yyyy'
                                                            placeholderText="MM-DD-YYYY"
                                                        /> */}
                                                        <DateField value={DOB} setValue={setDOB} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control as="select" onChange={(e) => { setCountry(e.target.value) }}>
                                                            <option>Select Country</option>
                                                            <option>US</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Address (Street)</Form.Label>
                                                        <Form.Control type="" maxLength="50" placeholder="E.g. California USA" value={Address} onChange={(e) => { onlyAddress(e) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Control as="select" onChange={(e) => { setState(e.target.value) }}>
                                                            <option>Select State</option>
                                                            {
                                                                StateName.length > 0 && StateName.map((States, index) => <option key={index} value={States.code}>{States.name} ,  {States.code}</option>)
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control type="" placeholder="E.g. Los Angeles" onChange={(e) => { setCity(e.target.value) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Zip Code</Form.Label>
                                                        <Form.Control type="" inputMode="numeric" value={PostalCode} maxlength="5" placeholder="E.g.12345" onChange={(e) => { onlyPostal(e) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-md-12 mb-2'>
                                                    <Form.Group className='mb-3 d-flex agreecheckox'>
                                                        <Form.Check checked={agree} onChange={(e) => setAgree(e.target.checked)} id='check' />
                                                        <Form.Label htmlFor='check'>I verify that the beneficial owner information I provided is complete and accurate.</Form.Label>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="btn submit1" onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </Form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Benficialowner;