import React, { useEffect, useState } from 'react';
import { getSiteSettings } from '../../APIs/getSiteSettings';
import { LoadingSpinerComponent } from './loadingspinner';

const FooterNew = () => {
  const [siteSettings, setSiteSettings] = useState(null);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSiteSettings()
      .then((res) => {
        setSiteSettings(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    setTimeout(() => {
      Loading && setLoading(false);
    }, 2000);
  }, []);

  if (Loading) {
    return <LoadingSpinerComponent promiseInProgress={Loading} />;
  }
  return (
    <>
      <footer className="contactfooter">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <a href="https://payrink.com/">
                <img
                  alt="img"
                  src={require("../../images/footLogo.png").default}
                />
              </a>
            </div>
            <div className="col-md-3 mb-2">
              <div>
                <ul className="p-0 mt-3 footer-ct-cnt">
                  {/* <li>
                    <p className="footercnt"><a href='tel:.phone_number'>{siteSettings?.phone_number}</a></p>
                  </li> */}
                  <li>
                    <p className="footercnt">
                      <a href="mailto:.sale_email">
                        {siteSettings?.sale_email}
                      </a>
                    </p>
                  </li>
                  {/* <li>
                    <p className="footercnt">
                      <a href="mailto:.support_email">
                        {siteSettings?.support_email}
                      </a>
                    </p>
                  </li> */}
                  <li>
                    <p className="footercnt">{siteSettings?.address}</p>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-md-3 mb-2 quick-links-col">
              <h5 className="quicklinks">Quick Links</h5>

              <ul className="p-0 mt-3">
                <li className="quick-list">
                  <a className="" href="/">
                    Home
                  </a>
                </li>
                <li className="quick-list">
                  <a className="" href="/solutions">
                    Solutions
                  </a>
                </li>
                <li className="quick-list">
                  <a className="" href="/about-us">
                    About Us
                  </a>
                </li>
                <li className="quick-list">
                  <a className="" href="/terms">
                    Terms of Services
                  </a>
                </li>
                <li className="quick-list">
                  <a className="" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div> */}

            {/* <div className="col-md-3 mb-2">
              <h5 className="quicklinks">Follow Us On:</h5>
              <div>
                <ul className="p-0 mt-3 social_row">
                  <li>
                    <a className="social_links" target="_blank" href={siteSettings?.linkedin_url}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a className="social_links"  target="_blank" href={siteSettings?.facebook_url}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a className="social_links"  target="_blank" href={siteSettings?.twitter_url}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      <section className="copyrightcontact">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <p className="pb-0 mb-0">© 2022 PayRink | All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterNew;
