import axios from "axios";
import { config } from "../config";

export const signupApi = (payload) => {
  
    const {
            phone,email,password ,otpGet,repassword,timezone, checked
    } = payload
    let params = {
        "phone":phone,
        "email": email,
        "otp":otpGet,
        "password1": password,
        "password2": repassword,
        "timezone":timezone,
        "agree":checked
    };
    return axios.post(
        `${config.api_URL}/accounts/verify_otp`,params);
}



export const generate_otp = (data) => {
    console.log(data.email)
    const params ={ //object destructuring
        "phone":data.phone,
        "email":data.email,
        "password1":data.password,
        "password2":data.repassword,
        "agree":data.checked
    }
    return axios.post(
        `${config.api_URL}/accounts/generate_otp`,params);
}


// export const loginaApi = (data) => {
//     console.log('hello')
//     let params={
//             "username":data,
//             "password":data
//     }
//     axios.post(`${config.api_URL}/adminAPI/login`,params);
//     return  localStorage.setItem('token','bjshagckasgckjaskjcbaksjbcvkjasbvkjb')

    
// }