import React from 'react'
import Header from '../Components/Common/Header';
import Footer2 from './Common/Footer2';
import Footer  from './Common/Footer';

import Header2 from './Common/Header2';


const Vision = () => {
    return(
        <>
        {localStorage.getItem('access_token')!==null?
            <Header2/>
        : 
            <Header/>
        }
        
        <section className="solution_secyion vision">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Our Mission</h1>
                        <h4 className="text-white mb-4">Our Mission is to Save the American Small Business. We aim to save the small businesses of Billions of money spent on transaction fees. With the money saved, we hope businesses can invest more on the customer experience and better compensate their employees.</h4>
                    </div>
                </div>
            </div>
        </section>

        <section className="vision1_section Accelerate_section fluid_section mt-4">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <div className="Accelerate_section_box">
                    <div className="Accelerate_section_left text-center">
                    <div>
                        <h5>Our Story</h5>
                    </div>
                    </div>
                    <div className="Accelerate_section_right" style={{overflow:"hidden"}}>
                    <div>
                        <p>Every year, Banks and Credit card companies, Credit Card Processing companies make over $50 Billion on Fees associated with Credit card transactions. Many small businesses are struggling with the costs of operations. We talked to many small business who told us that our solution will enable them to better invest in their employees, expand their businesses and improve the overall customer experience. </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section className="vision2_section Accelerate_section fluid_section">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <div className="Accelerate_section_box"> 
                    <div className="Accelerate_section_right" style={{overflow:"hidden"}}>
                    <div>
                        <p>We collaborate  with Industry Experts, experienced financial professionals in developing and perfecting our products. </p>
                    </div>
                    </div>
                    <div className="Accelerate_section_left text-center">
                        <div>
                            <h5>Experienced <br />Leadership</h5>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        {localStorage.getItem('access_token')!==null?
            <Footer2/>
        :
            <Footer/>
        }
        {/* <Footer/> */}
        </>
    )
}
export default Vision;