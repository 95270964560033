import React from 'react'
import {Navbar, Nav, Form , Button} from 'react-bootstrap'
const Header3 = () =>{

    return(
        <>
                <header>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Navbar expand="lg" className="px-0">
                                    <Navbar.Brand href="">
                                    <img src={require("../../images/logo.png").default} alt="img" className="web_logo" />
                                        <img src={require("../../images/mob_logo.png").default} alt="img" className="mob_logo" />
                                    </Navbar.Brand>
                                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="ml-auto">
                                            <Nav.Link onClick={Checks}>Home</Nav.Link>
                                            <Nav.Link href="#link">Solution</Nav.Link>
                                            <Nav.Link href="#link">Vision</Nav.Link>
                                        </Nav>
                                        <Form inline>
                                            <Button variant="outline-light" href='/signup'>Get Started</Button>
                                        </Form>
                                    </Navbar.Collapse> */}
                                    </Navbar>
                            </div>
                        </div>
                    </div>
                </header>
        </>
    )
}
export default Header3;