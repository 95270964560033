import axios from 'axios'
import { usePlaidLink } from 'react-plaid-link'
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import { config, environment } from '../config';
const Link1 = (props) => {
  const [token, setToken] = useState('')
  useEffect(() => {
    // alert('useEffect')
    generateToken();
    // .catch(function (err) {alert(Response.data.message)})
  }, [])

  const [loader, setLoader] = useState(false)

  function generateToken() {

    generatedToken().then(Response => {
      console.log(Response.data)
      setToken(Response.data.link_token)
    })
  }

  function generatedToken() {


    return axios.get(`${config.api_URL}/accounts/plaid_link_token`, {
      headers: {
        'Authorization': localStorage.getItem('access_token')
      }
    })
  }

  const onSuccess = useCallback((public_token, metadata) => {
    setLoader(true)
    const data = {
      "public_token": public_token,
      "accountID": metadata.account.id,
      "mask": metadata.accounts[0].mask,
      "bankName": metadata.institution.name,
      "bankID": metadata.institution.institution_id
    }
    toast.dismiss()
    axios.post(`${config.api_URL}/accounts/plaid_exchange_token`, data, {
      headers: {
        'Authorization': localStorage.getItem('access_token')
      }
    }).then(Response => {
      if (Response.status === 200) {
        console.log(Response.data)
        toast.success(Response.data.message)
        window.setTimeout(() => {
          setLoader(false)
          window.location.href = '/addmoney'
        }, 2000)
      }
    }).catch(function (err) {
      setLoader(false)
      console.log({ err })
      toast.error(err.response.data.message)
    })
  }
    , [])







  const plaidConfig = {
    clientName: 'PayRink',

    env: environment,
    product: ['auth', 'transactions'],
    token: token,
    onSuccess,
    // ...
  };

  const { open, ready, error } = usePlaidLink(plaidConfig);

  return token === null ? (
    <div className="loader"></div>) :
    // <PlaidLink>
    (<div><LoadingSpinerComponent promiseInProgress={loader} />
      <button onClick={() => open()} disabled={!ready} id="connect_btn" className="show1">

        Link Account
      </button>
    </div>
      // </PlaidLink>
    );
};
export default Link1;