import React, {useState} from 'react'
import {Form} from 'react-bootstrap';
import Footer from './Common/Footer2';
import Header2 from './Common/Header2';
import OtpInput from 'react-otp-input';
import ForgetPin from '../APIs/ForgetPinApi';
import { toast } from 'react-toastify';
import Create_Pin from './Create_Pin';
import Reset_Pin from '../APIs/ResetPinApi';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import { useHistory } from 'react-router';
import Footer2 from './Common/Footer2';

const Forgot_Pin = () => {
    const [OTP, setOTP] = useState("");
    const [OTP1, setOTP1] = useState("");
    const [loader, setLoader] = useState(false);
    let history=useHistory()

    function HandleOtp(){
        setLoader(true)
        toast.dismiss()
        const data = {
            'otp':OTP
        }
        Reset_Pin(data).then(Response=>{
            if(Response.status==200){
                localStorage.setItem('pinotp',OTP)
                toast.success(Response.data.message)
                window.setTimeout(()=>{
                    setOTP('')
                    setLoader(false)
                    history.push('/createpin')
                },3600)
            }
        }).catch(function(err){
            if(err.response.status==401){
                localStorage.removeItem('access_token')
                history.push('/login')
            }
            setOTP('')
            setLoader(false)
            toast.error(err.response.data.message)
        })
    }
    
    return (
        <>
            <Header2 />
            <section className="bg_section py-5 forgotpin_section">
            <LoadingSpinerComponent promiseInProgress = {loader} />
                <div className="container">
                    <div className="row">
                        <aside className="col-lg-6 col-md-7 m-auto">
                            <div className="text-center">
                                <h4 className="text-white mb-2">Forgot your PIN?</h4>
                                <p className="text-white">The OTP has been sent on your registered email, please check!</p>
                                <Form>
                                    <Form.Group className="mb-3 text-center" controlId="formBasicPhone">
                                        <Form.Label className="text-secondary mt-3">Enter OTP</Form.Label>
                                        <div className="otp_row pin_row mt-1">
                                            <OtpInput
                                            inputMode="numeric"
                                            value={OTP}
                                            onChange={setOTP}
                                            inputStyle={"input101"}
                                            // onChange={handleClick}
                                            numInputs={6}
                                            separator={<span></span>}
                                            />

                                            </div>
                                            <div className="text-center"> 
                                                <a className="btn signupbtn mt-4 mb-2" onClick={HandleOtp} >
                                                SUBMIT
                                                </a>
                                            </div>
                                    </Form.Group>
                                </Form>
                            </div>
                            {/* <div className="text-center">
                                <h4 className="text-white mb-2">Forgot your PIN?</h4>
                                <p className="text-white">Please set your PIN to make your payment more secure while PAY.</p>
                            
                            <div className="border_box pb-3 mt-3">
                                <div className="signup_box my-2">
                                <Form>
                                    <Form.Group className="mb-3 text-center" controlId="formBasicPhone">
                                        <Form.Label>Enter New PIN</Form.Label>
                                        <div className="otp_row pin_row mt-1">
                                            <OtpInput
                                            inputMode="numeric"
                                            value={OTP}
                                            onChange={setOTP}
                                            inputStyle={"input101"}
                                            numInputs={4}
                                            separator={<span></span>}
                                            />

                                            </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword" className="mt-2 text-center">
                                        <Form.Label>Enter Confirm PIN</Form.Label>
                                        <div className="otp_row pin_row mt-1">
                                            <OtpInput
                                            inputMode="numeric"
                                            value={OTP1}
                                            onChange={setOTP1}
                                            inputStyle={"input101"}
                                            numInputs={4}
                                            separator={<span></span>}
                                            />

                                            </div>
                                    </Form.Group>
                                    <div className="text-center"> 
                                        <a className="btn signupbtn my-2" href="/sendmoney">
                                        SUBMIT
                                        </a>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                            </div> */}
                        </aside>
                    </div>
                </div>
            </section>
            <Footer2 />
        </>
    )
}
export default Forgot_Pin;