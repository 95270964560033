import axios from "axios";
import { config } from "../config";

function GeneratePin(pin){
    return axios.post(`${config.api_URL}/accounts/create_pin`,{'pin':pin},{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}

export default GeneratePin;