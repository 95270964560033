import axios from "axios";
import { config } from "../config";



function deleteAccount(data){
    return axios.post(`${config.api_URL}/accounts/remove_account`,data,{headres:{

    'Authorization':localStorage.getItem('access_token')
    }})
}

export default deleteAccount;