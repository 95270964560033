import axios from "axios";
import { config } from "../config";


function NewNumberOtp(data){
  
    return axios.post(`${config.api_URL}/accounts/otp_new_number`,data,{
        headers:{'Authorization':localStorage.getItem('access_token')}
})
}

export default NewNumberOtp;