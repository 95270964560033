import axios from "axios";
import { config } from "../config";


function addMoney(data) {
    return axios.post(`${config.api_URL}/accounts/add_money`, data,
        {
            headers: {
                'Authorization': localStorage.getItem('access_token')
            }
        }
    )
}

export default addMoney;