import axios from "axios";
import { config } from "../config";

function Recentusers(){
    return axios.get(`${config.api_URL}/accounts/filter_contacts`,{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}

export default Recentusers;