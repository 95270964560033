import axios from "axios";
import { config } from "../config";

function GetNotification(page){
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone
    return axios.get(`${config.api_URL}/accounts/notifications?page='+page+'&timezone=`+timezone,{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}

export default GetNotification;