import axios from "axios";
import testUtils from "react-dom/test-utils";
import { config } from "../config";


const Logindata ={
    verifictaion
}

function verifictaion(user,password,timezone,fcmToken,device){
    // let formData = new FormData();
    // formData.append('username',user);
    // formData.append('password',password);
    // console.log('username-->>>',user)
    // console.log('password-->>>',password)
    // console.log('timezone-->>>',timezone)
    return axios.post(
    `${config.api_URL}/accounts/auth-token`,{'username':user,"password":password,"timezone":timezone,"fcmToken":fcmToken,"device":device});
}

export default Logindata; 