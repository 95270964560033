import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';

// import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase';
// import NotificationManager  from 'react-notifications';
// import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import ReactNotification from 'react-notifications-component'
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { useLocation } from 'react-router';
// import 'animate.css/animate.compat.css'


axios.defaults.baseURL = "http://192.168.2.22:8000/";
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem('access_token');

  var firebaseConfig = {
    apiKey: "AIzaSyClftC-CFYY7OEUoESI0K3vqhxLqulY26E",
    authDomain: "payrinkofficial.firebaseapp.com",
    projectId: "payrinkofficial",
    storageBucket: "payrinkofficial.appspot.com",
    messagingSenderId: "135751746305",
    appId: "1:135751746305:web:1d97203298952a6f711100",
    measurementId: "G-XTZJ63DCFQ"
  };
  
  if(firebase.messaging.isSupported()){
    firebase.initializeApp(firebaseConfig);
    const messaging = firebase.messaging();
    messaging.getToken({vapidKey: "BIXINx_g0uHWHYxe2V_UtJtJa6vm9_b7KR17MuRginrwQ2HbIIUl8fxdtEFN-_jLmWwMS_HJsy5ei5I4hFQrFgY"}).then((currentToken) => {
      if (currentToken) {
        localStorage.setItem('f@m',currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
    messaging.onMessage((payload) => {
      store.addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        type: 'info',
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 10000,
          onScreen: true,
          click:true,
          showIcon:true,
          pauseOnHover: true
        }
        
      })
      
      // console.log(location.pathname);
      if(window.location.pathname=='/transactions'){
        alert(window.location.pathname)
            window.setTimeout(()=>{
              window.location.reload();
            },2000);
    }
     
      console.log('Message received. ', payload);
      // ...
    });
  }
  
  // <ReactNotification />

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
