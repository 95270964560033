import moment, { invalid } from 'moment';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { businessSign } from '../../APIs/FormsAPI';
import Footer from '../Common/Footer';
import Header3 from '../Common/header3';
import { LoadingSpinerComponent } from '../Common/loadingspinner';
import Step1 from "./Step1";
import Step2 from "./Step2";

const Business = (props) => {
    const [step, setStep] = useState(1);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const [stepBack, setStepBack] = useState({});
    const [loader, setLoader] = useState(false)
    const [start, setStart] = useState(false)
    const [loading, setLoading] = useState(false)


    const nextStep = () => {
        if (step < 2) {
            setStep(step + 1);
        } else if (step === 2) {
            handleShow();
        }
    };

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const backData = (event) => {
        setStepBack(event)
    }

    function handleStep1(event) {
        setStep1Data(event)
        toast.dismiss()
        if (event.businessName.trim() === "") {
            toast.error("Business Name field can't be empty")
            return false;
        }
        else if (event.businessType === "") {
            toast.error('Please select business type')
            return false;
        }
        else if (event.businessClassification === "") {
            toast.error('Please select Business category')
            return false;
        }
        else if (event.industryClassification === "") {
            toast.error('Please select industry category')
            return false;
        }
        else if (event.ein.trim() === '') {
            toast.error("EIN field can't be empty")
            return false;
        }
        else if (event.ein.trim().length !== 9) {
            toast.error('Please enter valid EIN number')
            return false;
        }
        else if (event.address.trim() === '') {
            toast.error("Business Address can't be empty")
            return false;
        }
        else if (event.state === '') {
            toast.error('Please select state')
            return false;
        }
        else if (event.city.trim() === '') {
            toast.error("City field can't be empty")
            return false;
        }
        else if (event.postalcode.trim() === '') {
            toast.error("Zip Code field can't be empty")
            return false;
        }
        else if (String(event.postalcode.trim()).length !== 5) {
            toast.error('Please enter valid Zip Code')
            return false;
        }
        else if (event.businessType != 'soleProprietorship' && event.licence == undefined) {
            toast.error('Please upload business license')
            return false
        }
        else {
            setLoader(true)
            setTimeout(() => {
                setLoader(false)
                nextStep(1);
            }, 2000)
            // alert(event.picture)
            window.scrollTo(0, 0);
        }

    }

    function handleStep2(event) {
        toast.dismiss()
        event && setStep2Data(event);
        // return false
        if (event.first_name.trim() === "") {
            toast.error("First Name field can't be empty")
            return false;
        }
        else if (event.last_name.trim() === "") {
            toast.error("Last Name field can't be empty")
            return false;
        }
        else if (event.ssn.trim() === '') {
            toast.error("SSN field can't be empty")
            return false;
        }
        else if (event.dateOfBirth === "") {
            toast.error("DOB field can't be empty")
            return false;
        }
        else if (event.ssn.trim().length !== 9) {
            toast.error("Please enter valid SSN number")
            return false;
        }
        else if (event.owner_address.trim() === "") {
            toast.error("Address field can't be empty")
            return false;
        }

        else if (event.owner_city.trim() === '') {
            toast.error("City field can't be empty")
            return false;
        }
        else if (event.owner_state === '') {
            toast.error("State field can't be empty")
            return false;
        }
        else if (event.owner_postalCode.trim() === '') {
            toast.error("Zip Code field can't be empty")
            return false;
        }
        else if (event.owner_postalCode.trim().length !== 5) {
            toast.error("Please enter valid Zip Code")
            return false;
        }
        else if (step1Data.businessType != "soleProprietorship" && (event.owner_docType === '' || event.owner_document === "")) {
            if (event.owner_docType === '') {
                toast.error("Please select document type")
                return false;
            }
            else if (!event.owner_document) {
                toast.error('Please upload document image')
                return false;
            }
        }

        else {
            if (localStorage.getItem('Type') !== 'soleProprietorship') {
                nextStep(2)
            }
            else {
                const signupdata = Object.assign(step1Data, {...event, dateOfBirth:moment(event.dateOfBirth).format("YYYY-MM-DD")});
                setLoading(true)
                businessSign.businessUserSignup(signupdata).then(Response => {
                    if (Response.status === 200) {
                        setLoading(false)
                        toast.success(Response.data.message)
                        props.history.push('/login')
                    }
                }).catch(function (err) {
                    setLoading(false)
                    if (err.response?.status == 400) {
                        toast.error(err.response.data.message)
                    } else {
                        toast.error("Internal Server Error.")
                    }
                })
            }

        }
    }

    function businessSignup(event) {
        const signupdata = Object.assign(step1Data, {...step2Data, dateOfBirth:moment(step2Data.dateOfBirth).format("YYYY-MM-DD")});
        toast.dismiss()
        setStart(true)
        businessSign.businessUserSignup(signupdata).then(Response => {
            if (Response.status === 200) {
                setStart(false)
                toast.success(Response.data.message)
                props.history.push('/login')
            }
        }).catch(function (err) {
            setStart(false)
            toast.error(err.response.data.message)
        })
    }

    function businessSignupYes(event) {
        setStart(true)
        const signupdata = Object.assign(step1Data, step2Data);
        toast.dismiss()
        businessSign.businessUserSignup(signupdata).then(Response => {
            if (Response.status === 200) {
                setStart(false)
                toast.success(Response.data.message)
                props.history.push('/benficialowner')
            }
            else {

                toast.error(Response.data.message)
            }
        }).catch(function (err) {
            setStart(false)
            console.log('error-->>>>', err)
            toast.error(err.response.data.message)
        })


    }


    return (
        <>
            <Header3 />
            <section className="signup_section py-5">
                <LoadingSpinerComponent promiseInProgress={loading} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 col-md-12 m-auto">
                            <div className="text-center">
                                <h4 className="text-white mb-2">Create Profile</h4>
                            </div>

                            <div className="signup_box my-4">
                                {
                                    {
                                        1: <Step1 handleStep1={handleStep1} step1Data={step1Data} loader={loader} />,
                                        2: <Step2 handleChange={prevStep} handleStep2={handleStep2} backData={backData} stepBack={stepBack} setStep2Data={setStep2Data} businessType={step1Data.businessType} />,
                                    }[step]
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show} onHide={handleClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="p-0">
                    <LoadingSpinerComponent promiseInProgress={start} />
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 m-auto p-1">
                                <div className="signup_box my-2 text-center">
                                    <h5 className="text-white mt-3 mb-4">Does business have other beneficial owner?</h5>
                                    <div className="yesnobtn">
                                        <a className="btn yesbtn text-white" onClick={event => businessSignupYes(event)}>Yes</a>
                                        <a className="btn nobtn text-white" onClick={event => businessSignup(event)}>No</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )
}
export default Business;