import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import BankList from '../APIs/BankListApi';
import ForgetPin from '../APIs/ForgetPinApi';
import deleteAccount from '../APIs/RemoveBankApi';
import Money from '../APIs/withdrawMoney';
import { config } from '../config';
import Footer2 from './Common/Footer2';
import Header2 from './Common/Header2';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import Link2 from './link2';

const Withdraw = (props) => {

    const [id, setId] = useState('')
    const [show, setShow] = useState(false);
    const [AccountList, setAccountList] = useState([]);
    const [AccountId, setAccountId] = useState('');
    const [Amount, setAmount] = useState('');
    const [noBalance, setNoBalance] = useState(false);
    const [loader, setLoader] = useState(false)
    const [account, setAccount] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [pinShow, setPinShow] = useState(false)
    const [showvalue, setShowValue] = useState(false)
    const [smShow, setSmShow] = useState(false);
    const [pin, setPin] = useState('')
    const [pinSet, setPinSet] = useState(false)
    const [fundsFlow, setFundsFlow] = useState("")
    const [Res, setRes] = useState("")
    const [bal, setBal] = useState(localStorage.getItem("balance"))

    const handleClose = () => {
        setShow(false);
        history.push('/withdraw')
    }


    const handleShow = () => {
        toast.dismiss()
        if (Amount == '') {
            toast.error("Amount field can't be empty.")
        } else if (Amount < 1) {
            toast.error('Payment must be at least $1')
        } else if (fundsFlow == "") {
            toast.error('Please selet withdraw option')
        } else if (Number(localStorage.getItem("balance")) < Amount) {
            setNoBalance(true)
        } else if (AccountId == '') {
            toast.error("Please select at least one bank.")
        } else {
            if (pinSet == true) {
                setPinShow(true)
            } else {
                setSmShow(true)
            }
        }
    }

    function modalClose() {
        setPinShow(false)
        setPin('')
    }

    function ConfirmMoney() {
        setLoader(true)
        const data = {
            "account_id": AccountId,
            "amount": Amount,
            "txnKey": uuid(),
            'pin': pin,
            "flow": fundsFlow
        }
        toast.dismiss()
        Money(data).then(Response => {
            if (Response.status === 200) {
                setLoader(false)
                setRes(Response.data.message)
                setPin('')
                setShow(true)
                // toast.success(Response.data.message)
                setPinShow(false)

            }
        }).catch(function (err) {
            //  setPinShow(false)
            setPin('')
            toast.error(err.response.data.message)
            setLoader(false)
        }

        )
    }

    function Forget_pin() {
        setLoader(true)
        toast.dismiss()
        ForgetPin().then(Response => {
            if (Response.status = 200) {
                toast.success(Response.data.message)
                window.setTimeout(() => {
                    setLoader(false)
                    history.push('/forgotpin')
                }, 2300)
            }
        }).catch(function (err) {
            toast.error(err.response.data.message)
        })
    }


    function handleNoBalance() {
        setAmount('')
        setNoBalance(false)
    }
    function handleConfirmClose() {
        setId('')
        setConfirm(false)
    }

    function handleConfirm(event, id) {
        event.preventDefault()
        // alert(id)
        setConfirm(true)
        setId(id)
    }
    function handleClick(event) {
        setPin(event)
    }

    const removeAccount = (event) => {
        event.preventDefault()
        setAccount(true)
        const data = {
            'account_id': id
        }
        toast.dismiss()
        deleteAccount(data).then(Response => {
            if (Response.status === 200) {
                toast.success(Response.data.message)
                window.setTimeout(() => {
                    setConfirm(false)
                    setAccount(false)
                    history.push('/withdraw')
                }, 2000)
            }
        }).catch(function (err) { alert(JSON.stringify({ err })) })
    }

    useEffect(() => {
        setLoader(true)
        if (!localStorage.getItem('access_token')) {
            history.push('/login')
            return false;
        }
        window.setTimeout(() => {
            List();
        }, 2000)

    }, [])
    let history = useHistory()

    const Media = config.api_URL


    function List() {
        toast.dismiss()
        setLoader(true)
        BankList().then(Response => {
            setPinSet(Response.data.pin_set)
            setAccountList(Response.data.data)
            setLoader(false)
        }).catch(function (err) {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
            toast.error(err.response.data.message)
            setLoader(false)
        })
    }

    function handleAmount(e) {
        if (/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/.test(e.target.value)) {
            setAmount(e.target.value)
            return true;
        }
        else if (e.target.value == "") {
            setAmount('')
            return false;
        }
    }


    function formatInput(e) {
        if (e.keyCode == 69 || e.keyCode == 189) {
            // setAmount('')
            e.preventDefault();
            return false;
        } else {
            // setAmount('');
            setAmount(e.target.value);
            return true;
        }
    }


    return (
        <>
            <Header2 />
            <section className="bg_section py-5">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container-fluid">
                    <div className="row">
                        <aside className="col-md-12">
                            <div className="border_box pb-5">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/home" className="active"><i className="fa fa-angle-left mr-2" style={{ fontSize: "17px" }}></i>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Withdraw Money
                                    </Breadcrumb.Item>
                                </Breadcrumb>

                                <div className="row mt-4 divsrow">
                                    <aside className="leftside1">
                                        <Form.Group className="amount_row">
                                            <Form.Label>Enter Amount</Form.Label>
                                            <span>$</span>
                                            <Form.Control type="text" inputMode="numeric" maxlength="10" value={Amount} placeholder="1.00" onKeyDown={(e) => { formatInput(e) }} onChange={(e) => { handleAmount(e) }} />
                                        </Form.Group>

                                        <Form.Group>
                                            <label>Please select withdraw option</label>
                                        </Form.Group>

                                        <Row>
                                            <Col sm={12} md={6} lg={6} className="mt-3">
                                                <div className='withdraw-options text-center' onClick={() => setFundsFlow("same_day")}>
                                                    <div className='withdraw-icons'>
                                                        <i className={`fa fa-flash ${fundsFlow === "same_day" ? "active" : ""}`}></i>
                                                    </div>
                                                    <div className='form-check'>
                                                        {/* <input checked={fundsFlow === "same_day"} style={{ height: "15px", width: "15px" }} type='radio' id="sameDay" name="withdraw" className='form-check-input' onClick={() => setFundsFlow("same_day")} /> */}
                                                        <label htmlFor='sameDay' className='form-check-label'>Same Day
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={<Tooltip>Funds will be available same day, most probably in few 4-5 hours in your bank account.</Tooltip>}
                                                            >
                                                                <i className='fa fa-info-circle px-2'></i>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>
                                                    <p className='text-center'>1.5% fee (Max $25)</p>
                                                </div>

                                            </Col>
                                            <Col sm={12} xs={12} md={6} lg={6} className="mt-3">
                                                <div className='withdraw-options text-center' onClick={() => setFundsFlow("standard")}>
                                                    <div className='withdraw-icons'>
                                                        <i className={`fa fa-university ${fundsFlow === "standard" ? "active" : ""}`}></i>
                                                    </div>
                                                    <div className='form-check'>
                                                        {/* <input checked={fundsFlow === "standard"} style={{ height: "15px", width: "15px" }} type='radio' id="standard" name="withdraw" className='form-check-input' onClick={() => setFundsFlow("standard")} /> */}
                                                        <label htmlFor='standard' className='form-check-label'>
                                                            2-3 biz days
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={<Tooltip>Funds will take 2-3 business days to reflect in your bank account.</Tooltip>}
                                                            >
                                                                <i className='fa fa-info-circle px-2'></i>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>
                                                    <p className='text-center'>No fee</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                {
                                                    bal ?
                                                        <p className='text-white mt-3'>Transfer upto ${bal}</p>
                                                        : ""
                                                }
                                            </Col>
                                        </Row>

                                        <div className="bank_list mt-4">
                                            <h6>Your Saved Bank</h6>
                                            <div className="row mt-3">
                                                <aside className="col-md-12">
                                                    {AccountList.length == 0 ? <p>No bank account linked, Please add new bank account.</p> : ""}
                                                    {
                                                        AccountList.length > 0 && AccountList.map((account) =>

                                                            <div className="bank_box active mb-2">
                                                                <div className="content">
                                                                    <span className="user_img">
                                                                        <img src={Media + account.fields.img} alt="" />
                                                                    </span>
                                                                    <div className="bank_innerrow" >
                                                                        <span className="check-box-bank">
                                                                            <p className="main-para text-light mb-0"  >{account.fields.institute_name + " **** " + account.fields.mask}</p>
                                                                            {/* <p className="main-para pt-1">Alex Smith</p> */}
                                                                        </span>
                                                                        <span>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value={account.pk} onClick={(e) => setAccountId(e.target.value)} />
                                                                            </div>
                                                                        </span>

                                                                        <span><i className="fa fa-trash card-delete-box" aria-hidden="true" value={account.pk} onClick={(e) => { handleConfirm(e, account.pk) }}></i></span>

                                                                    </div>
                                                                </div>
                                                            </div>)
                                                    }

                                                </aside>
                                                {AccountList.length > 0 ?
                                                    <aside className="col-md-12 aabtn1">
                                                        <button className="btn mt-4" onClick={handleShow}>Submit</button>
                                                    </aside>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </aside>
                                    <aside className="centerside1">
                                        <span>OR</span>
                                    </aside>

                                    <aside className="rightside1 text-center align-self-center">
                                        <h3> Add Bank Account</h3>
                                        <Link2 />
                                    </aside>

                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>

            <Modal className="sucessmodal" show={show} onHide={handleClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={require('../images/check.png').default} alt="" />
                                <h5 className="text-white mt-3">${Amount}</h5>
                                <p>{Res}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>





            <Modal show={confirm} onHide={handleConfirmClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <LoadingSpinerComponent promiseInProgress={account} />
                <Modal.Body className="p-0">
                    <button type="button" className="close" onClick={handleConfirmClose}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 m-auto p-1">
                                <div className="signup_box my-2 text-center">
                                    <h5 className="text-white mt-3 mb-4">Are you sure, you want to delete the bank?</h5>
                                    <div className="yesnobtn">
                                        <a className="btn yesbtn text-white" onClick={event => { removeAccount(event) }}>Yes</a>
                                        <a className="btn nobtn text-white" onClick={handleConfirmClose}>No</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            {/* <Modal show = {MoneyModal} onHide={HandleMoney}
                 size="sm"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
                 >
                      <LoadingSpinerComponent promiseInProgress = {moneyLoader} />
                <Modal.Body className="p-0">
                <button type="button" className="close" onClick={HandleMoney}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                <div className="container">
                  <div className="row">
                      <div className="col-lg-12 col-md-12 m-auto p-1">
                          <div className="signup_box my-2 text-center">
                              <h5 className="text-white mt-3 mb-4">Are you sure, you want to Withdraw the money?</h5>
                              <div className="yesnobtn">
                                  <a className="btn yesbtn text-white"  onClick= {}>Yes</a>
                                  <a className="btn nobtn text-white" onClick= {HandleMoney}>No</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </Modal.Body>
      </Modal> */}

            <Modal className="sucessmodal" show={noBalance} onHide={handleNoBalance}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={require('../images/sorry.png').default} alt="" />
                                <h5 className="text-white mt-3">Sorry!</h5>
                                <p>You don’t have sufficient balance in your wallet.</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={pinShow} onHide={modalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="text-center w-100 pt-2">
                        <Modal.Title className="text-white">Enter your 4 digits PIN Number</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="otp_row pin_row">
                                    <OtpInput
                                        isInputNum={true}
                                        isInputSecure={showvalue == true ? false : true}
                                        inputMode="numeric"
                                        value={pin}

                                        inputStyle={"input101"}
                                        onChange={handleClick}
                                        numInputs={4}
                                        separator={<span></span>}


                                    />

                                </div>
                                <p className="mt-2 mb-0 text-right forgot_pin">
                                    <a className="btn" onClick={(e) => { showvalue == false ? setShowValue(true) : setShowValue(false) }}>
                                        {showvalue == false ? <i className="fa fa-eye" aria-hidden="true"> Show PIN</i> : ''}{showvalue == true ? <span><i className="fa fa-eye-slash" aria-hidden="true"></i> Hide PIN</span> : ''}
                                    </a>
                                    <a className="btn" onClick={Forget_pin} >
                                        Forgot PIN?
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn signupbtn my-3" onClick={event => { ConfirmMoney(event) }}>
                        Confirm
                    </button>
                </Modal.Footer>

            </Modal>

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={smShow}
                onHide={() => setSmShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-white"></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="text-white modal-title h4">Create your 4 - digits PIN</div>
                    <a className="btn createpin_btn text-white my-3" href="/createpin">
                        Create PIN
                    </a>
                </Modal.Body>
            </Modal>
            <Footer2 />
        </>
    )
}
export default Withdraw;