import axios from "axios";
import { config } from "../config";

function Reset_Pin(data){
    return axios.post(`${config.api_URL}/accounts/reset_pin`,data,{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}
export default Reset_Pin;