import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Details from '../APIs/TransactionDetailApi';
import { config } from '../config';
import Footer2 from './Common/Footer2';

import Footer from './Common/Footer2';
import Header2 from './Common/Header2';


const History = () => {
    const [Res, setRes] = useState('')
    const Media = config.api_URL
    let history = useHistory()
    useEffect(() => {
        let query = new URLSearchParams(window.location.search)
        //   alert(query.get('txn_key'))

        Details(query.get('txn_key')).then(Response => {
            toast.dismiss()
            console.log(Response.data.data)
            if (Response.status == 200) {
                setRes(Response.data.data)
            }
        }).catch(function (err) {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
            toast.error(err.response.data.message)
        })


    }, [])
    return (
        <>
            <Header2 />
            <section className="history-section py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 m-auto">
                            <div className="history-box transaction-card py-4">
                                <div className="text-center hstry_head">
                                    <div className="user_image mr-3 mx-auto text-center">
                                        <img src={Media + Res.to_img} alt="" />
                                    </div>
                                    {
                                        Res.is_business && Res.txn_type == 'debit'
                                            ?
                                            <span className="verify_img3"><img src={require('../images/verify.png').default} alt="" /></span>
                                            : ""
                                    }
                                    <h5 className="mb-0 text-white mt-3">{Res.to}</h5>
                                    <h6>{Res.to_self == true ? '' : Res.to_phone}</h6>
                                    <p className="mt-3">{Res.created_at}</p>
                                </div>
                                <div className="history-details">
                                    <span className="payment-details">Payment Details</span>
                                </div>
                                <div className="history-details">
                                    <span>To</span>
                                    <h4>{Res.to} {Res.is_business && Res.txn_type === 'debit' ? <img width="20px" src={require('../images/verify.png').default} alt="" /> : ""}</h4>
                                </div>
                                <div className="history-details">
                                    <span>From</span>
                                    <h4>{Res.from_account} {Res.is_business && Res.txn_type === 'credit' ? <img width="20px" src={require('../images/verify.png').default} alt="" /> : ""}</h4>
                                </div>
                                <div className="history-details">
                                    <span>Transaction ID</span>
                                    <h4>{Res.txn_id}</h4>
                                </div>
                                <div className="history-details">
                                    <span>Amount</span>
                                    <h4>${Res.amount}</h4>
                                </div>
                                <div className="history-details">
                                    <span>Status</span>
                                    <h4>{Res.status}</h4>
                                </div>
                                {
                                    Res.memo ?
                                        <div className="history-details">
                                            <span>Transaction Memo</span>
                                            <h4>{Res.memo}</h4>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer2 />
        </>
    )
}
export default History;