import axios from "axios";
import { config } from "../config";


function TransactionsL(sort,year,page, search){
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone
   return axios.get(`${config.api_URL}/accounts/list_transactions?search=${search}&sorting=`+sort+"&year="+year+"&page="+page+"&timezone="+timezone,{
       headers:{
        'Authorization':localStorage.getItem('access_token')
       }
   })
}

export default TransactionsL;