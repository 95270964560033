import axios from "axios";
import { config } from "../config";

export function getSolutionsfirstSec() {
    return axios.get(`${config.api_URL}/accounts/get_solution_page`,
        {
            headers: {
                'Authorization': localStorage.getItem('access_token')
            }
        })
}
export function getSolutionsSecondSec() {
    return axios.get(`${config.api_URL}/accounts/get_our_services`,
        {
            headers: {
                'Authorization': localStorage.getItem('access_token')
            }
        })
}
export function getSolutionsThirdSec() {
    return axios.get(`${config.api_URL}/accounts/get_contact_sales`,
        {
            headers: {
                'Authorization': localStorage.getItem('access_token')
            }
        })
}
export function getSolutionsForthSec() {
    return axios.get(`${config.api_URL}/accounts/get_payrink_faq`,
        {
            headers: {
                'Authorization': localStorage.getItem('access_token')
            }
        })
}