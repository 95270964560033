//import axios from "axios";
//import { config } from "../config";

//function contactUs(data){
//    return axios.post(`${config.api_URL}/accounts/message_admin`,data)
//}

//export default contactUs;

import axios from "axios";
import { config } from "../config";

function contactUs(data) {
    return axios.post(`${config.api_URL}/accounts/contact_us`, data, {
        headers: {
            "Authorization": localStorage.getItem("access_token")
        }
    })
}

export default contactUs;