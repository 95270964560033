import axios from "axios";
import moment from 'moment';
import { config } from "../config";

export const SignUp = {
  normalUserSignup
}

function normalUserSignup(data) {

  const {
    FirstName,
    LastName,
    SocialSecurityNumber,
    date,
    State,
    city,
    Address,
    Email,
    phone,
    picture,
    doc,
    PostalCode,
    DocType,
  } = data;

  const formdata = new FormData()
  // formdata.append("document", doc)
  // formdata.append("docType", DocType)
  if (picture) {
    formdata.append("picture", picture)
  }
  formdata.append("first_name", FirstName)
  formdata.append("last_name", LastName)
  formdata.append("ssn", SocialSecurityNumber)
  formdata.append("dateOfBirth", date)
  formdata.append("email", localStorage.getItem('email'))
  formdata.append("phone", phone)
  formdata.append("state", State)
  formdata.append("city", city)
  formdata.append("address", Address)
  formdata.append("postalCode", PostalCode)

  const AUTH_TOKEN = localStorage.getItem('access_token');

  return axios.post(`${config.api_URL}/accounts/individual_signup`, formdata, {
    headers: {
      'Authorization': AUTH_TOKEN
    }
  });


}

export const businessSign = {
  businessUserSignup
}


function businessUserSignup(signupdata) {
  const AUTH_TOKEN = localStorage.getItem('access_token');

  const formdata = new FormData()
  formdata.append('businessName', signupdata.businessName)
  formdata.append('businessClassification', signupdata.businessClassification)
  formdata.append('businessType', signupdata.businessType)
  formdata.append('industryClassification', signupdata.industryClassification)
  formdata.append('dateOfBirth', signupdata.dateOfBirth)
  formdata.append('phone', signupdata.phone)
  formdata.append('owner_address', signupdata.owner_address)
  formdata.append('owner_city', signupdata.owner_city)
  formdata.append('owner_state', signupdata.owner_state)
  formdata.append('owner_postalCode', signupdata.owner_postalCode)
  formdata.append('email', signupdata.email)
  formdata.append('picture', signupdata.picture)
  formdata.append('first_name', signupdata.first_name)
  formdata.append('last_name', signupdata.last_name)
  formdata.append('address', signupdata.address)
  formdata.append('store', signupdata.store)
  formdata.append('city', signupdata.city)
  formdata.append('state', signupdata.state)
  formdata.append('postalCode', signupdata.postalcode)
  formdata.append('ein', signupdata.ein)
  formdata.append('ssn', signupdata.ssn)
  formdata.append('address', signupdata.address)
  if (signupdata.businessType != 'soleProprietorship') {
    formdata.append('owner_docType', signupdata.owner_docType)
    formdata.append('owner_document', signupdata.owner_document)
    formdata.append('licence', signupdata.licence)
  }

  // alert(JSON.stringify(formdata))

  return axios.post(`${config.api_URL}/accounts/business_signup`, formdata, {
    headers: {
      'Authorization': AUTH_TOKEN
    }

  })

}


export const benficial = {
  beneficialOwner
}

function beneficialOwner(data) {
  const {
    DocImg,
    FirstName,
    LastName,
    DocType,
    SSN,
    DOB,
    Country,
    Address,
    State,
    City,
    PostalCode
  } = data

  const AUTH_TOKEN = localStorage.getItem('access_token');


  const formdata = new FormData()
  formdata.append('first_name', FirstName)
  formdata.append('last_name', LastName)
  formdata.append('address', Address)
  formdata.append('city', City)
  formdata.append('state', State)
  // formdata.append('docType', DocType)
  // formdata.append('document', DocImg)
  formdata.append('dateOfBirth', moment(DOB).format("YYYY-MM-DD")
  )
  formdata.append('passport', SSN)
  formdata.append('country', Country)
  formdata.append('postalCode', PostalCode)

  console.log(formdata)
  return axios.post(`${config.api_URL}/accounts/create_beneficial_owner`, formdata, {
    headers: {
      'Authorization': AUTH_TOKEN
    }
  })

}