/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable no-cond-assign */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import Footer from './Common/Footer2';
import Header2 from './Common/Header2';
import { Nav, Form, Tab, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import GetProfile from '../APIs/GetProfileApi';
import { toast } from 'react-toastify';
import OldNumberOtp from '../APIs/OtpOldNumber';
import NewNumberOtp from '../APIs/OtpNewNumber';
import ChangeNumberOtp from '../APIs/VerificationOtpNumberChange';
import OtpInput from 'react-otp-input';
import { saveAs } from "file-saver";
import QrcodeGen from '../APIs/QrCodeApi';
import ChangePassword from '../APIs/ChangePasswordApi';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import Change_Pin from '../APIs/ChangePinApi';
import { useHistory } from 'react-router';
import { stateName } from '../APIs/Businessclassfyservice';
import Footer2 from './Common/Footer2';
import axios from 'axios';
import { config } from '../config';


const Profile = (props) => {

    const [OTP, setOTP] = useState("");
    const [showOldOtp, setShowOtdOtp] = useState(true)
    const [showNewOtp, setShowNewOtp] = useState(false)
    const [OTP1, setOTP1] = useState("");
    const [verify, setVerify] = useState(0)
    const [ShowNew, setShowNew] = useState(0)
    const [showvalue, setShowValue] = useState(false)
    const [showNewvalue, setShowNewValue] = useState(false)
    const [userState, setStateName] = useState("")
    const [disputeMsg, setDispute] = useState("")
    const [temp, setTemp] = useState(0)

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            window.location = '/login'
            return false;
        }
        UProfile()
        QrGen()

    }, [temp])


    const [loader, setLoader] = useState(false)
    const [phone, setPhone] = useState('');
    const [userDetails, setUserDetails] = useState('')
    const [show, setShow] = useState(false)
    const [otp, setOtp] = useState('')
    const [res, setRes] = useState('')
    const [Newphone, setNewPhone] = useState('')
    const [qrlink, setQrLink] = useState('')
    const Media = config.api_URL + userDetails.picture
    const MediaQr = config.api_URL + qrlink
    const [OldPassword, setOldPassword] = useState('')
    const [NewPassword, setNewPassword] = useState('')
    const [ConfirmPassword, setConfirmPassword] = useState('')
    const [old, setOld] = useState(0)
    const [OTP2, setOTP2] = useState('')
    function onlyNumber(e) {
        console.log(e);
        if (/^[0-9\b]+$/.test(e)) {
            setPhone(e);
            return true;
        } else if (e === '')
            setPhone('');

    }

    function onlyNewNumber(e) {
        console.log(e);
        if (/^[0-9\b]+$/.test(e)) {
            setNewPhone(e);
            return true;
        } else if (e === '')
            setNewPhone('');

    }
    let history = useHistory()
    function QrGen() {
        toast.dismiss()
        QrcodeGen().then(Response => {
            if (Response.status == 200) {
                setQrLink(Response.data.image)
            }
        }).catch(function (err) {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
            toast.error(err.response.data.message)
        })

    }

    function UProfile() {
        GetProfile().then(Response => {
            setUserDetails(Response.data)
            var user_state = Response.data.state
            stateName().then(res => {
                let STATES = res.data.data
                for (let i = 0; i < STATES.length; i++) {
                    const element = STATES[i];
                    if (element.code === user_state) {
                        setStateName(element.name)
                    }
                }
            })
        }).catch(function (err) {
            toast.error(err.response.data.message)
        })
    }
    function handleOldPin(event) {

        setOTP(event)

    }

    function handleNewPin(event) {

        setOTP1(event)

    }
    function handleOldPin2(event) {

        setOTP2(event)

    }


    function handleClick(event) {
        setOtp(event)
    }
    function modalClose() {
        setShow(false)
        setOtp('')
    }

    const saveFile = () => {
        saveAs(
            MediaQr,
            "Your QRcode"
        );
    };

    function ConfirmOtp(event) {
        toast.dismiss()
        event.preventDefault()
        if (phone.trim() === "") {
            toast.error("Phone Number field can't be empty.")
            return false;
        }
        else if (phone.trim().length < 10) {
            toast.error('Phone number should be 10 digits.')
            return false;
        }
        else {
            if (old != 1) {
                const data = {
                    "phone": phone
                }
                // alert(JSON.stringify(data))
                OldNumberOtp(data).then(Response => {
                    if (Response.status == 200) {
                        // setOld(1)
                        setShow(true)
                        toast.success(Response.data.message)

                    }
                }).catch(function (err) {
                    setLoader(false)
                    toast.error(err.response.data.message)
                })
            } else {
                if (Newphone.trim() === "") {
                    toast.error("New phone Number field can't be empty.")
                    return false;
                }
                else if (Newphone.trim().length < 10) {
                    toast.error('New phone number should be 10 digits.')
                    return false;
                }
                const data = {
                    "phone": Newphone
                }
                NewNumberOtp(data).then(Response => {
                    if (Response.status == 200) {
                        setShow(true)
                        // setOtp('')
                        toast.success(Response.data.message)

                    }
                }).catch(function (err) {
                    toast.error(err.response.data.message)
                })
            }
        }

    }

    function ConfirmOtp1(event) {
        // alert("Helooooooooooooo")
        toast.dismiss()
        event.preventDefault()
        if (phone.trim() === "") {
            toast.error("Phone Number field can't be empty.")
            return false;
        }
        else if (phone.trim().length < 10) {
            toast.error('Phone number should be 10 digits.')
            return false;
        }
        else {
            // alert("Hlrlooo")
            if (Newphone == '') {
                const data = {
                    "phone": phone
                }
                OldNumberOtp(data).then(Response => {
                    if (Response.status == 200) {
                        setShow(true)
                        toast.success(Response.data.message)
                    }
                }).catch(function (err) {
                    setLoader(false)
                    toast.error(err.response.data.message)
                })
            } else if (Newphone) {
                if (Newphone.trim() === "") {
                    toast.error("New phone Number field can't be empty.")
                    return false;
                }
                else if (Newphone.trim().length < 10) {
                    toast.error('New phone number should be 10 digits.')
                    return false;
                }
                const data = {
                    "phone": Newphone
                }
                // alert(JSON.stringify(data))
                NewNumberOtp(data).then(Response => {
                    if (Response.status == 200) {
                        setShow(true)
                        // setOtp('')
                        toast.success(Response.data.message)

                    }
                }).catch(function (err) {
                    toast.error(err.response.data.message)
                })
            }
        }
    }


    function VerifyOtp() {
        setLoader(true)
        toast.dismiss()
        if (Newphone == '') {
            const data = {
                phone,
                otp
            }
            ChangeNumberOtp(data).then(Response => {
                if (Response.status == 200) {
                    setLoader(false)
                    setRes(Response.data)
                    setShow(false)
                    setOtp('')
                    setOld(1)
                    toast.success(Response.data.message)
                }
            }).catch(function (err) {
                setLoader(false)
                toast.error(err.response.data.message)
            })
        }
        else {
            const data = {
                "phone": Newphone,
                otp
            }
            ChangeNumberOtp(data).then(Response => {
                if (Response.status == 200) {
                    setRes(Response.data)
                    setShow(false)
                    setOtp('')
                    toast.success(Response.data.message)
                    setTemp(temp+1)
                }
            }).catch(function (err) {
                setLoader(false)
                toast.error(err.response.data.message)
            })
        }

    }

    function Check_pin() {
        toast.dismiss()
        if (OTP == '') {
            toast.error('Please enter old PIN.')
        }
        else {
            setLoader(true)
            const data = {
                'old_pin': OTP
            }
            Change_Pin(data).then(Response => {

                if (Response.status = 200) {
                    setVerify(1)
                    setShowOtdOtp(false)
                    setShowNewOtp(true)
                    setShowValue(false)
                    setLoader(false)
                    toast.success(Response.data.message)
                }
            }).catch(function (err) {
                setLoader(false)
                toast.error(err.response.data.message)
            })
        }
    }
    function Set_pin() {
        toast.dismiss()
        if (OTP1 == '') {
            toast.error('Please enter new PIN.')
        } else if (OTP1 == OTP) {
            toast.error("New PIN should be different from current PIN.")
        } else if (OTP2 == '') {
            toast.error('Please enter confirm PIN.')
        } else if (OTP1 !== OTP2) {
            toast.error("New PIN and Confirm PIN should be same.")
        }
        else {
            setShowNewValue(false)
            setShowNewOtp(true)
            setLoader(true)
            const data = {
                'old_pin': OTP,
                'new_pin': OTP1
            }
            Change_Pin(data).then(Response => {

                if (Response.status = 200) {

                    toast.success(Response.data.message)
                    window.setTimeout(() => {
                        setLoader(false)
                       history.push('/profile')
                    }, 3000)
                }
            }).catch(function (err) {
                setLoader(false)
                toast.error(err.response.data.message)
            })
        }
    }

    function ChangingPassword() {
        toast.dismiss()
        if (OldPassword.trim() == '') {
            toast.error("Current password field can't be empty.")
            return false;
        }
        setLoader(true)
        const data = {
            "old": OldPassword,
            // "new":NewPassword
        }
        ChangePassword(data).then(Response => {
            if (Response.status == 200) {
                toast.success(Response.data.message)

                setLoader(false)
                setShowNew(1)


            }
        }).catch(function (err) {
            setLoader(false)
            toast.error(err.response.data.message)
        })


    }
    function ChangingNewPassword() {
        toast.dismiss()

        if (NewPassword.trim() == '') {
            toast.error("New password field can't be empty.")
            return false;
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}/.test(NewPassword)) {
            toast.error("Password must be eight characters long and contain at least one alphabet, at least one number and one special character.")
        }
        else if (OldPassword == NewPassword) {
            toast.error('New password should be different from current password.')
        }
        else if (ConfirmPassword.trim() == '') {
            toast.error("Confirm password field can't be empty.")
        }

        else {


            if (NewPassword == ConfirmPassword) {
                setLoader(true)
                const data = {
                    "old": OldPassword,
                    "new": NewPassword
                }
                ChangePassword(data).then(Response => {
                    if (Response.status == 200) {
                        toast.success(Response.data.message)
                        window.setTimeout(() => {
                            setLoader(false)
                            window.location.href = '/profile'
                        }, 1500)


                    }
                }).catch(function (err) {
                    setLoader(false)
                    toast.error(err.response.data.message)
                })
            }
            else {
                toast.error('New password and confirm password should be same.')
            }
        }
    }

    function pagePrint() {
        var win = window.open('', '', 'height=500, width=800')
        win.document.write(document.getElementById("QR").innerHTML)
        win.document.close()
        win.print()
    }
    function HandleKey(e) {
        if (e.which == 32) {
            e.preventDefault()
        } else {
            setOldPassword(e.target.value)
        }
    }
    function HandleNewKey(e) {
        if (e.which == 32) {
            e.preventDefault()
        } else {
            setNewPassword(e.target.value)
        }
    }
    function HandleConfirmKey(e) {
        if (e.which == 32) {
            e.preventDefault()
        } else {
            setConfirmPassword(e.target.value)
        }
    }
    function sendDispute() {
        toast.dismiss()
        if (!disputeMsg.trim().length) {
            toast.error("Dispute field can't be empty.")
            return false;
        }
        setLoader(true)
        axios.post(`${config.api_URL}/accounts/dispute`, { "message": disputeMsg }, {
            headers: { "Authorization": localStorage.getItem("access_token") }
        }).then(resp => {
            setDispute("")
            toast.success(resp.data.message)
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            toast.error(err.response.data.message)
        })
    }
    return (
        <>
            <Header2 />
            <section className="profile_page py-5">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile_section pb-5">
                                <p className="main-para">My Profile</p>
                                <div className="row align-items-center mb-4">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="name_area">
                                            <div className="user_image mr-3">
                                                <img src={Media} alt="" />
                                            </div>
                                            <div>
                                                <h4>{userDetails.first_name}</h4>
                                                <h6>{userDetails.email}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="profile_btn">
                                            <a className="setting_btn btn" href="/settings"><i className="fa fa-cog"></i></a>
                                            <a className="editprofile_btn btn" href="/edit_profile">Edit Profile</a>
                                        </div>
                                    </div>
                                </div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <div className="row profile_tabing">
                                        <div className="col-md-3">
                                            <Nav variant="pills" className="flex-column tabbing_leftside">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">
                                                        <h4>
                                                            <span className="name_icon"><i className="fa fa-user"></i> My Information</span>
                                                            <span className="arrow_icon"><i className="fa fa-angle-right"></i></span>
                                                        </h4>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">
                                                        <h4>
                                                            <span className="name_icon"><i className="fa fa-qrcode"></i> My QR Code</span>
                                                            <span className="arrow_icon"><i className="fa fa-angle-right"></i></span>
                                                        </h4>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">
                                                        <h4>
                                                            <span className="name_icon"><i className="fa fa-phone"></i> Change Phone Number</span>
                                                            <span className="arrow_icon"><i className="fa fa-angle-right"></i></span>
                                                        </h4>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">
                                                        <h4>
                                                            <span className="name_icon"><i className="fa fa-unlock"></i> Change Password</span>
                                                            <span className="arrow_icon"><i className="fa fa-angle-right"></i></span>
                                                        </h4>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth">
                                                        <h4>
                                                            <span className="name_icon"><i className="fa fa-unlock"></i> Change PIN</span>
                                                            <span className="arrow_icon"><i className="fa fa-angle-right"></i></span>
                                                        </h4>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="sixth">
                                                        <h4>
                                                            <span className="name_icon"><i className="fa fa-comment"></i> Raise Dispute</span>
                                                            <span className="arrow_icon"><i className="fa fa-angle-right"></i></span>
                                                        </h4>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="col-md-9">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="tabbing_details">
                                                        <h2 className="text-white mb-4">My Information</h2>
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3">
                                                                <h5>First Name</h5>
                                                                <h6>{userDetails.first_name}</h6>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <h5>Last Name</h5>
                                                                <h6>{userDetails.last_name}</h6>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <h5>Phone Number</h5>
                                                                <h6>{userDetails.phone}</h6>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <h5>Email Address</h5>
                                                                <h6>{userDetails.email}</h6>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <h5>Social Security Number (SSN)</h5>
                                                                <h6>{userDetails.ssn}</h6>
                                                            </div>
                                                            <div className="col-md-12 mb-3">
                                                                <h5>Address </h5>
                                                                <h6>{userDetails.address}</h6>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <h5>City </h5>
                                                                <h6>{userDetails.city}</h6>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <h5>State </h5>
                                                                <h6>{userState}</h6>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <h5>Zip Code </h5>
                                                                <h6>{userDetails.postalCode}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <div className="tabbing_details">
                                                        <h2 className="text-white mb-4">My QR Code</h2>
                                                        <div className="row">
                                                            <div className="col-md-12 text-center">
                                                                <div className="qrcode_row py-4">
                                                                    <div id="QR"><img src={MediaQr} alt="" /></div>

                                                                    <div className="mt-4">
                                                                        <button className="btn printbtn" onClick={(e) => pagePrint(e)}>Print</button>
                                                                        <button className="btn dwnldbtn" onClick={saveFile}>Download</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <div className="tabbing_details pb-5">
                                                        <h2 className="text-white mb-3">Change Phone Number</h2>
                                                        <p>Please Enter your registered phone number.</p>
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <Form.Group className="my-3 phone_input2" controlId="formBasicEmail">
                                                                    <Form.Label>Phone Number</Form.Label>
                                                                    <div className="phn_number_row">
                                                                        {res.matched ? <span className="verified"><i className="fa fa-check"></i> verified</span> : ''}
                                                                        <PhoneInput
                                                                            placeholder="E.g. (987-654-3210)"
                                                                            disableCountryCode={true}
                                                                            disableDropdown={true}
                                                                            onlyCountries={['us']}
                                                                            enableAreaCodes={true}
                                                                            country={'us'}
                                                                            value={phone}
                                                                            onChange={(e) => onlyNumber(e)}
                                                                        />
                                                                    </div>
                                                                    {!res.matched ? <button className="btn subtn1 mt-3" onClick={ConfirmOtp}>Submit</button> : ''}
                                                                </Form.Group>
                                                                {res.matched ?
                                                                    <div className="mt-4">
                                                                        <Form.Group className="my-3 phone_input2" controlId="formBasicEmail">
                                                                            <Form.Label>New Phone Number</Form.Label>
                                                                            <PhoneInput
                                                                                placeholder="E.g. (987-654-3210)"
                                                                                disableCountryCode={true}
                                                                                disableDropdown={true}
                                                                                onlyCountries={['us']}
                                                                                enableAreaCodes={true}
                                                                                country={'us'}
                                                                                value={Newphone}
                                                                                onChange={(e) => onlyNewNumber(e)}
                                                                            />
                                                                        </Form.Group>
                                                                        <button className="btn subtn1 mt-3" onClick={ConfirmOtp}>Update</button>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fourth">
                                                    <div className="tabbing_details change_passwordrow pb-5">
                                                        <h2 className="text-white mb-4">Change Password</h2>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <Form.Group controlId="formBasicPassword" className="mb-3">
                                                                    <Form.Label>Current Password</Form.Label>
                                                                    <Form.Control type="password" disabled={ShowNew == 1} placeholder="Enter your password" onKeyDown={(e) => HandleKey(e)} onChange
                                                                        ={(e) => setOldPassword(e.target.value)} />
                                                                </Form.Group>
                                                            </div>
                                                            {ShowNew == 0 &&
                                                                <>
                                                                    <div className="col-md-12">
                                                                        <div className="mt-4">
                                                                            <button className="btn subtn1 mt-3" onClick={ChangingPassword}>Next</button>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" mt-3 Existing col-md-12">
                                                                        <p className="pb-0 mb-0 pt-2">
                                                                            <a href="/forgotpassword" className="text-white"><u>Forgot Password?</u></a>
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            }

                                                            {ShowNew == 1 &&
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <Form.Group controlId="formBasicPassword" className="mb-3">
                                                                            <Form.Label>New Password</Form.Label>
                                                                            <Form.Control type="password" placeholder="Enter New password" onKeyDown={(e) => HandleNewKey(e)} onChange={(e) => setNewPassword(e.target.value)} />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Group controlId="formBasicPassword" className="mb-3">
                                                                            <Form.Label>Confirm New Password</Form.Label>
                                                                            <Form.Control type="password" placeholder="Re-Enter New Password" onKeyDown={(e) => HandleConfirmKey(e)} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                                        </Form.Group>
                                                                    </div>
                                                                </>
                                                            }
                                                            {ShowNew == 1 &&
                                                                <div className="col-md-12">
                                                                    <div className="mt-4">
                                                                        <button className="btn subtn1 mt-3" onClick={ChangingNewPassword}>Update</button>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fifth">
                                                    <div className="tabbing_details">
                                                        <h2 className="text-white mb-4">Change PIN</h2>
                                                        <div className="row">
                                                            <div className="col-md-4 text-left">
                                                                <div className="pb-3 mt-3">
                                                                    <div className="signup_box my-2">
                                                                        <Form>
                                                                            <Form.Group className="mb-3 text-left" controlId="formBasicPhone">
                                                                                <Form.Label>Enter Old PIN</Form.Label>
                                                                                <div className="otp_row pin_row mt-1">
                                                                                    <OtpInput
                                                                                        isDisabled={verify == 1}
                                                                                        isInputNum={true}
                                                                                        isInputSecure={showvalue == true ? false : true}
                                                                                        inputMode="numeric"
                                                                                        value={OTP}
                                                                                        // onChange={setOTP}
                                                                                        inputStyle={"input101"}
                                                                                        onChange={handleOldPin}
                                                                                        numInputs={4}
                                                                                        separator={<span></span>}
                                                                                    />
                                                                                    {showOldOtp ?
                                                                                        <p className="forgot_pin d-flex justify-content-center mr-5 mt-3">
                                                                                            <a className="btn" onClick={(e) => { showvalue == false ? setShowValue(true) : setShowValue(false) }}>
                                                                                                {showvalue == false ? <i className="fa fa-eye" aria-hidden="true"> Show PIN</i> : ''}{showvalue == true ? <span><i className="fa fa-eye-slash" aria-hidden="true"> Hide PIN</i></span> : ''}
                                                                                            </a>
                                                                                        </p>
                                                                                        : ""
                                                                                    }
                                                                                </div>
                                                                            </Form.Group>
                                                                            {verify == 0 ?
                                                                                <div className="text-left">
                                                                                    <a className="btn signupbtn mt-4 mb-2" onClick={Check_pin}  >
                                                                                        NEXT
                                                                                    </a>
                                                                                </div>
                                                                                : ''}
                                                                            {verify == 1 ?
                                                                                <>
                                                                                    <Form.Group controlId="formBasicPassword" className="mt-4 text-left">

                                                                                        <Form.Label>Enter New PIN</Form.Label>
                                                                                        <div className="otp_row pin_row mt-1">
                                                                                            <OtpInput
                                                                                                isInputNum={true}
                                                                                                isInputSecure={showNewvalue == true ? false : true}
                                                                                                inputMode="numeric"
                                                                                                value={OTP1}
                                                                                                onChange={handleNewPin}
                                                                                                inputStyle={"input101"}
                                                                                                // onChange={handleClick}
                                                                                                numInputs={4}
                                                                                                separator={<span></span>}
                                                                                            />

                                                                                        </div>
                                                                                    </Form.Group>
                                                                                    <Form.Group className="mb-3 text-left" controlId="formBasicPhone">
                                                                                        <Form.Label>Enter Confirm PIN</Form.Label>
                                                                                        <div className="otp_row pin_row mt-1">
                                                                                            <OtpInput
                                                                                                // isDisabled={verify==1}
                                                                                                isInputNum={true}
                                                                                                isInputSecure={showNewvalue == true ? false : true}
                                                                                                inputMode="numeric"
                                                                                                value={OTP2}
                                                                                                // onChange={setOTP}
                                                                                                inputStyle={"input101"}
                                                                                                onChange={handleOldPin2}
                                                                                                numInputs={4}
                                                                                                separator={<span></span>}
                                                                                            />
                                                                                            {showNewOtp ?
                                                                                                <p className="forgot_pin d-flex justify-content-center mr-5 mt-3">
                                                                                                    <a className="btn" onClick={(e) => { showNewvalue == false ? setShowNewValue(true) : setShowNewValue(false) }}>
                                                                                                        {showNewvalue == false ? <i className="fa fa-eye" aria-hidden="true"> Show PIN</i> : ''}{showNewvalue == true ? <span><i className="fa fa-eye-slash" aria-hidden="true"> Hide PIN</i></span> : ''}
                                                                                                    </a>
                                                                                                </p>
                                                                                                : ""
                                                                                            }
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                    <div className="text-left">
                                                                                        <a className="btn signupbtn mt-4 mb-2" onClick={Set_pin}>
                                                                                            SUBMIT
                                                                                        </a>
                                                                                    </div>
                                                                                </>
                                                                                : ''}

                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="sixth">
                                                    <div className="tabbing_details">
                                                        <h2 className="text-white mb-4">Raise Dispute</h2>
                                                        <div className="row">
                                                            <div className="col-md-8 text-left">
                                                                <div className="pb-3 mt-3">
                                                                    <div className="signup_box my-2">
                                                                        <Form>
                                                                            <Form.Group>
                                                                                <Form.Control style={{ height: "150px" }} onChange={(e) => { setDispute(e.target.value) }} value={disputeMsg} as="textarea" placeholder="Enter your dispute message here" />
                                                                            </Form.Group>
                                                                            <div className="text-left">
                                                                                <a className="btn signupbtn mt-2" onClick={sendDispute}>
                                                                                    SUBMIT
                                                                                </a>
                                                                            </div>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show} onHide={modalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="text-center w-100">
                        <Modal.Title className="text-white">Verification Code</Modal.Title>
                        <p className="pt-2 pb-0" >Please type the verification code sent to</p>
                        <h6>+1{Newphone == '' ? phone : Newphone}</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="otp_row">
                                    <OtpInput
                                        isInputNum={true}
                                        inputMode="numeric"
                                        value={otp}
                                        inputStyle={"input101"}
                                        onChange={handleClick}
                                        numInputs={6}
                                        separator={<span></span>}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn signupbtn mt-3 mb-2" onClick={VerifyOtp}  >
                        Confirm
                    </button>
                </Modal.Footer>
                <div className="text-center mb-3 resend">
                    <p className="pb-0 mb-0 pt-2">I didn’t get the code  <a href="" onClick={(event) => ConfirmOtp1(event)} ><u>Resend</u></a></p>
                </div>
            </Modal>

            <Footer2 />
        </>
    )
}
export default Profile;