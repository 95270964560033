import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Navbar, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FooterNew from "./Common/FooterNew";
import { useState } from "react";
import { getSiteSettings } from "../APIs/getSiteSettings";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
    const [siteSettings, setSiteSettings] = useState(null);
    
    getSiteSettings().then((res) => {
        setSiteSettings(res?.data?.data);
    }).catch((err) => console.log(err));
    
    return (
        <>
            <header className="contactheader">
                <div className="container-fluid">
                    <Navbar expand="lg contact-nav" className="px-0">
                        <Navbar.Brand href="/">
                            <img
                                src={require("../images/logoWhite.png").default}
                                alt="img"
                                className="web_logo"
                            />
                            <img
                                src={require("../images/blackLogo.png").default}
                                alt="img"
                                className="mob_logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/solutions">Solutions</Nav.Link>
                            </Nav>
                            <Form inline>
                                <Button className="dark-btn" href="/login">
                                    Get Started
                                </Button>
                            </Form>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </header>
            <section
                className="team-cnt-header text-center text-white"
                style={{ background: "#f26369", padding: "90px 0" }}
            >
                <div className="container-fluid">
                    <h2
                        className="cnt-title"
                        style={{ fontSize: "50px", fontWeight: "bold" }}
                    >
                        Privacy Policy
                    </h2>
                </div>
            </section>
            <section className="privacy-policy" style={{ background: "#f2f3f4" }}>
                <Container className="privacy-content">
                    <div className="WordSection1">
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ fontSize: '24.0pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Privacy Notice</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Effective
                            Date: October 26, 2021</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If you signed up for PayRink before the
                            effective date, and you have not accepted this new privacy notice, please see
                            the terms that are applicable to you.</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We’ve included annotations in these gray
                            boxes as a guide to our notices. Annotations aren’t part of the notices and
                            have no legal effect, but they should help you follow the text.<br />
                            <br />
                            If you are a PayRink customer, this privacy notice applies to you. It explains
                            what data we collect about you, what we do with it, when and why we share it
                            with others, how long we keep it, how we secure it, what cookies are and what
                            they (and similar technologies) do, what data is collected about you by third
                            party service providers, and what choices you have to control your data.<br />
                            <br />
                            By using PayRink you consent to these data practices.</span></i></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>This Privacy Notice describes how PayRink,
                            Inc. and our affiliates (collectively, “PayRink,” “we,” and “us”) collect, use,
                            disclose, transfer, store, retain or otherwise process your information when
                            you (“you,” “your”) download PayRink through a relevant app store and sign up
                            for a PayRink account and other services through the app or online at&nbsp;</span><span style={{ color: 'black' }}><Link to="/">https://www.payrink.com</Link></span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>This Privacy Notice applies to your use of our Services,
                            and covers only information collected in connection with your access to and use
                            of our Services. Please read this Privacy Notice carefully. By continuing to
                            interact with our Services, you are consenting to the practices described in
                            this Privacy Notice.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Our Privacy Notice explains:</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information we collect about you</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sources of Information We Collect About You</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; How we use your information</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When and with whom we share your information</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cookies and Other Similar Automated
                            Technologies</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Third-Party Analytics Services</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; How Long We Keep Your information</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your Choices</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rights of California Residents</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Security</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Storage and Processing</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Changes to this Privacy Notice</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; How to Contact Us</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>INFORMATION WE COLLECT ABOUT YOU</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We use your data to make PayRink’s products
                            and services work better for you and for others. This describes what data we
                            get about you (which can vary depending on where you live).</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We need to collect information about you to provide you
                            with the Services or the support you request. Specifically, we collect (and/or
                            have collected during at least the 12-month period preceding the effective date
                            of this Privacy Notice) the categories of personal information described below.
                            Additionally, you can choose to voluntarily provide information to us.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Information You Provide to Access our Services</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We use data you put into our systems when
                            you use PayRink, like financial information, information that identifies who
                            you are, transaction information, and any other data you give us.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We collect information you provide when you apply or sign
                            up for a PayRink account, go through our identity or account verification
                            process, authenticate into your account, communicate with us, answer our
                            surveys, participate in contests or promotions offered by us or our partners,
                            or otherwise use our Services.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We collect the following categories of information about
                            you when you use our Services, including:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Identification
                            Information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;such as: Your name, email address,
                                zip code, phone number, signature, and authentication credentials (for example,
                                information you use to log in to your PayRink account), including Internet
                                Protocol (“IP”) address.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Additional
                            Identification Information for Verified Users.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;such as: Your full mailing address; date of birth;
                                government-issued identification, including Social Security number, passport,
                                or driver’s license; and photograph if you choose to share such information in
                                order to send and receive larger payment amounts.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Financial
                            Information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;such as: Bank account numbers you add
                                to your PayRink account.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Contacts
                            Information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;In order to provide you with the
                                Service, we will also need to collect information from you about the intended
                                recipient of the payment you request us to make. We will therefore ask you to
                                provide contact details of your intended recipient. You can do this by manually
                                entering a phone number or email address into the PayRink. You can also choose
                                to let us access your phone contacts information, as described below (under
                                information to enhance your experience. </span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Transaction
                            Information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;When you use our Services to make,
                                accept, or request payments, we collect information such as: when and where the
                                transactions occur, the names of the transacting parties, a description of the
                                transactions, the payment or transfer amounts, and the devices and payment
                                methods used to complete the transactions.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><a name="enhance" /><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Information You Provide to Enhance Your
                            Experience</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We also use data you give us to make your
                            experience with PayRink better, like contacts you choose to upload to make it
                            easier for you to exchange money with them.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You can choose to provide us with additional information
                            to improve your user experience when using our Services:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Contacts
                            Information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;You can choose to let us access and
                                upload your phone contacts information to make it easier for you to find the
                                people you want to send money to, to help you invite your friends to PayRink,
                                for account and identity verification and fraud prevention purposes, to reduce
                                the risk you will send payments to the wrong person, or to provide other
                                personalized services. You can update your settings to stop sharing your phone
                                contacts with us at any time, although we will always need a recipient’s phone number
                                to send money to them as requested by you.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Other
                            Information You Provide.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;We collect information that you
                                voluntarily provide to us, including your photograph, if you choose to upload a
                                picture to the Services; survey responses; participation in contests, or other
                                prospective marketing forms or devices; suggestions for improvements;
                                referrals; or any other actions you perform on the Services.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Information We Collect From Your Use of our Services</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We also get data from the devices you use
                            when you interact with our systems, like your location and information about
                            the device you’re using. We need this data to do things like help protect your
                            account from hackers, combat fraud, check if payments are being made or
                            received legally and by you, and making our products and services better for
                            you and others.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We collect information about you and the devices you use
                            to access the Services, such as your computer, mobile phone, or tablet. The
                            information that we collect includes:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Geolocation
                            Information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;The location of your device,
                                including your IP address, device language, and location of your network
                                provider. For more information and to learn how to disable collection of
                                location information from your mobile device, please see below.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Device
                            Information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;Information about your device,
                                including your hardware model, operating system and version, device name,
                                unique device identifier, mobile network information, and information about the
                                device’s interaction with our Services. If you use an iOS device, we also
                                automatically detect if you have downloaded Chrome, so we can ask you which
                                browser you prefer to use to open links from your PayRink.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Internet
                            or other electronic network activity information.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;Information about how you use and interact with our Services,
                                including your access time, “log-in” and “log-out” information, browser type
                                and language, country and language setting on your device, IP address, the
                                domain name of your internet service provider, other attributes about your
                                browser, mobile device and operating system, any specific page you visit on our
                                platform, content you view, features you use, the date and time of your use of
                                the Services, your search terms, and the website you visited before you visited
                                or used the Services.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Inferences</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;drawn from any of the information we collect to
                            create a profile about you that may reflect, for example, your preferences,
                            characteristics, and behavior, including for account security purposes or to
                            enhance our Services to you.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><a name="sources" /><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>SOURCES OF INFORMATION WE COLLECT ABOUT YOU</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We also need to check that you are using
                            our services legally, that you are eligible for the services you want to use,
                            and we protect your data and our services from fraudsters who may put you and
                            your money at risk. To do this, we may collect data about you from companies
                            that help us verify your identity, do a credit check, prevent fraud or assess
                            risk.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We collect (and/or have collected during at least the
                            12-month period preceding the effective date of this Privacy Notice)
                            information about you from the following categories of sources:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You directly, when you submit information
                            to us or allow us to access information</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Your devices and how you interact with our
                            Services</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Our group companies or affiliates</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Other sources, including:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Identity
                            Verification.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;Information from third-party
                                verification services (e.g., identity verification agencies and companies which
                                provide third party analytics (see “Third-Party Analytics” below)), and
                                publicly available sources. In some circumstances, where lawful, this
                                information can include your government-issued identification number.</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Credit,
                            Compliance, and Fraud.</span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;Information about you from third
                                parties for any credit investigation, credit eligibility, identity or account
                                verification process, fraud detection process, or collection procedure, or as
                                may otherwise be required by applicable law. This includes, without limitation,
                                the receipt and exchange of account or credit-related information with any
                                credit reporting agency or credit bureau, where lawful, and any person or
                                corporation with whom you have had, currently have, or may have a financial
                                relationship, including without limitation past, present, and future places of
                                employment, financial institutions, and personal reporting agencies.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="how" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>HOW WE USE YOUR INFORMATION</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We use your data to do things like make
                            sure the products you are using work, help you log in to your account, verify
                            who you are, secure your data, fight fraud, follow the law, enforce our agreements,
                            figure out what new products we can build, and market and tailor our products
                            to you.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We may collect, use and share (or have collected, used or
                            shared during at least the 12-month period preceding the effective date of this
                            Privacy Notice), information about you for the following reasons:</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Providing, Improving, and Developing our Services</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Determining whether the Services are
                            available in your country;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Processing or recording payment
                            transactions or money transfers;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Providing you with the PayRink product and
                            features you choose to use;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Displaying your historical transaction
                            information;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Connecting you with people you already
                            know;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Providing, maintaining and improving our
                            Services;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Developing new products and services;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Delivering the information and support you
                            request through the use of our Services;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Improving, personalizing and facilitating
                            your use of our Services, content and applications;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Measuring, tracking and analyzing trends
                            and usage in connection with your use or the performance of our Services,
                            including promotions or discounts offered by us or our partners; and</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Otherwise providing you with the products
                            and features you choose to use.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Communicating with You About our Services</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Sending you surveys and getting your
                            feedback about our Services; and</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Sending you information we think you may
                            find useful or which you have requested from us about our products and
                            services.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Protecting our Services and Maintaining a Trusted Environment</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Conducting investigations, complying with
                            and enforcing any applicable laws, regulations, legal requirements, and
                            industry standards, and responding to lawful requests for information from the
                            government or to valid legal process;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Contacting you to resolve disputes, collect
                            fees and help you with our Services;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Debugging to identify and fix errors that
                            impair how our Services function;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Making sure you follow our&nbsp;Terms of
                            Service or other applicable agreements or policies;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Investigating, detecting, preventing,
                            recovering from, or reporting fraud, misrepresentations, security breaches or
                            incidents, other potentially prohibited, malicious, or illegal activities, or
                            to otherwise help protect your account, including to dispute chargebacks on
                            your behalf;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Protecting your, our, or our other
                            customers’ rights or property, or the security or integrity of our Services;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Verifying or maintaining the quality and
                            safety of our Services; and</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Verifying your identity (e.g., through
                            government-issued identification numbers).</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Advertising and Marketing</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Marketing our Services to you; and</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Communicating with you about opportunities,
                            products, services, contests, promotions, discounts, incentives, surveys, and
                            rewards offered by us and select partners.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If we send you marketing emails, each email will have
                            instructions on how you can “opt out” of getting future marketing from us.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Other Uses</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>For any other reason we may tell you about
                            from time to time.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="when" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>WHEN AND WITH WHOM WE SHARE YOUR INFORMATION</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We share your information with others in
                            the circumstances described in this section. For example, with other people
                            with whom you interact when you use our services; within our family of
                            companies (like PayRink and Plaid), who may offer services that we think could
                            be useful to you; with service providers who help us run our services; or if we
                            need to share your information to comply with the law.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We may share the information described in the
                            “Information We Collect About You” section with the following categories of
                            service providers and third parties:</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>With Other Users of our Services</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>With other users of our Services. For
                            example, we may share information with the person(s) with whom you transact
                            when you make or accept a payment or money transfer using our Services. This
                            helps them know they’re getting money from you (or that you requested a payment
                            from them). We may also make certain information available to other PayRink
                            customers to help them know they’re making a payment to the right person, such
                            as when you joined PayRink and whether you appear in their contacts, and the
                            number of people in their contacts who have also transacted with you. If you
                            choose, we may also allow you to share additional information about you and
                            your use of our Services with other PayRink customers.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>With our Affiliates and Group Companies</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>With our group companies and corporate
                            affiliates, for the reasons outlined above. For example, we may share your
                            information internally to provide you with enhanced services from PayRink and
                            other PayRink company products you use, to understand how you engage with PayRink
                            company products to help make our Services better for you and for everyone, and
                            to help us build Services tailored to your preferences.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>With our Service Providers</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>With service providers who help us provide,
                            maintain, and improve our Services (e.g., vendors who help us with fraud
                            prevention, identity verification, and fee collection services), as well as
                            financial institutions, payment networks, payment card associations, credit
                            bureaus, carrying brokers, and other entities in connection with your payment, money
                            transfer process, investing activity, or otherwise to help us provide the
                            Services;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>With service providers that help us run our
                            advertising campaigns, contests, special offers, or other events or activities.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Business Transfers and Corporate Changes</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>To a subsequent owner, co-owner, or
                            operator of one or more of the Services; or</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If we do or try to do a corporate merger,
                            consolidation, or restructuring (including during due diligence and negotiation
                            of these); the sale of substantially all of our stock and/or assets; the
                            financing, acquisition, divestiture, or dissolution of all or a portion of our
                            business; or other corporate change.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Safety and Compliance with Law</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If we believe that disclosure is reasonably
                            necessary (i) to comply with any applicable law, regulation, legal process or
                            governmental request (e.g., from creditors, tax authorities, law enforcement
                            agencies, in response to a garnishment, levy, or lien notice, etc.); (ii) to
                            establish, exercise or defend our legal rights; (iii) to enforce or comply with
                            our&nbsp;General Terms&nbsp;or other applicable agreements or policies; (iv) to
                            protect our or our customers’ rights or property, or the security or integrity
                            of our Services; (v) for an investigation of suspected or actual illegal
                            activity; or (vi) to protect us, users of our Services or the public from harm,
                            fraud, or potentially prohibited or illegal activities.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>With Your Consent</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>With your consent. For example:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '105.0pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>At your direction or as described at the
                            time you agree to share;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '105.0pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>When you authorize a third party
                            application or website to access your information.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>With Others</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>With other companies who deliver services
                            on our behalf, including via the use of cookies and similar automated
                            technologies, and third party analytics.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Aggregated and Anonymized Information</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We also may share (within our group of
                            companies or affiliates, or with service providers or other third parties)
                            aggregated and/or anonymized information that does not specifically identify
                            you or any individual user of our Services.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="cookies" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>COOKIES AND OTHER SIMILAR AUTOMATED TECHNOLOGIES</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Digital cookies and similar technologies
                            help us make our services better to use by doing things like recognizing when
                            you’ve signed in, analyzing how you use our services so we can make them more useful
                            to you, giving you a more personalized experience, and making our ads to you
                            work better.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>When you interact with our online services, or open
                            emails we send you, we obtain certain information using automated technologies,
                            such as cookies, web server logs, web beacons, and other technologies. A
                            “cookie” is a text file that websites send to a visitor’s computer or other
                            internet-connected device to uniquely identify the visitor’s browser or to
                            store information or settings in the browser. A “web beacon,” also known as an
                            internet tag, pixel tag, or clear GIF, is a tiny graphic image that may be used
                            in our websites or emails.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We use these automated technologies to collect your
                            device information, internet activity information, and inferences as described
                            above. These technologies help us to:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Remember your information so you do not
                            have to re-enter it;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Track and understand how you use and
                            interact with our online services and emails;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Tailor our online services to your
                            preferences;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Measure how useful and effective our
                            services and communications are to you; and</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Otherwise manage and enhance our products
                            and services.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We set some of these automated technologies ourselves,
                            but others are set by third parties who deliver services on our behalf. For
                            example, we may use other companies’ web analytics services (described in the
                            “Third-Party Analytics Services” section), which use automated technologies to
                            help us evaluate how customers use of our websites.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Your browser can alert you when cookies are placed on
                            your device, and how you can stop or disable them via your browser settings.
                            Please note, however, that without cookies all of the features of our online
                            services may not work properly. If you use a mobile device, you can manage how
                            your device and browser share certain device data by changing the privacy and
                            security settings on your mobile device. You can learn more about cookies and
                            how to manage your preferences by visiting http://www.allaboutcookies.org.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="third" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>THIRD-PARTY ANALYTICS SERVICES</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We use other companies as service providers
                            to help us analyze our site, and track metrics. These service providers
                            generally promised us under contract to keep data private but have their own
                            privacy policies that you should be aware of.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We may use third-party analytics service providers to
                            help us with our online services, such as Google Analytics, BugSnag, and
                            Crashlytics. The analytics providers that administer these services use
                            technologies such as cookies, web beacons, and web server logs to help us
                            analyze how you use our online services. We may disclose your site-use
                            information (including IP address) to these analytics providers, and other
                            service providers who use the information to help us figure out how you and
                            others use our online services.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>To learn more about Google Analytics and
                            how to opt out, please visit https://marketingplatform.google.com/about/ or
                            https://support.google.com/analytics/answer/181881?hl=en.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>To learn more about BugSnag, please visit
                            https://docs.bugsnag.com/legal/privacy-policy/.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>To learn more about Crashlytics, please
                            visit https://fabric.io/terms.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="long" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>HOW LONG WE KEEP YOUR INFORMATION</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We keep your information as long as you
                            keep using our products and services. After that, we keep it for as long as we
                            need it to do things like fight fraud, collect fees you owe, resolve disputes
                            with (or involving) you, follow the law, enforce our agreements and defend our
                            rights in court.</span></i></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We generally keep your information as long
                            as reasonably necessary to provide you the Services or to comply with
                            applicable law. However, even after you deactivate your account, we can retain
                            copies of information about you and any transactions or Services in which you
                            may have participated for a period of time that is consistent with applicable
                            law, applicable statute of limitations or as we believe is reasonably necessary
                            to comply with applicable law, regulation, legal process, or governmental
                            request, to detect or prevent fraud, to collect fees owed, to resolve disputes,
                            to address problems with our Services, to assist with investigations, to
                            enforce our General Terms&nbsp;or other applicable agreements or policies, or
                            to take any other actions consistent with applicable law.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="choices" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>YOUR CHOICES</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>You can see, change or fix information you
                            gave us, ask us to deactivate your account, control your location information,
                            or tell us to stop marketing to you by opting out.<br />
                            <br />
                            If you live in California, you have additional rights, which we describe below.</span></i></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Personal Information</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You can access, change, or correct
                            information that you have provided by logging into your PayRink account at any
                            time or by making a request to us using the&nbsp;contact details below. We will
                            need to verify your identity before granting access or otherwise changing or
                            correcting your information.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Deactivating Your Account</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If you wish to deactivate your account, you
                            can do so at any time by logging into your PayRink account and visiting </span><span style={{ color: 'black' }}><Link to="/">https://www.payrink.com</Link></span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If you would like to have your account
                            closed permanently, please contact us at&nbsp;</span><span style={{ color: 'black' }}><a href="https://www.payrink.com/"><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>https://www.payrink.com/</span></a></span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Location Information</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>In order to provide certain Services, we may require
                            access to location information, including precise geolocation information
                            collected from your device. If you do not consent to collection of this
                            information, certain Services will not function properly and you will not be
                            able to use those Services. You can stop our collection of location information
                            at any time by changing the preferences on your mobile device. If you do so,
                            some of our mobile applications will no longer function. You also may stop our
                            collection of location information via mobile application by following the
                            standard uninstall process to remove PayRink mobile applications from your
                            device.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Do Not Track</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Certain web browsers allow you to instruct your browser
                            to respond to Do Not Track (“DNT”) signals to website you visit, informing
                            those sites that you do not want your online activities to be tracked. At this
                            time, our websites are not designed to respond to DNT signals or similar
                            mechanisms from browsers.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>Promotional Communications</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You can opt out of receiving in-app push notifications
                            containing promotional messages from us by following the instructions on screen
                            or by following the instructions in those messages. If you decide to opt out,
                            we may still send you non-promotional communications in-app, or via email and
                            text, such as transaction receipts and messages about your account, or our
                            processing of your information in accordance with the provision of our
                            Services.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><a name="rights" /><span style={{ fontSize: '12.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>RIGHTS OF CALIFORNIA RESIDENTS</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If you live in California, the following additional
                            rights apply to you.</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Right
                            to Know</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You may have the right to request, up to twice in a
                            12-month period, to see the following information about the personal
                            information we have collected about you during the past 12 months:</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>The categories and specific pieces of
                            personal information we have collected about you;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>The categories of sources from which we
                            collected the personal information;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>The business or commercial purpose for
                            which we collected the personal information;</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>The categories of third parties with whom
                            we shared the personal information; and</span></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>The categories of personal information
                            about you that we disclosed for a business purpose, and the categories of third
                            parties to whom we disclosed that information for a business purpose.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>To request the specific pieces of personal information we
                            have collected about you, see “How to exercise your rights” below. You may find
                            all of the other information listed above in this Privacy Notice.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>California law gives you the right to ask if we disclose
                            your personal information to third parties for their direct marketing purposes
                            (we do not disclose your personal information for others’ direct marketing
                            purposes). It also gives you the right to ask if we sell your personal
                            information to third parties (we do not sell your personal information and have
                            not done so in the past), and if we did (which we don’t), you’d have the right
                            to opt out of such sales.</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Right
                            of Deletion</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You have the right to ask us to delete the personal
                            information we have collected from you (subject to exceptions the law
                            provides). Please note that you may no longer be able to use our Services if
                            you delete your personal information.</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Right
                            of Non-Discrimination</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You have the right to not be discriminated against if you
                            exercise these privacy rights. We will not discriminate against you, deny,
                            charge different prices for, or provide a different quality of goods or
                            services if you choose to exercise these rights.</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>How
                            to Exercise Your Rights</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If you live in California and would like to send us a
                            request to exercise one of your above rights, you may do so online at </span><span style={{ color: 'black' }}><Link to="/">https://www.payrink.com</Link></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}> or by contacting us as indicated in the “How to Contact
                                Us” section below.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>To help protect your privacy and maintain security, we
                            take steps to verify your identity and will require you to be logged in to your
                            PayRink account before granting you access to your personal information or
                            complying with your request.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>You may designate an authorized agent to make a request
                            on your behalf as permitted under law, though before we process that request,
                            we will require that you provide the authorized agent written permission to do
                            so and verify your identity directly with us.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>To the extent permitted by applicable law, we may charge
                            a reasonable fee to comply with your request.</span></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>Exceptions
                            to These Rights</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>California law provides for certain exceptions to the
                            rights described above. We reserve the right to avail ourselves of these
                            exceptions where applicable.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>CHILDREN’S PERSONAL INFORMATION</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>If you are a kid under 13, please don’t use
                            our services. If we collect your data and later learn you are a kid under 13,
                            we will delete it.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Our Services are general audience services not directed
                            at children under the age of 13. If we learn that any information we collect
                            has been provided by a child under the age of 13, we will promptly delete that
                            information.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="security" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>HOW WE SECURE INFORMATION</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We do a lot to keep your data safe. While
                            we think we have strong defenses in place, no one can ever guarantee that
                            hackers won’t be able to break into our sites or steal your data while it is
                            stored or flowing from you to us or vice versa.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We take reasonable measures, including administrative,
                            technical, and physical safeguards, to protect your information from loss,
                            theft, and misuse, and unauthorized access, disclosure, alteration, and
                            destruction. Nevertheless, the internet is not a 100% secure environment, and
                            we cannot guarantee absolute security of the transmission or storage of your
                            information. We hold information about you both at our own premises and with
                            the assistance of third-party service providers.</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>For more information about our security practices, please
                            visit </span><span style={{ color: 'black' }}><Link to="/">https://www.payrink.com</Link></span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="storage" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>STORAGE AND PROCESSING</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We operate in many countries, and we (or
                            our service providers) may move your data and process it outside the country
                            where you live.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We may, and we may use third-party service providers to,
                            process and store your information in the United States, Canada, Japan, the
                            European Union, and other countries.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="changes" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>CHANGES TO THIS PRIVACY NOTICE</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>We can change this privacy notice. When the
                            changes impact your rights or how we use your data in important ways, we’ll
                            tell you first, which may be through our app, website, or by email.</span></i></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>We may amend this Privacy Notice from time to time by
                            posting a revised version and updating the “Effective Date” above. The revised
                            version will be effective on the “Effective Date” listed. We will provide you
                            with reasonable prior notice of material changes in how we use your
                            information, which may include notification in PayRink through our app or
                            website, or by email, if you have provided one. If you disagree with these
                            changes, you may cancel your account at any time. If you keep using our
                            Services, you consent to any amendment of this Privacy Notice.</span></p>
                        <p style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><a name="contact" /><span style={{ fontSize: '18.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>HOW TO CONTACT US</span></p>
                        <p style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '13.0pt', fontFamily: '"inherit",serif', color: 'white', letterSpacing: '.15pt' }}>You can contact our privacy team with any
                            questions or concerns at the address below.</span></i></p>
                        <p style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>Please contact our privacy team with any
                            questions or concerns regarding this Privacy Notice: PayRink, Inc. 210 Delburg
                            St, Davidson, NC 28036. Email us at </span><span style={{ color: 'black' }}><a href="mailto:privacy@payrink.com">privacy@payrink.com</a></span><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}> &nbsp;</span></p>
                        <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '13.5pt', fontFamily: '"cash-market-rounded",serif', color: 'white', letterSpacing: '.15pt' }}>If you have any questions or concerns regarding our
                            privacy notice, or if you believe our privacy notice or applicable laws
                            relating to the protection of your personal information have not been
                            respected, you may file a complaint with our privacy team listed above. We will
                            respond to let you know when you can expect a further response. We may request
                            additional details from you regarding your concerns and may need to engage or
                            consult with other parties in order to investigate and address your issue. We may
                            keep records of your request and any resolution.</span></p>
                        <p className="MsoNormal">&nbsp;</p>
                    </div>

                </Container>
                {
                    localStorage.getItem('access_token') !== null ?
                        <FooterNew />
                        :
                        <FooterNew />
                }
            </section>
        </>
    )

}

export default PrivacyPolicy