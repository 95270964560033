/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Media } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import TransactionsL from '../APIs/TransactionList'
import Transactions from '../APIs/TransactionList'
import walletAmount from '../APIs/walletAmountApi'
import { config } from '../config'
import Footer2 from './Common/Footer2'
import Footer from './Common/Footer2'
import Header2 from './Common/Header2'

const Home = () => {

    const [transcList, setTranscList] = useState([])
    let history = useHistory()
    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            history.push('/login')
        }
        transacList();

    }, [])

    const Media = config.api_URL

    function transacList() {
        const year = new Date().getFullYear()
        TransactionsL("all", year, 1, "").then(Response => {
            setTranscList(Response.data.data)
        }).catch(function (err) {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
            toast.error(err.response.data.message)
        })
    }




    return (
        <>
            <Header2 />
            {/* <section className="wallet-box-area">
            <div className="container">
                <div className="media">
                    <img className="d-flex mr-3" src={require('../images/wallet_new.png').default} alt="image" width="100"/>
                    <div className="media-body">
                        <h5 className="mt-0">${Balnace}</h5>
                        <p>Your Balance</p>
                    </div>
                </div>
            </div>
        </section> */}
            <section className="money-section">
                <div className="container-fluid">
                    <div className="row">
                        <aside className="col-md-4 money-col">
                            <a className="money" onClick={() => history.push("/addmoney")}>
                                <img className="money-img" src={require('../images/money.png').default} alt="money" />
                                <h3>Add Money To PayRink</h3>
                            </a>
                        </aside>
                        <aside className="col-md-4 money-col">
                            <a className="money send" onClick={() => history.push("/sendmoney")}>
                                <img className="money-img" src={require('../images/send.png').default} alt="money" />
                                <h3>Pay Money</h3>
                            </a>
                        </aside>
                        <aside className="col-md-4 money-col">
                            <a className="money withdraw" onClick={() => history.push("/withdraw")} >
                                <img className="money-img" src={require('../images/withdraw.png').default} alt="money" />
                                <h3>Withdraw Money</h3>
                            </a>
                        </aside>
                    </div>
                </div>
            </section>

            <section className="transaction pb-5" style={{ minHeight: "280px" }}>
                <div className="container-fluid">
                    <div className="transaction-card">

                        <p className="main-para">Recent Transactions</p>
                        {transcList.length == 0 ? <p className="empty-text">"Welcome to PayRink! You can now pay for goods and services using our app."</p> : ''}

                        {
                            transcList.length > 0 && transcList.map((transc) =>
                                transc.fields.txn_type === "debit" ?
                                    <>
                                        <a className="history" style={{cursor:"pointer"}} onClick={() => history.push('/history?txn_key=' + transc.pk)}>
                                            <div className="row carding">
                                                <aside className="col-md-9 col-sm-9">
                                                    <div className="content">
                                                        <span className="user_img">
                                                            <img src={Media + transc.fields.from_account_img} alt="" />
                                                        </span>
                                                        {
                                                            transc.fields.is_business
                                                                ?
                                                                <span className="verify_img"><img src={require('../images/verify.png').default} alt="" /></span>
                                                                : ""
                                                        }
                                                        <p className="main-para text-light mb-0">{transc.fields.from_account}</p>
                                                        <p className="main-para">{transc.fields.created_at}</p>
                                                    </div>
                                                </aside>
                                                <aside className="col-md-3 col-sm-3 price-box">
                                                    <span className="price minus">- ${transc.fields.amount}</span>
                                                    <span className="price failed">{transc.fields.status}</span>
                                                </aside>
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a className="history" style={{cursor:"pointer"}} onClick={() => history.push('/history?txn_key=' + transc.pk)}>
                                            <div className="row carding">
                                                <aside className="col-md-9 col-sm-9">
                                                    <div className="content">
                                                        <span className="user_img">
                                                            <img src={Media + transc.fields.to_img} alt="" />
                                                        </span>
                                                        <p className="main-para text-light mb-0">{transc.fields.to}</p>
                                                        <p className="main-para">{transc.fields.created_at}</p>
                                                    </div>
                                                </aside>
                                                <aside className="col-md-3 col-sm-3 price-box">
                                                    <span className="price">+ ${transc.fields.amount}</span>
                                                    <span className="price failed">{transc.fields.status}</span>
                                                </aside>
                                            </div>
                                        </a>
                                    </>
                            )}



                    </div>


                </div>
            </section>
            <Footer2 />
        </>
    )
}
export default Home;