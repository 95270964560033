import axios from "axios"
import { config } from "../config";



export const businessClassification = () => {

    return axios.get(
        `${config.api_URL}/accounts/business-classifications`);
}



export const stateName = () => {
    return axios.get(
        `${config.api_URL}/states_list`
    );
}