import React, { useEffect, useState } from 'react';
import { Form, Breadcrumb } from 'react-bootstrap';
import Footer from './Common/Footer2';
import Header2 from './Common/Header2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { stateName } from '../APIs/Businessclassfyservice';
import { toast } from 'react-toastify';
import GetProfile from '../APIs/GetProfileApi';
import UpdatePro from '../APIs/UpdateProfileApi';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import { useHistory } from 'react-router';
import Footer2 from './Common/Footer2';
import { config } from '../config';
// import { propTypes } from 'react-bootstrap/esm/Image';

const Business_editprofile = (props) => {

    useEffect(() => {
        stateNames()
        Profile()
        if (!localStorage.getItem('access_token')) {
            props.history.push('/login')
            return false;
        }
    }, [])


    // const[UserDetails,setUserDetails]=useState('')
    const [Email, setEmail] = useState('')
    const [profileImg, setProfileImg] = useState('')
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [Address, setAddress] = useState('');
    const [picture, setPicture] = useState('')
    const [PostalCode, setPostalCode] = useState('');
    const [StateName, setStateName] = useState('')
    const [loader, setLoader] = useState(false)
    const [status, setStatus] = useState("")
    const [src, setSrc] = useState(require('../images/add.png').default);
    const [doc, setDoc] = useState('')
    const [beneficialAdded, setBeneficialAdded] = useState(false)
    const [DocType, setDocType] = useState('')
    const [license, setLicense] = useState(require('../images/add.png').default)
    const [LicenseImage, setLicenseImage] = useState("")
    const [benificierImage, setBenificier] = useState(require('../images/add.png').default)
    const [BeneficialDocImg, setBeneficialDocImg] = useState('')
    const [BeneficialDocType, setBeneficialDocType] = useState('')
    const [store, setStore] = useState('')
    const [ownerDocsAdded, setOwnerDocsAdded] = useState(false)
    const [beneficialDocsAdded, setBeneficialDocsAdded] = useState(false)

    var history = useHistory()

    function Profile() {
        GetProfile().then(Response => {
            setEmail(Response.data.email)
            setPhone(Response.data.phone)
            setCity(Response.data.city)
            setState(Response.data.state)
            setProfileImg(Response.data.picture)
            setAddress(Response.data.address)
            setStatus(Response.data.status)
            setPostalCode(Response.data.postalCode)
            setStore(Response.data.store)
            setBeneficialAdded(Response.data.beneficial_added)
            setBeneficialDocType(Response.data.beneficial_docType)
            setDocType(Response.data.owner_docType)
            if (Response.data.owner_docType) {
                setOwnerDocsAdded(true)
                let doc_ext = Response.data.owner.split('.').pop()
                if (doc_ext == 'pdf') {
                    setSrc("pdf.png")
                } else {
                    setSrc(config.api_URL + Response.data.owner)
                }
                let lic_ext = Response.data.licence.split('.').pop()
                if (lic_ext == 'pdf') {
                    setLicense("pdf.png")
                } else {
                    setLicense(config.api_URL + Response.data.licence)
                }
            }
            if (Response.data.beneficial_docType) {
                setBeneficialDocsAdded(true)
                let ben_ext = Response.data.beneficial.split('.').pop()
                if (ben_ext == 'pdf') {
                    setBenificier("pdf.png")
                } else {
                    setBenificier(config.api_URL + Response.data.beneficial)
                }
            }
            localStorage.setItem('profile', Response.data.picture)
        }).catch(function (err) {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
            toast.error(err.response.data.message)
        })
    }

    function HandleImage(event) {
        setPicture(event.target.files[0])
        let image = URL.createObjectURL(event.target.files[0])
        setProfileImg(image)
    }

    function stateNames() {
        stateName().then(res => {
            // console.log(res.data.data)
            setStateName(res.data.data)
        })
    }

    function onlyPostal(e) {
        // console.log(e)
        if (/^[0-9\b]+$/.test(e.target.value)) {
            setPostalCode(e.target.value);
        } else if (e.target.value === '')
            setPostalCode('');

    }

    function onlyAddress(e) {
        if (/^[a-zA-Z0-9 ]{0,51}$/.test(e.target.value)) {
            setAddress(e.target.value)
        }
        else if (e.target.value = '') {
            setAddress('')
        }
    }
    function onlyCity(e) {
        if (/^[A-Za-z ]+$/.test(e.target.value)) {
            setCity(e.target.value)
        } else if (e.target.value == '') {
            setCity('')
        }
    }

    function handleImage(event) {
        if (event.target.files[0]) {
            let file = event.target.files[0]
            let ext = file.name.split('.').pop()
            if (ext == "pdf") {
                setSrc("pdf.png")
            } else {
                setSrc(URL.createObjectURL(file));
            }
            setDoc(file)
        }
        event.target.value = ""
    }

    function handleLicense(event) {
        if (event.target.files[0]) {
            let file = event.target.files[0]
            let ext = file.name.split('.').pop()
            if (ext == "pdf") {
                setLicense("pdf.png")
            } else {
                setLicense(URL.createObjectURL(file))
            }
            setLicenseImage(file)
        }
    }

    function handleBeneficialImage(event) {
        if (event.target.files[0]) {
            setBeneficialDocImg(event.target.files[0])
            setBenificier(URL.createObjectURL(event.target.files[0]));
        }
    }

    function UpdateProfile(e) {
        e.preventDefault()
        console.log("clickkkk")
        toast.dismiss()
        if (profileImg == '') {
            toast.error("Business picture can't be empty")
            return false;
        }

        else if (Email == "") {
            toast.error("Email field can't be empty")
            return false;
        }

        else if (! /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(Email)) {
            toast.error('Please enter valid Email.')
            return false;
        }
        else if (phone == '') {
            toast.error("Phone number can't be empty")
            return false;
        }
        else if (state == '') {
            toast.error("State can't be empty")
            return false;
        }
        else if (city == '') {
            toast.error("City can't be empty")
            return false;
        }
        else if (PostalCode == '') {
            toast.error("Zip code can't be empty")
            return false;
        }
        else if (Address == '') {
            toast.error("Address can't be empty")
            return false;
        } else if (!ownerDocsAdded && (DocType == "" || doc == "" || LicenseImage == "")) {
            if (doc && DocType == "") {
                toast.error("Please select owner document type")
                return false;
            } else if (DocType && doc == "") {
                toast.error("Please upload owner document")
                return false;
            } else if (status == 'document' && DocType == "") {
                toast.error("Please upload owner document")
                return false;
            } else if (status == 'document' && LicenseImage == "") {
                toast.error("Please upload business license")
                return false;
            }
        } else if (!beneficialDocsAdded && beneficialAdded && (BeneficialDocType == "" || BeneficialDocImg == "")) {
            if (status == 'document' && beneficialAdded && BeneficialDocType == "") {
                toast.error("Please upload beneficial owner document")
                return false;
            } else if (BeneficialDocImg && BeneficialDocType == "") {
                toast.error("Please select beneficial owner document type")
                return false;
            } else if (BeneficialDocType && BeneficialDocImg == "") {
                toast.error("Please upload beneficial owner document")
                return false;
            }
        } else {
            setLoader(true)
            const data = new FormData()
            data.append('address', Address)
            data.append("city", city)
            data.append("state", state)
            data.append("postalCode", PostalCode)
            data.append("email", Email)
            data.append("phone", phone)
            data.append("store", store)
            if (picture) {
                data.append("picture", picture)
            }
            if (DocType) {
                data.append("docType", DocType)
            }
            if (doc) {
                data.append("document", doc)
            }
            if (BeneficialDocType) {
                data.append("beneficial_docType", BeneficialDocType)
                data.append("beneficial_document", BeneficialDocImg)
            }
            if (LicenseImage) {
                data.append("licence", LicenseImage)
            }

            UpdatePro(data).then(Response => {
                if (Response.status == 200) {
                    setLoader(false)
                    localStorage.setItem('profile', profileImg)
                    toast.success(Response.data.message)
                    props.history.push('/business_profile')
                }
            }).catch(function (err) {
                setLoader(false)
                toast.error(err.response.data.message)
            })
        }
    }

    return (
        <>
            <Header2 />
            <section className="signup_section py-5">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container-fluid">
                    <div className="edit_profile">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => history.push("/business_profile")} className="active"><i className="fa fa-angle-left mr-2" style={{ fontSize: "17px" }}></i>My Profile</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Edit Profile
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 mb-2 text-center">
                                <div className="user_image mr-3 mx-auto icon_circle">
                                    <i className="fa fa-camera"></i>

                                    <img src={profileImg.startsWith("/media/") ? config.api_URL + profileImg : profileImg} alt="" />
                                    <input type="file" accept="image/png, image/jpg, image/jpeg" className='' onChange={HandleImage} />
                                </div>
                                <div className="mt-3">
                                    <a className="text-white">Update Business Picture</a>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <Form>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="mb-3">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control as="select" value={state} onChange={(e) => { setState(e.target.value) }}>
                                                    <option>Select State</option>
                                                    {
                                                        StateName.length > 0 && StateName.map((States, index) => <option key={index} value={States.code}>{States.name} ,  {States.code}</option>)
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="mb-3">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="" placeholder="E.g. Los Angeles" value={city} onChange={(e) => { onlyCity(e) }} />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control type="" inputMode="numeric" maxlength="5" placeholder="E.g.12345" value={PostalCode} onChange={(e) => { onlyPostal(e) }} />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="" maxLength="50" placeholder="E.g. California USA" value={Address} onChange={(e) => { onlyAddress(e) }} />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Store</Form.Label>
                                                <Form.Control disabled={status == 'verified'} type="" maxLength="50" placeholder="E.g. Walmart 201" value={store} onChange={(e) => { setStore(e.target.value) }} />
                                            </Form.Group>
                                        </div>

                                        {
                                            status == "document" ?
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className="col-md-6 mb-2">
                                                            <Form.Group controlId="exampleForm.ControlSelect3">
                                                                <Form.Label>Document type</Form.Label>
                                                                <Form.Control as="select" onChange={(e) => { setDocType(e.target.value) }}>
                                                                    <option value=''>Select document type</option>
                                                                    <option value='idCard' selected={DocType === "idCard"}>ID Card</option>
                                                                    <option value='passport' selected={DocType === "passport"}>Passport</option>
                                                                    <option value='license' selected={DocType === "license"}>Driver License</option>
                                                                    {/* <option value='other' selected={DocType === "other"}>Other</option> */}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-3 mb-2">
                                                            <Form.Group className="mb-3" controlId="formFile">
                                                                <Form.Label>Owner ID</Form.Label>
                                                                <div className="dcmnt_img">
                                                                    <img src={src} alt="" />
                                                                    <input type="file" name='document' accept="image/jpeg,image/jpg,image/png,application/pdf" className="form-control imginput_upload" onChange={handleImage} />
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-3 mb-2">
                                                            <Form.Group className="mb-3" controlId="formFile">
                                                                <Form.Label>Business License</Form.Label>
                                                                <div className="dcmnt_img">
                                                                    <img src={license} alt="" />
                                                                    <input type="file" name='document' accept="image/jpeg,image/jpg,image/png,application/pdf" className="form-control imginput_upload" onChange={handleLicense} />
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    {
                                                        beneficialAdded ?
                                                            <>
                                                                <div className='row'>
                                                                    <div className="col-md-6 mb-2">
                                                                        <Form.Group controlId="exampleForm.ControlSelect3">
                                                                            <Form.Label>Beneficial Owner document type</Form.Label>
                                                                            <Form.Control as="select" onChange={(e) => { setBeneficialDocType(e.target.value) }}>
                                                                                <option value=''>Select document type</option>
                                                                                <option value='idCard' selected={BeneficialDocType === "idCard"}>ID Card</option>
                                                                                <option value='passport' selected={BeneficialDocType === "passport"}>Passport</option>
                                                                                <option value='license' selected={BeneficialDocType === "license"}>Driver License</option>
                                                                                {/* <option value='other' selected={BeneficialDocType === "other"}>Other</option> */}
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    </div>

                                                                    <div className="col-md-3 mb-2">
                                                                        <Form.Group className="mb-3" controlId="formFile">
                                                                            <Form.Label>Beneficial Owner Document Image</Form.Label>
                                                                            <div className="dcmnt_img">
                                                                                <img src={benificierImage} alt="" />
                                                                                <input type="file" name='document' accept="image/jpeg,image/jpg,image/png,application/pdf" className="form-control imginput_upload" onChange={handleBeneficialImage} />
                                                                            </div>
                                                                        </Form.Group>
                                                                    </div>

                                                                </div>
                                                            </>
                                                            : ""
                                                    }
                                                </div>
                                                : ""
                                        }

                                        <div className="col-md-12 mb-4">
                                            <div className="mt-2">
                                                <button className="btn subtn1 mt-3" onClick={(e) => UpdateProfile(e)}>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer2 />
        </>
    )
}
export default Business_editprofile;