import axios from "axios";
import { config } from "../config";


function OldNumberOtp(data){
  
    return axios.post(`${config.api_URL}/accounts/otp_old_number`,data,{
        headers:{'Authorization':localStorage.getItem('access_token')}
})
}

export default OldNumberOtp;