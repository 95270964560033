import axios from "axios";
import { config } from "../config";

function ForgetPin(){
    return axios.get(`${config.api_URL}/accounts/forgot_pin`,{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}
export default ForgetPin;