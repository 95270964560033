import React, { useState } from 'react';
import {Form, Button} from 'react-bootstrap'
import { Flip, toast } from 'react-toastify';
import validator from 'validator';
import { ForgetPasswordApi } from '../../APIs/Forgetpasswordservice';
import Footer from './Footer';
import Header from './Header';
import { LoadingSpinerComponent } from './loadingspinner';



const Forgotpassword = () => {
    const[email,setEmail]=useState('')
    const[loader,setLoader]=useState(false)

    const forgetPassword=()=>{
        toast.dismiss()
        if (email == "") {
            toast.error("email field can't be empty")
        }
       
    else if (! /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
        toast.error('Please enter valid Email.')}
    else{
        setLoader(true)
       const data={email} // used for when there is lot of data coming object destructuring method also we can send email without data variable directly.. 
    
       ForgetPasswordApi(data).then(Response=>{
           if (Response.status===200){
               setLoader(false)
                toast.success(Response.data.message)
           }
           else{
               setLoader(false)
               toast.error(Response.data.message)
           }
       }).catch(function(err){
           setLoader(false)
           toast.error(err.response.data.message)
       })
    }

    }
    function HandleKey(e){
        if(e.which==32){
            e.preventDefault()
        }else{
            setEmail(e.target.value)
        }
    }


    return(
        <>
        <Header />
            <section className="signup_section py-5">
            <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-7 m-auto">
                            <div className="text-center">
                                <h4 className="text-white mb-2">Forgot your password?</h4>
                                <p className="pt-2 mb-0">Enter your registered email address in the field below.</p>
                                <p className="pt-0">We will send you a link to reset your password.</p>
                            </div>
                            <div className="signup_box my-4">
                            <Form>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="" placeholder="E.g. adam_smith007@gmail.com" onKeyDown={(e)=>HandleKey(e)} onChange={(e)=>setEmail(e.target.value)} required/>
                                </Form.Group>

                                <Button className="btn signupbtn mt-4" onClick={forgetPassword}>
                                    Reset My Password
                                </Button>
                                <div className="text-center mt-3 Existing">
                                    <p className="pb-0 mb-0 pt-2">Back to <a href="/login" className="text-white"><u>Login</u></a></p>
                                </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
export default Forgotpassword;