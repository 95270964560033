import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image';
import { stateName } from '../../APIs/Businessclassfyservice';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Header from '../Common/Header';
import DateField from '../Common/DateField';

const Step2 = (props) => {
    const [StateName, setStateName] = useState('')
    const [FirstName, setFirstName] = useState(props.stepBack.first_name ? props.stepBack.first_name : '')
    const [LastName, setLastName] = useState(props.stepBack.last_name ? props.stepBack.last_name : '')
    const [ssn, setSsn] = useState(props.stepBack.ssn ? props.stepBack.ssn : '')
    const [Dob, setDob] = useState(props.stepBack.dateOfBirth ? props.stepBack.dateOfBirth : '')
    const [Address, setAddress] = useState(props.stepBack.owner_address ? props.stepBack.owner_address : '')
    const [state, setState] = useState(props.stepBack.owner_state ? props.stepBack.owner_state : '')
    const [City, setCity] = useState(props.stepBack.owner_city ? props.stepBack.owner_city : '')
    const [PostalCode, setPostalCode] = useState(props.stepBack.owner_postalCode ? props.stepBack.owner_postalCode : '')
    const [image, setImage] = useState(props.stepBack.image ? props.stepBack.image : require('../../images/add.png').default)
    const [DocumentType, setDocumentType] = useState(props.stepBack.owner_docType ? props.stepBack.owner_docType : '')
    const [DocImage, setDocImage] = useState(props.stepBack.owner_document ? props.stepBack.owner_document : '')


    const Maxdate = moment(new Date()).subtract(18, 'years')
    const newDate = new Date(Maxdate)
    useEffect(() => {
        stateNames()
    }, []);

    function stateNames() {
        stateName().then(res => {
            setStateName(res.data.data)
        })
    }

    function handleImage(event) {
        if (event.target.files[0]) {
            let file = event.target.files[0]
            let ext = file.name.split('.').pop()
            if (ext == "pdf") {
                setImage("pdf.png")
            } else {
                setImage(URL.createObjectURL(file))
            }
            setDocImage(file)
        }
        event.target.value = ""
    }

    function handleBack() {
        const step2 = {
            first_name: FirstName,
            last_name: LastName,
            ssn: ssn,
            dateOfBirth: Dob,
            owner_address: Address,
            owner_state: state,
            owner_city: City,
            owner_postalCode: PostalCode,
        }
        if (props.businessType != "soleProprietorship") {
            step2.owner_docType = DocumentType
            step2.owner_document = DocImage
            step2.image = image
        }
        props.handleChange(2)
        props.backData(step2)
    }


    function handleNext() {
        const step2 = {
            first_name: FirstName,
            last_name: LastName,
            ssn: ssn,
            dateOfBirth: Dob,
            owner_address: Address,
            owner_state: state,
            owner_city: City,
            owner_postalCode: PostalCode,
        }
        if (props.businessType != "soleProprietorship") {
            step2.owner_docType = DocumentType
            step2.owner_document = DocImage
            step2.image = image
        }
        props.setStep2Data(step2)
        props.handleStep2(step2)
    }
    function onlyNumber(e) {
        if (/^[0-9\b]+$/.test(e.target.value)) {
            setSsn(e.target.value);
            return true;
        } else if (e.target.value === '')
            setSsn('');

    }
    function onlyPostal(e) {
        // console.log(e)
        if (/^[0-9\b]+$/.test(e.target.value)) {
            setPostalCode(e.target.value);
        } else if (e.target.value === '')
            setPostalCode('');

    }

    function handleDateChangeRaw(e) {
        e.preventDefault()
    }

    function onlyAddress(e) {
        if (/^[a-zA-Z0-9 ]{0,51}$/.test(e.target.value)) {
            setAddress(e.target.value)
        }
        else if (e.target.value = '') {
            setAddress('')
        }
    }


    return (
        <>
            <section className="signup_section">
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h6 className="text-white businesstext">Business Owner Details</h6>
                        <hr className="bg-secondary"></hr>
                    </div>
                </div>
                <div className="row">
                    <div className={"col-md-12"}>
                        <Form>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="" placeholder="E.g.Adam" value={FirstName} onChange={(e) => { setFirstName(e.target.value) }} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="" placeholder="E.g.Smith" value={LastName} onChange={(e) => { setLastName(e.target.value) }} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>SSN(Social Security Number)</Form.Label>
                                        <Form.Control type="text" inputmode="numeric" maxlength="9" placeholder="E.g.123456789" value={ssn} onChange={(e) => onlyNumber(e)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2 datepicker1">
                                    <Form.Group className="mb-3">
                                        <Form.Label>D.O.B</Form.Label>
                                        {/* <DatePicker
                                            className="mb-3"
                                            onChangeRaw={(e) => { handleDateChangeRaw(e) }}
                                            showMonthDropdown
                                            showYearDropdown

                                            maxDate={newDate}
                                            selected={Dob}
                                            onChange={(e) => { setDob(e) }}
                                            clearIcon={null}
                                            dateFormat='MM-dd-yyyy'
                                            placeholderText="MM-DD-YYYY"
                                        /> */}
                                        <DateField value={Dob} setValue={setDob} />
                                        {/* <Form.Control type="" placeholder="YYYY-MM-DD" onChange={(e)=>{setDob(e.target.value)}}/> */}
                                    </Form.Group>
                                </div>

                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Address (Street)</Form.Label>
                                        <Form.Control type="" placeholder="E.g. California USA" value={Address} onChange={(e) => { onlyAddress(e) }} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control as="select" value={state} onChange={(e) => { setState(e.target.value) }}>
                                            <option>Select State</option>
                                            {
                                                StateName.length > 0 && StateName.map((States, index) => <option key={index} value={States.code}>{States.name} ,  {States.code}</option>)
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="" placeholder="E.g. Los Angeles" value={City} onChange={(e) => { setCity(e.target.value) }} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control type="" inputMode="numeric" maxlength="5" placeholder="E.g.12345" value={PostalCode} onChange={(e) => { onlyPostal(e) }} />
                                    </Form.Group>
                                </div>
                                {
                                    props.businessType != 'soleProprietorship' ?
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group controlId="exampleForm.ControlSelect2">
                                                        <Form.Label>Document Type</Form.Label>
                                                        <Form.Control as="select" value={DocumentType} onChange={(e) => {
                                                            setDocumentType(e.target.value)
                                                        }}>
                                                            <option value=''>Select document type</option>
                                                            <option value='idCard'>ID Card</option>
                                                            <option value='passport'>Passport</option>
                                                            <option value='license'>Driver License</option>
                                                            {/* <option value='other'>Other</option> */}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Upload ID</Form.Label>
                                                        <div className="dcmnt_img">
                                                            <span>
                                                                <img src={image} alt="" />
                                                            </span>
                                                            <input type="file" accept="image/jpeg,image/jpg,image/png,application/pdf" className='imginput_upload' onChange={handleImage} />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                        </Form>

                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between">
                                <button className="btn backbtn" onClick={handleBack}>
                                    Back
                                </button>
                                <button className="btn nxtbtn" onClick={handleNext}>
                                    Submit
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Step2;