import axios from "axios";
import { config } from "../config";



function BankList(){
    return axios.get(`${config.api_URL}/accounts/list_accounts`,
    {headers:{
        'Authorization':localStorage.getItem('access_token')}
    })

}

export default BankList;



