import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap'
import { useState } from 'react';
import { stateName } from '../../APIs/Businessclassfyservice';
import { SignUp } from '../../APIs/FormsAPI';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import Header from '../Common/Header';
import moment from 'moment';
import Footer from '../Common/Footer';
import Header3 from '../Common/header3';
import { LoadingSpinerComponent } from '../Common/loadingspinner';
import MaskedInput from 'react-maskedinput';
import DateField from '../Common/DateField';
// import { propTypes } from 'react-bootstrap/esm/Image';



const FormData = require('form-data');


const Business_owner = (props) => {

    // const [src, setSrc] = useState(require('../../images/add.png').default);
    const [profile, setProfile] = useState('')
    const [picture, setPicture] = useState('')
    // const [doc, setDoc] = useState('')
    const [StateName, setStateName] = useState('');
    const [city, setCity] = useState('')
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [SocialSecurityNumber, setSocialSecurityNumber] = useState('')
    const [DateOfBirth, setDateOfBirth] = useState('')
    const [Email, setEmail] = useState('')
    const [State, setState] = useState('')
    const [DocType, setDocType] = useState('')
    const [Address, setAddress] = useState('')
    const [PostalCode, setPostalCode] = useState('')
    const phone = localStorage.getItem('phone')
    const [loader, setLoader] = useState(false)
    const [locationKeys, setLocationKeys] = useState([])
    const [dates, setDates] = useState({ display: '', value: '' })

    const history = useHistory()
    const Maxdate = moment(new Date()).subtract(18, 'years').toDate()
    // const newDate = new Date(Maxdate)

    useEffect(() => {
        stateNames()
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
                //   alert("Push")
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                    // alert("pop")
                    // Handle forward event

                } else {
                    setLocationKeys((keys) => [location.key, ...keys])
                    //  alert("Back button")
                    // Handle back event

                }
            }
        })

    }, [locationKeys,]);


    // function handleImage(event) {
    //     if (event.target.files[0]) {
    //         // alert('success')
    //         setDoc(event.target.files[0])
    //         setSrc(URL.createObjectURL(event.target.files[0]));
    //     }
    // }

    function handleProfile(event) {
        if (event.target.files[0]) {
            // alert('success')
            setPicture(event.target.files[0])
            setProfile(URL.createObjectURL(event.target.files[0]));

        }

    }

    function stateNames() {
        stateName().then(res => {
            setStateName(res.data.data)
        })
    }

    function SubmitForm(event) {
        event.preventDefault()
        toast.dismiss()
        // if (profile === '') {
        //     toast.error('Please upload Profile image')
        //     return false;
        // }

        if (FirstName.trim() === "") {
            toast.error("First Name field can't be empty")
            return false;
        }
        else if (LastName.trim() === "") {
            toast.error("Last Name field can't be empty")
            return false;
        }
        else if (SocialSecurityNumber.trim() === "") {
            toast.error("SSN field can't be empty")
            return false;
        }
        else if (SocialSecurityNumber.trim().length !== 9) {
            toast.error("Please enter valid SSN number")
            return false;
        }
        else if (DateOfBirth === "") {
            toast.error("DOB field can't be empty")
            return false;
        }

        else if (Address.trim() === '') {
            toast.error("Address field can't be empty")
            return false;
        }
        else if (State === '') {
            toast.error("Please select state")
            return false;
        }
        else if (city.trim() === '') {
            toast.error("City field can't be empty")
            return false;
        }
        else if (PostalCode.trim() === '') {
            toast.error("Zip Code field can't be empty")
            return false;
        }
        else if (PostalCode.trim().length !== 5) {
            toast.error('Please enter valid Zip Code')
            return false;
        }
        // else if (doc && DocType === '') {
        //     toast.error("Please select document type.")
        //     return false;
        // }
        // else if (DocType && doc === '') {
        //     toast.error('Please upload document image')
        //     return false;
        // }

        else {
            let date = moment(DateOfBirth).format("YYYY-MM-DD")
            const data = {
                FirstName,
                LastName,
                SocialSecurityNumber,
                date,
                State,
                city,
                Address,
                Email,
                phone,
                picture,
                // doc,
                PostalCode,
                // DocType,
            }
            setLoader(true)
            SignUp.normalUserSignup(data).then(Response => {
                if (Response.status === 200) {
                    setLoader(false)
                    toast.success(Response.data.message)
                    history.push('/login')
                }

            }).catch(function (err) {
                setLoader(false)
                console.log({ err })
                toast.error(err.response.data.message)

            })
        }
    }
    function onlyNumber(e) {
        console.log(e.target.value);
        if (/^[0-9\b]+$/.test(e.target.value)) {
            setSocialSecurityNumber(e.target.value);
            return true;
        } else if (e.target.value === '')
            setSocialSecurityNumber('');
    }

    function PostNo(e) {
        if (/^[0-9\b]+$/.test(e.target.value)) {
            setPostalCode(e.target.value);
        } else if (e.target.value === '')
            setPostalCode('');

    }

    function onlyAddress(e) {
        if (/^[a-zA-Z0-9 ]{0,51}$/.test(e.target.value)) {
            setAddress(e.target.value)
        }
        else if (e.target.value = '') {
            setAddress('')
        }
    }


    return (
        <>
            <Header3 />
            <section className="signup_section py-5">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 col-md-12 m-auto">
                            <div className="text-center">
                                <h4 className="text-white mb-2">Create Profile</h4>
                            </div>
                            <div className="signup_box my-4">
                                <div className="row">
                                    <div className="col-md-3 text-center">
                                        {/* <a className="" href="/#"> */}
                                        <div className="icon_circle">
                                            <img src={profile} alt="" />
                                            <input type="file" name='picture' accept="image/*" className="form-control" onChange={handleProfile} />
                                            <i className="fa fa-camera"></i>
                                        </div>
                                        <p className="mt-2 addphototext text-white">Add a Photo</p>
                                        {/* </a> */}

                                    </div>
                                    <div className="col-md-9">
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control type="" placeholder="E.g.Adam" onChange={(e) => { setFirstName(e.target.value) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control type="" placeholder="E.g.Smith" onChange={(e) => { setLastName(e.target.value) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Social Security Number(SSN)</Form.Label>
                                                        <Form.Control type="" maxLength="9" inputMode="numeric" placeholder="E.g.1234" value={SocialSecurityNumber} onChange={(e) => onlyNumber(e)} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2 datepicker1">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label >D.0.B</Form.Label>

                                                        {/* <DatePicker
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            maxDate={Maxdate}
                                                            selected={dates.display}
                                                            onChange={handleDateChange}
                                                            // startDate={Maxdate}
                                                            value={dates.display}
                                                            clearIcon={null}
                                                            dateFormat='MM-dd-yyyy'
                                                            placeholderText="MM-DD-YYYY"
                                                            onChangeRaw={(e) => handleDateChangeRaw(e)}
                                                            customInput={
                                                                <MaskedInput value={dates.value} mask="11-11-1111" placeholder="MM-DD-YYYY" />
                                                            }
                                                        /> */}

                                                        <DateField value={DateOfBirth} setValue={setDateOfBirth} />

                                                    </Form.Group>

                                                    {/* <div className="form-group">
                                                    <label >D.0.B</label>
                                                        <input type="date" className="form-control" pattern="YYYY-MM-DD" onChange={(e) => alert(JSON.stringify(e.target.value))}
                                                         />
                                                    </div> */}

                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Email ID</Form.Label>
                                                        <Form.Control type="" value={localStorage.getItem('email')} placeholder="E.g. adam@gmail.com" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Phone Number</Form.Label>
                                                        <Form.Control type="" placeholder="E.g. (987-653-2110)" value={localStorage.getItem('phone')} disabled />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Address (Street)</Form.Label>
                                                        <Form.Control type="" maxLength="50" value={Address} placeholder="E.g. California USA" onChange={(e) => { onlyAddress(e) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Control as="select" onChange={(e) => { setState(e.target.value) }}>
                                                            <option>Select State</option>
                                                            {
                                                                StateName.length > 0 && StateName.map((States, index) => <option key={index} value={States.code}>{States.name} ,  {States.code}</option>)
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    {/* <Form.Group controlId="exampleForm.ControlSelect2">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control as="select">
                                                        <option>Select City</option>
                                                        <option>City</option>
                                                        </Form.Control>
                                                    </Form.Group> */}
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control type="" placeholder="E.g. Los Angeles" onChange={(e) => { setCity(e.target.value) }} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Zip Code</Form.Label>
                                                        <Form.Control type="" inputMode="numeric" value={PostalCode} maxLength="5" placeholder="E.g.12345" onChange={(e) => PostNo(e)} />
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-md-6 mb-2">
                                                    <Form.Group controlId="exampleForm.ControlSelect3">
                                                        <Form.Label>Document Type</Form.Label>
                                                        <Form.Control as="select" onChange={(e) => { setDocType(e.target.value) }}>
                                                            <option value=''>Select document type</option>
                                                            <option value='idCard'>ID Card</option>
                                                            <option value='passport'>Passport</option>
                                                            <option value='license'>Driver License</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <Form.Group className="mb-3" controlId="formFile">
                                                        <Form.Label>Document Image</Form.Label>
                                                        <div className="dcmnt_img">
                                                            <img src={src} alt="" />
                                                            <input type="file" name='document' accept="image/*" className="form-control imginput_upload" onChange={handleImage} />
                                                        </div>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div>
                                                <button className="btn submit1" onClick={SubmitForm}>Submit</button>
                                            </div>
                                        </Form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Business_owner;