import axios from "axios";
import { config } from "../config";

function NotifyOnOff(data){
    return axios.post(`${config.api_URL}/accounts/notificationsOnOff`,data,{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}

export default NotifyOnOff;