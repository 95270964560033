import moment from "moment"
import { toast } from "react-toastify"

export default function DateField({value, setValue}) {
    const Maxdate = moment(new Date()).subtract(18, 'years')
    const Mindate = moment(new Date()).subtract(115, 'years')

    function handleChange(e) {
        let val = e.target.value
        let mVal= moment(val)
        if(mVal.isAfter(Maxdate)){
            setValue("")
            toast.error("You must be 18 years older.")
        } else {
            setValue(val)
        }
    }

    return (
        <>
            <input type="date" max={Maxdate.format("yyyy-MM-DD")} min={Mindate.format("yyyy-MM-DD")}  className="form-control" value={value} onChange={handleChange} />
        </>
    )
}