import React, { useEffect, useState } from 'react';
import { Form, Breadcrumb, Modal } from 'react-bootstrap';
import Footer from './Common/Footer2';
import Header2 from './Common/Header2';
import 'react-phone-input-2/lib/style.css'
import NotifyOnOff from '../APIs/NotificationsOnOffApi';
import { toast } from 'react-toastify';
import BankList from '../APIs/BankListApi';
import deleteAccount from '../APIs/RemoveBankApi';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import Link4 from './link4';
import { useHistory } from 'react-router';
import Footer2 from './Common/Footer2';
import { config } from '../config';

const Settings = () => {

    const [show, setShow] = useState(false);
    const [notiStatus, setNotiStatus] = useState(localStorage.getItem('notify') == 'true' ? true : false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [phone, setPhone] = useState('');
    const [AccountList, setAccountList] = useState([]);
    const [AccountId, setAccountId] = useState('');
    const [id, setId] = useState('')
    const [confirm, setConfirm] = useState(false)
    const [account, setAccount] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            history.push('/login')
            return false;
        }
        List()
    }, [])

    const Media = config.api_URL
    let history = useHistory()
    function List() {
        toast.dismiss()

        BankList().then(Response => {

            setAccountList(Response.data.data)

        }).catch(function (err) {
            if (err.response.status == 401) {
                history.push('/login')
                localStorage.removeItem('access_token')
            }
            toast.error(err.response.data.message)
        })
    }

    function handleMyProfile() {
        if (localStorage.getItem('is_business') == 'true') {
            history.push('/business_profile')
        } else {
            history.push('/profile')
        }
    }



    const removeAccount = (event) => {
        event.preventDefault()
        setAccount(true)
        console.log(id)
        const data = {
            'account_id': id
        }
        toast.dismiss()
        deleteAccount(data).then(Response => {
            if (Response.status === 200) {
                setId('')
                toast.success(Response.data.message)
                window.setTimeout(() => {
                    setConfirm(false)
                    history.push('/settings')
                }, 3000)
            }
        }).catch(function (err) {
            toast.error(err.response.data.msg)
        })
    }
    function handleConfirm(event, id) {
        event.preventDefault()
        setConfirm(true)
        setId(id)
    }
    function handleConfirmClose() {
        setId('')
        setConfirm(false)
    }

    function HandleSwitch() {
        toast.dismiss()
        if (notiStatus == true) {
            setNotiStatus(false)
            const data = {
                "enable": false
            }
            NotifyOnOff(data).then(Response => {
                if (Response.status == 200) {
                    console.log("stnotiStatusat-on true", notiStatus, Response.data.notifications)
                    setNotiStatus(Response.data.notifications)
                    localStorage.setItem('notify', Response.data.notifications)
                    //    alert(localStorage.setItem('notify'))
                    toast.success("Notifictaion Disabled")
                }
            }).catch(function (err) {
                toast.error(err.response.data.message)
            })
        } else {
            //    alert("true one")
            setNotiStatus(true)
            const data = {
                "enable": true
            }
            NotifyOnOff(data).then(Response => {
                if (Response.status == 200) {
                    console.log("notiStatus------- on false", notiStatus, Response.data.notifications)
                    setNotiStatus(Response.data.notifications)
                    localStorage.setItem('notify', Response.data.notifications)
                    toast.success("Notification Enabled")
                }
            }).catch(function (err) {
                toast.error(err.response.data.message)
            })
        }
    }


    return (
        <>
            <Header2 />
            <section className="signup_section py-5">
                <div className="container-fluid">
                    <div className="edit_profile">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={handleMyProfile} className="active"><i className="fa fa-angle-left mr-2" style={{ fontSize: "17px" }}></i>My Profile</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Settings
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="setting_head"><img src={require('../images/setting.png').default} alt="" className="mr-2" /> Turn On/Off Notifications</h3>
                            </div>
                            <div className="col-md-6 text-right">
                                <div className="mb-3 custom-switch mr-3">
                                    {/* <Form>
									<Form.Check
										type="switch"
                                        value={Switch}
										id="custom-switch2"
                                        // onClick={HandleSwitch}
                                       
									/>
								</Form> */}

                                    {/* <label className="switch round custom-control-label">
                                        <input className="custom-control-input" type="checkbox"  defaultChecked={localStorage.getItem('notify')} onChange={(e)=>HandleSwitch(e)}/>
                                        <span className="slider round"></span>
                                    </label> */}

                                    <form className="">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" id="custom-switch2" className="custom-control-input" checked={notiStatus} onChange={HandleSwitch} />
                                            <label title="" for="custom-switch2" className="custom-control-label">
                                            </label>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="bg-secondary" />
                            </div>
                            <div className="col-md-12">
                                <div className="row mt-4 divsrow">
                                    <aside className="leftside1">
                                        <div className="bank_list">
                                            <h6>Your Saved Bank</h6>
                                            <div className="row mt-3">
                                                <aside className="col-md-12">
                                                    {AccountList.length == 0 ? <p>No bank account linked, Please add new bank account.</p> : ""}
                                                    {
                                                        AccountList.length > 0 && AccountList.map((account) =>

                                                            <div className="bank_box active mb-2">
                                                                <div className="content">
                                                                    <span className="user_img">
                                                                        <img src={Media + account.fields.img} alt="" />
                                                                    </span>
                                                                    <div className="bank_innerrow" >
                                                                        <span className="check-box-bank">
                                                                            <p className="main-para text-light mb-0"  >{account.fields.institute_name + " **** " + account.fields.mask}</p>
                                                                            {/* <p className="main-para pt-1">Alex Smith</p> */}
                                                                        </span>
                                                                        <span>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value={account.pk} onClick={(e) => setAccountId(e.target.value)} disabled hidden />
                                                                            </div>
                                                                        </span>

                                                                        <span><i className="fa fa-trash card-delete-box" aria-hidden="true" value={account.pk} onClick={(e) => { handleConfirm(e, account.pk) }}></i></span>

                                                                    </div>
                                                                </div>
                                                            </div>)

                                                    }



                                                </aside>
                                                {AccountList.length > 0 ?
                                                    <aside className="col-md-12 aabtn1">
                                                        <button className="btn mt-4" onClick={handleShow} hidden disabled>Submit</button>
                                                    </aside>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </aside>
                                    <aside className="centerside1">
                                        <span>OR</span>
                                    </aside>
                                    <aside className="rightside1 text-left">
                                        <h3> Add Bank Account</h3>
                                        <Link4 />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer2 />
            <Modal show={confirm} onHide={handleConfirmClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <LoadingSpinerComponent promiseInProgress={account} />
                <Modal.Body className="p-0">
                    <button type="button" className="close" onClick={handleConfirmClose}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 m-auto p-1">
                                <div className="signup_box my-2 text-center">
                                    <h5 className="text-white mt-3 mb-4">Are you sure, you want to delete the bank?</h5>
                                    <div className="yesnobtn">
                                        <a className="btn yesbtn text-white" onClick={event => { removeAccount(event) }}>Yes</a>
                                        <a className="btn nobtn text-white" onClick={handleConfirmClose}>No</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="sucessmodal" show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={require('../images/remove.png').default} alt="" />
                                <h5 className="text-white mt-3" style={{ fontSize: "22px" }}>Remove Bank Account</h5>
                                <p style={{ fontSize: "18px" }}>Are you sure you want to remove your BB&T bank account?</p>
                                <div className="aabtn1">
                                    <button className="btn mt-1 mb-2" style={{ padding: "13px" }}>Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Settings;