import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import GetNotification from '../../APIs/GetNotifications'
import RemToken from '../../APIs/LogoutApi'
import walletAmount from '../../APIs/walletAmountApi'
import { config } from '../../config'
import { LoadingSpinerComponent } from './loadingspinner'


const Header2 = (props) => {
    const Media = config.api_URL
    const [Balnace, setBalance] = useState('')
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [notiList, setNotiList] = useState([])
    const [status, setStatus] = useState(true)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)


    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            window.location.href = '/login'
            return false;
        }
        walletBalance();
        Notifications(page);
    }, [])

    function checkes() {
        if (localStorage.getItem("access_token")) {
            history.push('/home')
        }
        else { history.push('/') }
    }

    // function checkes2(){
    //     console.log('tokeennnn--->>',localStorage.getItem("access_token"))
    //     if(localStorage.getItem("access_token")){
    //         history.push('/transactions')
    //     }
    //     else{history.push('/')}
    // }
    function handleScroll() {
        if (page < totalPages) {
            // alert(page)
            console.log(totalPages)
            var temp = page + 1
            console.log(typeof (temp))
            setPage(temp)
            // console.log(page)
            Notifications(temp)
        } else {
            setStatus(false)
        }



    }

    function walletBalance() {
        walletAmount().then(Response => {
            if (Response.status === 200) {
                setBalance(Response.data.data.balance)
                localStorage.setItem("balance", Response.data.data.balance)
            }
        })

    }


    function remove() {
        setLoader(true)
        toast.dismiss()
        RemToken().then(Response => {
            if (Response.status === 200) {
                localStorage.setItem('status', 0)
                localStorage.removeItem('access_token')
                localStorage.removeItem('profile_created')
                toast.success(Response.data.message)
                window.setTimeout(() => {
                    setLoader(false)
                    window.location.href = '/login'
                }, 2000)
            }
        }).catch(function (err) {
            setLoader(false)
            console.log({ err })
            toast.error(err.response.data.message)
        })
    }

    function HandleProfile() {
        // alert(typeof(localStorage.getItem('is_business')))
        if (localStorage.getItem('is_business') == 'true') {
            //  alert('business')
            window.location.href = '/business_profile'
        }
        else {
            window.location.href = '/profile'
        }
    }


    function Notifications(pages) {
        // alert('notify')
        //    alert(pages)
        GetNotification(pages).then(Response => {
            if (Response.status == 200) {
                setTotalPages(Response.data.pages)
                // console.log(Response)
                //     Response.data.data.map((item,i)=>i>2?item.status=false:item.status=true)
                if (pages === 1) {
                    //   alert('page1')
                    setNotiList(Response.data.data)
                }
                else {
                    //    alert('otherpages')
                    Response.data.data.map((item) =>
                        notiList.push(item)
                    )
                    var temp2 = [...notiList]
                    setNotiList(temp2)
                }
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    function txn_details(e, id) {
        if (id) {
            window.location.href = '/history?txn_key=' + id;
        } else {
            e.preventDefault();
        }
    }

    // function showNotifications() {

    //     var temp = [...notiList];
    //     temp.map((item)=>item.status==false?
    //       item.status=true
    //       :''
    //    )
    //    setNotiList(temp);
    // }

    return (
        <>
            <LoadingSpinerComponent promiseInProgress={loader} />
            <header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Navbar expand="lg" className="px-0">
                                <Navbar.Brand href="/home">
                                    <img src={require("../../images/logo.png").default} alt="img" className="web_logo" />
                                    <img src={require("../../images/mob_logo.png").default} alt="img" className="mob_logo" />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className="header2">
                                    <Nav className="ml-auto align-items-center">
                                        <Nav.Link onClick={checkes}>Home</Nav.Link>
                                        <Nav.Link href='/transactions?page=1&sort=all'>Transactions</Nav.Link>
                                        <Nav.Link href="">
                                            <Dropdown className="profile_dropdown notification ">
                                                <Dropdown.Toggle variant="transparent" id="dropdown-basic1">
                                                    <span className="bell_img">
                                                        <img src={require("../../../src/images/notification.png").default} alt="img" />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="py-0 dropdown-menu-right">
                                                    <div className="notification_body">
                                                        <div className="notification_header rounded" style={{ padding: "10 20px" }}>
                                                            <h5 className="mb-0">Notifications</h5>


                                                            {/* {notiList.length>3 && <h6 className="mb-0"><a onClick={showNotifications} className="View All">View All</a></h6>} */}

                                                        </div>
                                                        <div id="idNotificationDiv" className="mainotidifation" >{notiList.length == 0 ? "No notifications yet" : ''}
                                                            <InfiniteScroll
                                                                dataLength={notiList.length}

                                                                next={handleScroll}
                                                                height={200}
                                                                hasMore={status}
                                                                scrollableTarget="idNotificationDiv"
                                                            >
                                                                {notiList.length > 0 && notiList.map((item) =>

                                                                    <div className="content mb-3 pb-2" onClick={(e) => txn_details(e, item.txn_id)}>
                                                                        <span className="user_img ml-1 pt-1">
                                                                            <img src={Media + item.image} alt="" />
                                                                        </span>
                                                                        <p className="main-para mb-0">{item.title}</p>
                                                                        <div className="justify-content-between mt-1">
                                                                            <p className="price1">{item.body}</p>
                                                                            <p className="timeng1 mt-2">{item.created_at}</p>
                                                                        </div>
                                                                    </div>

                                                                )}
                                                                {/* {notiList} */}
                                                            </InfiniteScroll>

                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav.Link>
                                        <Nav.Link href="#">
                                            <button className="btn btn-outline-light my-2 bell_img" style={{cursor:"default"}}>
                                                <img src={require('../../../src/images/wallet.png').default} alt="walllet" className="mr-3" />${Balnace}
                                            </button>
                                        </Nav.Link>
                                        <Dropdown className="profile_dropdown">
                                            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                                <span>
                                                    <img src={localStorage.getItem('status') != 1 ? Media + localStorage.getItem('profile') : localStorage.getItem('profile')} alt="" />
                                                </span>
                                                {
                                                    localStorage.getItem('is_business') == "true"
                                                        ?
                                                        <div className="header_verify_icon"><img width="20px" src={require('../../images/verify.png').default} alt="" /></div>
                                                        : ""
                                                }
                                                <p>{localStorage.getItem('name')} <i className="fa fa-angle-down"></i></p>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="py-0 dropdown-menu-right">
                                                <Dropdown.Item onClick={HandleProfile}><i className="fa fa-user mr-2"></i>Profile</Dropdown.Item>
                                                <Dropdown.Item href="/settings"><i className="fa fa-cog mr-2"></i>Settings</Dropdown.Item>
                                                <Dropdown.Item className="logout" onClick={remove}><i className="fa fa-sign-out" aria-hidden="true"></i> LOGOUT</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header2;