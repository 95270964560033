import { useEffect, useState } from "react";
import Footer from "./Common/Footer2";
import Header2 from "./Common/Header2";
import Header from "./Common/Header";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { LoadingSpinerComponent } from "./Common/loadingspinner";
import HeaderNew from "./Common/HeaderNew";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import BootstrapSelect from "react-bootstrap-select-dropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FooterNew from "./Common/FooterNew";

const AboutUs = () => {
  const [loader, setLoader] = useState(false);
  return (
    <>
      {/* <header className="contactheader">
        <div className="container-fluid">
          <Navbar expand="lg contact-nav" className="px-0">
            <Navbar.Brand href="/">
              <img
                src={require("../images/logoWhite.png").default}
                alt="img"
                className="web_logo"
              />
              <img
                src={require("../images/blackLogo.png").default}
                alt="img"
                className="mob_logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/solutions">Solutions</Nav.Link>
              </Nav>
              <Form inline>
                <Button className="dark-btn" href="/login">
                  Get Started
                </Button>
              </Form>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header> */}
      <HeaderNew />
      <section className="about-us-header text-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6">
              <h2 className="about-title">
                Simplifying <span class="text-black">Payments</span> with Cutting-Edge Innovation.
              </h2>
              <p className="f-20">
                At PayRink Inc., we believe that making payments should be
                simple, secure, and affordable. That's why we have created a
                payment processing solution that leverages the latest technology
                to lower the cost of transactions for businesses of all sizes.
                Our cutting-edge technology and payments infrastructure,
                combined with our commitment to innovation, ensures that our
                clients always have access to the most advanced payment
                processing tools available.
              </p>
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="abut-absimg">
                <img
                  src={require("../images/about-earth.png").default}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-secont-sec  bg-white">
        <div className="container">
          <h2 className="f-45 text-center">About Us</h2>
          <p className="f-20">
            We understand that businesses are facing increasing pressure to
            reduce costs while maintaining the highest levels of security and
            efficiency. That's why our team of experts is dedicated to
            constantly improving our platform to meet these changing demands.
          </p>
          <p className="f-20">
            Our platform is designed to be user-friendly and highly
            customizable, allowing businesses to manage their payments and track
            transactions in real time easily. Whether you're a small start-up or
            a large enterprise, our payment processing solution is built to
            scale to meet your unique needs.
          </p>
          <p className="f-20">
            At PayRink, we are proud to be at the forefront of payment
            innovation, and we're committed to helping our clients succeed by
            providing them with the best possible payment processing experience.
            Contact us today to learn more about how we can help you simplify
            and streamline your payments.
          </p>
        </div>
      </section>
      <section className="about-center-image bg-white">
        <div className="container">
          <div className="about-image-full text-center">
            <img
              src={require("../images/about-center-img.jpg").default}
              alt="img"
            />
          </div>
        </div>
      </section>
      <section className="about-core-values bg-white">
        <div className="container">
          <div className="core-values">
            <h2 className="f-40 text-center">Core Values</h2>
            <ol className="core-listing f-20">
              <li>
                <strong>Customer-Centricity:</strong>{" "}
                <span>
                  We prioritize the needs of our customers and are dedicated to
                  providing exceptional customer service.
                </span>
              </li>
              <li>
                <strong>Innovation:</strong>{" "}
                <span>
                  We embrace new ideas and technologies to create innovative
                  payment solutions that meet the evolving needs of businesses
                  and consumers.
                </span>
              </li>
              <li>
                <strong>Transparency:</strong>{" "}
                <span>
                  We believe in being transparent in all of our transactions and
                  interactions with customers.
                </span>
              </li>
              <li>
                <strong> Security: </strong>{" "}
                <span>
                  We are committed to providing secure payment solutions that
                  protect the sensitive financial information of our customers.
                </span>
              </li>
              <li>
                <strong> Integrity: </strong>{" "}
                <span>
                  We act with honesty and integrity in all of our business
                  dealings and strive to earn the trust of our customers.
                </span>
              </li>
              <li>
                <strong> Collaboration: </strong>{" "}
                <span>
                  We believe in working together as a team to provide the best
                  possible payment solutions for our customers.
                </span>
              </li>
              <li>
                <strong> Agility: </strong>{" "}
                <span>
                  We are flexible and responsive, adapting to the changing needs
                  of our customers and the payment industry.
                </span>
              </li>
              <li>
                <strong> Continuous Improvement: </strong>{" "}
                <span>
                  We are dedicated to continuously improving our products and
                  services and strive to provide the most efficient and
                  cost-effective payment solutions available.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="about-mission">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="our-mission">
                <h2 className="f-40 text-center">Mission</h2>
                <p className="f-20">
                  Our mission at PayRink Inc. is to revolutionize the payment
                  industry by providing cost-effective and fast payment
                  solutions for businesses and consumers. We strive to lower the
                  cost of payment transactions, reduce the financial burden for
                  businesses, and improve the speed and efficiency of payments.
                  We believe in empowering our customers with the tools and
                  technology they need to succeed, and we are dedicated to
                  making payments simple, secure, and accessible for everyone.
                  With a commitment to innovation and a focus on customer
                  satisfaction, we aim to set the standard for the future of
                  payments.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="our-mission-img">
                <img
                  src={require("../images/mission-img.jpg").default}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <FooterNew />
    </>
  );
};

export default AboutUs;
