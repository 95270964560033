
import './App.css';

import Sendmoney from './Components/Sendmoney';
import Withdraw from './Components/Withdraw';
import Addmoney from './Components/Addmoney';
import Transactions from './Components/Transactions';
import Home from './Components/Home';
import HomeNew from './Components/HomeNew';
import SolutionsNew from './Components/SolutionsNew';
import Solutions from './Components/Solutions';
import Vision from './Components/Vision';
import Forgot_Pin from './Components/Forgot_Pin';
import Signup from './Components/Common/Signup';
import Login from './Components/Common/Login';
// import Transactions_details from './Components/Transactions_details';
import Settings from './Components/Settings';
import Edit_profile from './Components/Edit_profile';
import Business_profile from './Components/Business_profile';
import Business_editprofile from './Components/Business_editprofile';
import Forgotpassword from './Components/Common/Forgotpassword';
import Selectprofile from './Components/Selectprofile';
import Business_owner from './Components/Createprofile/Business_owner';
import Business from './Components/Createprofile/Business';
import Benficialowner from './Components/Createprofile/Benficialowner';
import Profile from './Components/Profile';
import History from './Components/History';
import Contact from './Components/Contact';
import { toast, ToastContainer } from 'react-toastify';
import Main from './Components/Main';
import Create_Pin from './Components/Create_Pin';
import 'react-toastify/dist/ReactToastify.css';
// import ReactNotification from 'react-notifications-component';
import ScrollToTop from './Components/ScrollToTop';

import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import Link from './Components/link';
import Blog from './Components/Blog';
import Terms from './Components/Terms';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Blogdetails from './Components/Blogdetails';
import About from './Components/About';
import Faq from './Components/Faq';
import AboutUs from './Components/AboutUs';

function Redirect() {
    const history = useHistory();
    history.push('/about-us');
    return '';
}

function App() {


    return (
        <>
            <Router>
                <div className="main">
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/forgotpassword" component={Forgotpassword} />
                            <Route exact path="/signup" component={Signup} />
                            <Route exact path="/selectprofile" component={Selectprofile} />
                            <Route exact path="/normal_customer" component={Business_owner} />
                            <Route exact path="/business_owner" component={Business} />
                            <Route exact path="/benficialowner" component={Benficialowner} />
                            <Route exact path="/addmoney" component={Addmoney} />
                            <Route exact path="/sendmoney" component={Sendmoney} />
                            <Route exact path="/withdraw" component={Withdraw} />
                            <Route exact path="/transactions" component={Transactions} />
                            <Route exact path="/link" component={Link} />
                            <Route exact path="/" component={Redirect} />
                            {/*<Route exact path="/solutions" component={Solutions} />*/}
                            <Route exact path="/solutions" component={SolutionsNew} />
                            <Route exact path="/vision" component={Vision} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/edit_profile" component={Edit_profile} />
                            <Route exact path="/business_profile" component={Business_profile} />
                            <Route exact path="/business_editprofile" component={Business_editprofile} />
                            <Route exact path="/settings" component={Settings} />
                            {/* <Route  exact path="/transactions_details" component={Transactions_details} /> */}
                            <Route exact path="/forgotpin" component={Forgot_Pin} />
                            <Route exact path="/createpin" component={Create_Pin} />
                            <Route exact path="/history" component={History} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/blog" component={Blog} />
                            <Route exact path="/terms" component={Terms} />
                            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                            <Route exact path="/blogdetails" component={Blogdetails} />
                            <Route exact path="/aboutus" component={About} />
                            <Route exact path="/faq" component={Faq} />
                            <Route exact path="/about-us" component={AboutUs} />
                        </Switch>
                    </ScrollToTop>
                </div>
                <ToastContainer newestOnTop={true} enableMultiContainer={false} limit={1} />
            </Router>

        </>
    );
}

export default App;
