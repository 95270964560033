import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import Footer from './Common/Footer2';
import Header2 from './Common/Header2';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import GeneratePin from '../APIs/createPinApi';
import Reset_Pin from '../APIs/ResetPinApi';
import { LoadingSpinerComponent } from './Common/loadingspinner';
import { Router, useHistory } from 'react-router';
import Footer2 from './Common/Footer2';

const Create_Pin = () => {

    let history = useHistory()

    const [OTP, setOTP] = useState("");
    const [OTP1, setOTP1] = useState("");
    const [loader, setLoader] = useState(false);
    const [resMsg, SetResMsg] = useState('');
    const [successpaymodal, setsuccesspaymodal] = useState(false)
    const [showvalue, setShowValue] = useState(false)

    function CreatePin(props) {

        toast.dismiss()
        if (OTP == '') {
            toast.error('Please enter new PIN.')
        }
        else if (OTP1 == '') {
            toast.error('Please enter confirm PIN.')
        }
        else if (OTP1 !== OTP) {
            toast.error('New and Confirm PIN must match.')
        }
        else {
            setLoader(true)
            if (localStorage.getItem('pinotp')) {
                const data = {
                    'otp': localStorage.getItem('pinotp'),
                    'pin': OTP
                }
                Reset_Pin(data).then(Response => {
                    if (Response.status == 200) {
                        setOTP('')
                        setOTP1('')
                        localStorage.removeItem('pinotp')
                        toast.success(Response.data.message)
                        window.setTimeout(() => {
                            setLoader(false)
                            history.go(-2)
                        }, 2000)

                    }
                }).catch(function (err) {
                    if (err.response.status == 401) {
                        history.push('/login')
                        localStorage.removeItem('access_token')
                    }
                    setOTP('')
                    setOTP1('')
                    setLoader(false)
                    toast.error(err.response.data.message)
                })
            } else {

                GeneratePin(OTP).then(Response => {
                    if (Response.status == 200) {
                        // alert('ok')
                        setLoader(false)
                        setOTP('')
                        setOTP1('')
                        SetResMsg(Response.data.message)
                        setsuccesspaymodal(true)
                        window.setTimeout(() => {
                            history.goBack()
                        }, 2000)

                    }
                }).catch((err) => {
                    if (err.response.status == 401) {
                        history.push('/login')
                        localStorage.removeItem('access_token')
                    }
                    setLoader(false)
                    setOTP('')
                    setOTP1('')
                    toast.error(err.response.data.message)
                })
            }
        }
    }

    const hidesuccesspaymodal = () => {
        setsuccesspaymodal(false)
        history.goBack()
    }

    return (
        <>
            <Header2 />
            <section className="bg_section py-5 forgotpin_section">
                <LoadingSpinerComponent promiseInProgress={loader} />
                <div className="container">
                    <div className="row">
                        <aside className="col-lg-6 col-md-7 m-auto">
                            <div className="text-center"><h4 className="text-white mb-2">Set your 4-digits PIN</h4>
                                <p className="text-white">Please set your PIN to make your payment more secure while PAY.</p>
                            </div>
                            <div className="border_box pb-3 mt-3">
                                <div className="signup_box my-2">
                                    <Form>
                                        <Form.Group className="mb-3 text-center" controlId="formBasicPhone">
                                            <Form.Label>Enter New PIN</Form.Label>
                                            <div className="otp_row pin_row mt-1">
                                                <OtpInput
                                                    isInputNum={true}
                                                    isInputSecure={showvalue == true ? false : true}
                                                    inputMode="numeric"
                                                    value={OTP}
                                                    onChange={setOTP}
                                                    inputStyle={"input101"}
                                                    numInputs={4}
                                                    separator={<span></span>}
                                                />

                                            </div>
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword" className="mt-2 text-center">
                                            <Form.Label>Enter Confirm PIN</Form.Label>
                                            <div className="otp_row pin_row mt-1">
                                                <OtpInput
                                                    isInputSecure={showvalue == true ? false : true}
                                                    isInputNum={true}
                                                    inputMode="numeric"
                                                    value={OTP1}
                                                    onChange={setOTP1}
                                                    inputStyle={"input101"}
                                                    numInputs={4}
                                                    separator={<span></span>}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group>
                                            <p className="forgot_pin justify-content-around">
                                                <a className="btn" onClick={(e) => { showvalue == false ? setShowValue(true) : setShowValue(false) }}>
                                                    {showvalue == false ? <i className="fa fa-eye" aria-hidden="true"> Show PIN</i> : ''}{showvalue == true ? <span><i className="fa fa-eye-slash" aria-hidden="true"> Hide PIN</i></span> : ''}
                                                </a>
                                            </p>
                                        </Form.Group>
                                        <div className="text-center">
                                            <a className="btn signupbtn my-2" onClick={CreatePin}>
                                                SUBMIT
                                            </a>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>
            <Modal className="sucessmodal" show={successpaymodal} onHide={hidesuccesspaymodal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="text-right justify-content-end">
                    <i className="fa fa-close text-white" onClick={hidesuccesspaymodal} style={{ cursor: "pointer" }}></i>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={require('../images/check.png').default} alt="" />
                                {/* <h5 className="text-white mt-3">$200</h5> */}
                                <p>{resMsg}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer2 />
        </>
    )
}
export default Create_Pin;