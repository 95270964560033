import axios from "axios";
import { config } from "../config";

function QrcodeGen(){
    return axios.get(`${config.api_URL}/accounts/get_qr`,
    {headers:
        {'Authorization':localStorage.getItem('access_token')}
    }
    )
}

export default QrcodeGen;