import axios from 'axios';
import { config } from "../config";


function Pay(data){
    return axios.post(`${config.api_URL}/accounts/send_money`,data,{
        headers:{
            "Authorization":localStorage.getItem('access_token')
        }
    })
}

export default Pay;