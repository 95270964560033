import axios from "axios";
import { config } from "../config";

function UpdatePro(data){
    return axios.post(`${config.api_URL}/accounts/update_profile`,data,{
        headers:{
            "Authorization":localStorage.getItem("access_token")
        }
    })
}

export default UpdatePro;