import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Footer from './Common/Footer';
import Header from './Common/Header';
import Header3 from './Common/header3';


const Selectprofile = () => {
    const history = useHistory()
    useEffect(() => {
       if(history.listen){
            if (history.length===7) {
                history.push('/')
            }
        }
    }, []);
    
    return(
        <>
        <Header3/>
            <section className="signup_section py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 m-auto">
                            <div className="signup_box my-4 text-center">
                                <img src={require('../images/profile_icon.png').default} alt="" />
                                <h5 className="text-white my-4">Are you a business owner and want to register as a business?</h5>
                                <div className="yesnobtn">
                                    <a className="btn yesbtn text-white" href="/business_owner">Yes</a>
                                    <a className="btn nobtn text-white" href="/normal_customer">No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
export default Selectprofile;